import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "../../Components/Loader/Loader.jsx";
import { parseError } from "api/common.js";
import "react-phone-number-input/style.css";

const HealthCardPreviewModal = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imgData, setImgData] = useState(null);

  useEffect(() => {
    setImgData(null);
    setError(null);
    setLoading(true);

    if (props.show && props.formKey) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/file/download/HealthCard/${props.formKey}`, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          setLoading(false);

          const base64 = btoa(
            new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
          );
          setImgData("data:;base64," + base64);
        })
        .catch((err) => {
          let error = parseError(err);
          setLoading(false);
          setError(error);
        });
    }
  }, [props.show, props.formKey]);

  return (
    <Modal show={props.show} onHide={() => props.onHide()} dialogClassName="wide">
      <Modal.Header closeButton>
        <Modal.Title>Health Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="flex flex-col gap-16">
            {loading && (
              <div className="flex flex-col items-center justify-center">
                <Loader title="Loading Preview..." />
              </div>
            )}

            {error && (
              <div className="error-alert" style={{ padding: "0.6rem" }}>
                {error}
              </div>
            )}

            {imgData && (
              <img
                alt="Health Card Preview"
                style={{ maxWidth: "800px", maxHeight: "540px", objectFit: "contain" }}
                src={imgData}
              />
            )}
          </div>

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HealthCardPreviewModal;
