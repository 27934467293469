import React, { useState, useEffect } from "react";
import { FormGroup, ControlLabel, FormControl, Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import ServiceLocationsMap from "./ServiceLocationsMap.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import Svg from "components/Svg/Svg.jsx";
import ReactTable from "react-table";
import { parseError } from "api/common.js";

const AddServiceLocation = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [serviceCentre, setServiceCentre] = useState(null);
  const [serviceCentresList, setServiceCentreList] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [techniciansList, setTechniciansList] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  const [pageSize] = useState(12);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/service-centres`)
      .then((res) => {
        setLoading(false);
        setServiceCentreList(
          res.data.items.map((s) => {
            return { label: s.name, value: s.id };
          })
        );

        let zoneId = props.match.params.id;
        if (zoneId !== "new") {
          setZoneId(zoneId);
        }
      })
      .catch((err) => {
        setLoading(false);
        parseError(err);
      });
  }, []);

  useEffect(() => {
    if (zoneId && zoneId !== "new") {
      setLoading(true);
      getZone(zoneId);
      getTechnicians(zoneId);
    }
  }, [zoneId]);

  const updateFeatures = (value) => {
    if (value && value.length > 0) {
      setCoordinates(value[0].geometry.coordinates[0]);
    }
  };

  const saveZone = () => {
    const isUpdate = zoneId && zoneId !== "new";

    setLoading(true);

    const data = {
      id: zoneId || null,
      coordinates: coordinates,
      name: name,
      serviceCentreId: serviceCentre.value,
    };

    const requestOptions = {
      method: isUpdate ? "PUT" : "POST",
      url: `${process.env.REACT_APP_API_URL}/zones`,
      data: data,
    };

    axios(requestOptions)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: "Service Location",
          text: `The service location '${data.name}' has been successfully created`,
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          props.history.go(-1);
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Service Location Error", error, "error");
      });
  };

  const getZone = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/zones/${id}`)
      .then((res) => {
        setLoading(false);
        setName(res.data.name);
        setCoordinates(res.data.coordinates);
        setServiceCentre(serviceCentresList.find((s) => s.value === res.data.serviceCentreId));
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Service Location Error", error, "error");
      });
  };

  const getTechnicians = (id, pageToken = null, isBackward = null) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/zones/${id}/technicians?pageSize=${pageSize}&paginationToken=${pageToken || ""}&isBackward=${
          isBackward ? "true" : "false"
        }`
      )
      .then((res) => {
        setTechniciansList(res.data.items);
        setNextPageToken(res.data.length < pageSize ? res.data.nextPageToken : null);
        setPreviousPageToken(res.data.previousPageToken);
      })
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Service Location Error", error, "error");
      });
  };

  return (
    <div className="main-content service-locations flex flex-col gap-12">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "0px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Go Back</span>
      </Button>

      <div
        className="flex flex-col card"
        style={{ position: "relative", border: "1px solid var(--border-color)", borderRadius: "6px", overflow: "hidden" }}
      >
        <ServiceLocationsMap
          mode={props.match.params.id === "new" ? "new" : "edit"}
          coordinates={[coordinates]}
          zoneId={props.match.params.id}
          onUpdate={updateFeatures}
          height={600}
        />

        <div className="flex flex-col" style={{ position: "absolute", left: "16px", top: "16px" }}>
          <Card
            content={
              <div className="flex flex-col" style={{ minWidth: "260px" }}>
                <FormGroup>
                  <ControlLabel>
                    Service Location Name: <span className="star">*</span>
                  </ControlLabel>
                  <FormControl
                    required
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                  <ControlLabel style={{ marginTop: "16px" }}>
                    Service Centre: <span className="star">*</span>
                  </ControlLabel>
                  <Select
                    isLoading={loading}
                    name="service-centre-select"
                    isClearable={false}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Service Centre"
                    noOptionsMessage={(inp) => "No Service Centres"}
                    isMulti={false}
                    value={serviceCentre}
                    onChange={(opt) => {
                      setServiceCentre(opt);
                    }}
                    options={serviceCentresList}
                    formatGroupLabel={(data) => <div className="flex items-center text-theme font-semibold">{data.label}</div>}
                  />
                </FormGroup>

                <Button
                  loading={loading}
                  disabled={!name || !serviceCentre || loading || coordinates.length === 0}
                  bsStyle="danger"
                  fill
                  type="submit"
                  style={{ marginLeft: "auto" }}
                  onClick={() => saveZone()}
                >
                  Save Location
                </Button>
              </div>
            }
          />
        </div>
      </div>

      {zoneId != null && (
        <div className="flex flex-col">
          <Card
            title="Assigned Technicians"
            content={
              <div className="flex flex-col gap-10">
                <ReactTable
                  noDataText={loading ? "" : "No technicians assigned..."}
                  loading={loading}
                  loadingText="Loading technicians..."
                  data={techniciansList.map((prop, key) => {
                    return {
                      id: key,
                      firstName: prop.firstName,
                      lastName: prop.lastName,
                      email: prop.email,
                      phone: prop.phone,
                      status: <div className={`badge ${prop.status === "enabled" ? "success" : "error"}`}>{prop.status}</div>,
                    };
                  })}
                  filterable={false}
                  sortable={false}
                  columns={[
                    {
                      Header: "First Name",
                      accessor: "firstName",
                    },
                    {
                      Header: "Last Name",
                      accessor: "lastName",
                    },
                    {
                      Header: "Email",
                      accessor: "email",
                      minWidth: 400,
                      style: { whiteSpace: "unset" },
                    },
                    {
                      Header: "Phone Number",
                      accessor: "phone",
                      sortable: false,
                      minWidth: 150,
                      className: "pullRight",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: false,
                      style: { textAlign: "right" },
                      maxWidth: 80,
                    },
                  ]}
                  defaultPageSize={pageSize}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
                <div className="flex items-center" style={{ marginLeft: "auto" }}>
                  <Button
                    className="pagination-btn left btn-fill"
                    disabled={!previousPageToken || loading}
                    onClick={() => getTechnicians(zoneId, previousPageToken, true)}
                    bsStyle="default"
                    fill
                  >
                    <Svg name="chevron-left" className="w-6 h-6" />
                  </Button>
                  <Button
                    className="pagination-btn right btn-fill"
                    disabled={!nextPageToken || loading}
                    onClick={() => getTechnicians(zoneId, nextPageToken, false)}
                    bsStyle="default"
                    fill
                  >
                    <Svg name="chevron-right" className="w-6 h-6" />
                  </Button>
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default AddServiceLocation;
