import React, { useState, useEffect } from "react";
import { Modal, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Swal from "sweetalert2";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Svg from "../../../components/Svg/Svg";
import { parseError } from "api/common";

const SendMedicalNotesModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setError(null);
    setLoading(false);
  }, [props.show]);

  useEffect(() => {
    setEmail(props.report ? props.report.email : "");
  }, [props.report]);

  const onSendReports = () => {
    setError(null);
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/service-notes/reports/${props.report.id}/send`, {
        email: email,
      })
      .then((res) => {
        if (res.data.sendResult === true) {
          props.onConfirm(res.data);
          Swal.fire("The email with this report has been sent.", "", "success");
        } else {
          Swal.fire(
            "Send Error",
            "The email has not been sent. Please check the email address and try again.",
            "error"
          );
        }
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()} className="narrow">
      <Modal.Header closeButton>
        <Modal.Title>Medical Notes Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="flex flex-col gap-6">
            <FormGroup>
              <ControlLabel>Email Address:</ControlLabel>
              <FormControl
                disabled={loading}
                type="email"
                maxLength={100}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </FormGroup>
          </div>

          {error && (
            <div className="error-alert" style={{ padding: "0.6rem", marginTop: "12px" }}>
              {error}
            </div>
          )}

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button
              disabled={loading}
              bsStyle="danger"
              outline="true"
              onClick={() => props.onHide()}
            >
              Close
            </Button>

            <Button
              loading={loading}
              bsStyle="danger"
              fill
              onClick={() => onSendReports()}
              disabled={
                loading ||
                !email ||
                !/^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(
                  email
                )
              }
            >
              <Svg name="send" className="w-4 h-4" />
              Send Report
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendMedicalNotesModal;
