import React, { useState, useEffect } from "react";
import { Modal, FormGroup, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import LocalizableTextArea from "../../Components/LocalizableInputs/LocalizableTextArea.jsx";
import axios from "axios";
import { parseError } from "api/common.js";

const NoteModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [description, setDescription] = useState(props.note ? props.note.description : "");
  const [localizations, setLocalizations] = useState(props.note ? props.note.localizations : {});
  const [localizationFlag, setLocalizationFlag] = useState(0);

  useEffect(() => {
    setLoading(false);
    setError(undefined);

    if (!props.show) {
      setDescription("");
      setLocalizations({});
      setLocalizationFlag((prev) => prev + 1);
    } else {
      setDescription(props.note ? props.note.description : "");
      setLocalizations(props.note ? props.note.localizations : {});
    }
  }, [props.show, props.note]);

  const onSaveNote = () => {
    setLoading(true);
    setError(null);

    let noteData = {
      id: props.note ? props.note.id : null,
      localizations: localizations,
      description: description,
    };

    let updateRequest = props.note
      ? axios.put(`${process.env.REACT_APP_API_URL}/service-notes`, noteData)
      : axios.post(`${process.env.REACT_APP_API_URL}/service-notes`, noteData);

    updateRequest
      .then((res) => {
        if (props.onConfirm) {
          props.onConfirm(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{props.question ? "Note Details" : "New Note"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <FormGroup style={{ marginBottom: "0" }}>
            <ControlLabel>
              Note Text:<span className="star">*</span>
            </ControlLabel>
            <LocalizableTextArea
              disabled={loading}
              placeholder="The note text..."
              value={description}
              maxLength={1000}
              forceResetFlag={localizationFlag}
              rows={6}
              onChange={(text) => {
                setDescription(text);
              }}
              onLocalizationChange={(translation) => {
                setLocalizations((prev) => {
                  let newObj = Object.assign({}, prev);
                  Object.assign(newObj, translation);
                  return newObj;
                });
              }}
              fieldName="Description"
              localizations={localizations}
            />
          </FormGroup>

          {error && (
            <div
              className="error-alert"
              style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
            >
              {error}
            </div>
          )}

          <div className="flex items-center gap-10" style={{ marginTop: "24px" }}>
            <Button
              bsStyle="danger"
              className="ml-auto"
              outline="true"
              disabled={loading}
              onClick={() => props.onHide()}
            >
              Cancel
            </Button>

            <Button
              loading={loading}
              disabled={!description || loading}
              bsStyle="danger"
              fill
              onClick={() => onSaveNote()}
            >
              {props.note && props.note.id ? "Save Note" : "Add Note"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NoteModal;
