import React, { useEffect, useState } from "react";
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import axios from "axios";
import Swal from "sweetalert2";
import showPass from "assets/img/show-icon.svg";
import hidePass from "assets/img/hide-icon.svg";
import "react-phone-number-input/style.css";
import { parseError } from "api/common";

const AdminRegister = (props) => {
  const [code, setCode] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [verifiedNumber, setVerifiedNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState({ value: "" });
  const [phoneError, setPhoneError] = useState(null);
  const [genders, setGenders] = useState([]);
  const [password, setPassword] = useState("");
  const [cfpassword, setCfPassword] = useState("");
  const [newType, setNewType] = useState("password");
  const [confirmType, setConfirmType] = useState("password");
  const [passwordError, setPasswordError] = useState(null);
  const [cfpasswordError, setCfPasswordError] = useState(null);

  useEffect(() => {
    let params = new URLSearchParams(props.location.search);
    setCode(params.get("code"));

    // axios
    //   .get(`${process.env.REACT_APP_API_URL}/account/genders`)
    //   .then((res) => {
    //     setGenders(res.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, []);

  const checkLowercase = (password) => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const checkSpecialCharacters = (password) => {
    const pattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);

    let lowerValid = checkLowercase(event.target.value);
    let upperValid = checkUppercase(event.target.value);
    let numberValid = checkNumber(event.target.value);
    let specialValid = checkSpecialCharacters(event.target.value);

    if (
      event.target.value.length < 8 ||
      !lowerValid ||
      !upperValid ||
      !numberValid ||
      !specialValid
    ) {
      setPasswordError(
        <small className="text-danger">
          Make sure your password has at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1
          special character and at least 8 characters long.
        </small>
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleCfPasswordChange = (event) => {
    setCfPassword(event.target.value);

    if (event.target.value !== password) {
      setCfPasswordError(<small className="text-danger">Passwords do not match.</small>);
    } else {
      setCfPasswordError(null);
    }
  };

  const triggerSmsVerification = () => {
    const self = this;
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/sms/${phone}`)
      .then((res) => {
        setLoading(false);
        setCodeSent(true);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Phone Verification Error", error, "error");
      });
  };

  const verifySmsCode = () => {
    const self = this;
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/confirmPhone`, {
        phone: phone,
        code: smsCode,
      })
      .then((res) => {
        setLoading(false);
        setPhoneVerified(true);
        setVerifiedNumber(phone);

        Swal.fire({
          title:
            "Your phone number has been confirmed. You can now complete the registration process.",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Phone Verification Error", error, "error");
      });
  };

  const registerAccount = () => {
    const self = this;
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/register`, {
        firstName: firstName,
        lastName: lastName,
        phone: verifiedNumber,
        code: code,
        // gender: {
        //   key: gender.value,
        //   value: gender.label,
        // },
        password: password,
        passwordConfirmation: cfpassword,
      })
      .then((res) => {
        setLoading(false);

        Swal.fire({
          title:
            "Your admin account has been created successfully. You can now login using your credentials.",
          icon: "success",
          showCancelButton: false,
        }).then((result) => {
          props.history.push("/auth/login");
        });
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Registration Error", error, "error");
      });
  };

  const handleRegistrationStep = () => {
    if (!codeSent) {
      triggerSmsVerification();
      return;
    }

    if (!phoneVerified) {
      verifySmsCode();
      return;
    }

    registerAccount();
  };

  return (
    <div className="flex flex-col" style={{ width: "90%", maxWidth: "400px", margin: "0 auto" }}>
      <Card
        style={{ padding: "16px 0px" }}
        className="w-full"
        title={
          <div className="text-center title" style={{ padding: "0" }}>
            Admin Registration
          </div>
        }
        content={
          <div>
            <FormGroup>
              <ControlLabel>
                First Name: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                disabled={loading}
                placeholder="First Name"
                type="text"
                name="firstName"
                maxLength="32"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value.replace(/[^a-z ]/gi, ""));
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                Last Name: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                disabled={loading}
                placeholder="Last Name"
                type="text"
                maxLength="32"
                name="lastName"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value.replace(/[^a-z ]/gi, ""));
                }}
              />
            </FormGroup>
            <div className="form-group phone-input">
              <ControlLabel>
                Phone Number: <span className="star">*</span>
              </ControlLabel>
              <PhoneInput
                disabled={loading}
                maxLength="16"
                max="10"
                defaultCountry="CA"
                extension="true"
                value={phone}
                onChange={(value) => {
                  setPhone(value);
                  if (value && !isPossiblePhoneNumber(value)) {
                    setPhoneError(
                      <small className="text-danger">
                        A valid Canadian or US phone number is required.
                      </small>
                    );
                  } else {
                    setPhoneError(null);
                  }

                  if (phoneVerified && value != verifiedNumber) {
                    setCodeSent(false);
                    setVerifiedNumber(false);
                    setPhoneVerified(false);
                    setSmsCode("");
                  }
                }}
                required
              />
              {phoneError}
            </div>
            {/*
            <FormGroup controlId="exampleForm.ControlSelect1">
              <ControlLabel>
                Gender: <span className="star">*</span>
              </ControlLabel>
              <Select
                isDisabled={loading}
                name="gender-select"
                isClearable={false}
                className="react-select react-select-icon"
                isSearchable={true}
                placeholder="Select Gender"
                noOptionsMessage={(inp) => "No Genders Loaded"}
                isMulti={false}
                value={gender}
                onChange={(opt) => {
                  setGender(opt);
                }}
                options={genders.map((s) => {
                  return { label: s.value, value: s.key };
                })}
                formatGroupLabel={(data) => (
                  <div className="flex items-center text-theme font-semibold">{data.label}</div>
                )}
                />
            </FormGroup>
            */}
            <FormGroup style={{ position: "relative" }}>
              <ControlLabel>
                Password: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                disabled={loading}
                type={newType}
                placeholder="Password"
                autoComplete="off"
                value={password}
                onChange={(event) => handlePasswordChange(event)}
                required
              />
              {newType === "input" ? (
                <img
                  className="show-icon"
                  src={hidePass}
                  onClick={(e) => {
                    setNewType("password");
                  }}
                />
              ) : (
                <img
                  className="show-icon"
                  src={showPass}
                  onClick={(e) => {
                    setNewType("input");
                  }}
                />
              )}
              {passwordError}
            </FormGroup>
            <FormGroup style={{ position: "relative" }}>
              <ControlLabel>
                Confirm Password: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                disabled={loading}
                type={confirmType}
                autoComplete="off"
                placeholder="Password Confirmation"
                value={cfpassword}
                onChange={(event) => handleCfPasswordChange(event)}
                required
              />
              {confirmType === "input" ? (
                <img
                  className="show-icon"
                  src={hidePass}
                  onClick={(e) => {
                    setConfirmType("password");
                  }}
                />
              ) : (
                <img
                  className="show-icon"
                  src={showPass}
                  onClick={(e) => {
                    setConfirmType("input");
                  }}
                />
              )}
              {cfpasswordError}
            </FormGroup>

            {codeSent && (
              <React.Fragment>
                <div className="separator horizontal" style={{ margin: "16px 0" }}></div>

                <FormGroup>
                  <ControlLabel>
                    SMS Code: <span className="star">*</span>
                  </ControlLabel>
                  <FormControl
                    disabled={loading}
                    placeholder="Enter SMS Verification Code"
                    type="text"
                    name="smsCode"
                    maxLength="6"
                    value={smsCode}
                    onChange={(event) => {
                      setSmsCode(event.target.value);
                    }}
                  />
                </FormGroup>
              </React.Fragment>
            )}
          </div>
        }
        legend={
          <div className="flex flex-col items-center">
            <Button
              fill
              type="submit"
              onClick={() => handleRegistrationStep()}
              loading={loading}
              disabled={
                !firstName ||
                !lastName ||
                phoneError ||
                !phone ||
                !password ||
                !cfpassword ||
                password !== cfpassword ||
                passwordError ||
                cfpasswordError ||
                loading ||
                (codeSent && !smsCode)
              }
            >
              {codeSent
                ? phoneVerified
                  ? "Complete Registration"
                  : "Verify SMS Code"
                : "Send SMS Verification Code"}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default AdminRegister;
