import React, { useState, useEffect } from "react";
import moment from "moment";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from "react-select";
import axios from "axios";
import { parseError } from "api/common";
import { dateTimeFormat } from "api/common";

const ReassignModal = (props) => {
  const [selectedTech, setSelectedTech] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [techList, setTechList] = useState([]);

  useEffect(() => {
    setError(null);
    setSelectedTech(null);
    setLoading(false);

    if (props.show && props.appointmentId) {
      setLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/appointments/${props.appointmentId}/reassign/availability`
        )
        .then((res) => {
          setLoading(false);
          let list = (res.data || []).map((tech) => {
            return {
              value: tech.id,
              label: `${tech.firstName} ${tech.lastName} (${tech.email})`,
              tech: tech,
            };
          });
          setTechList(list);
        })
        .catch((err) => {
          let error = parseError(err);
          setLoading(false);
          setError(error);
        });
    }
  }, [props.show, props.appointmentId]);

  const onAssignAppointment = () => {
    setError(null);
    setLoading(true);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/appointments/${
          props.appointmentId
        }/reassign/${encodeURIComponent(selectedTech.value)}`
      )
      .then((res) => {
        if (props.onConfirm) {
          props.onConfirm(res.data);
        }

        setLoading(false);
        props.onHide();
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--";
    }
    return moment(
      `${inDate.day}/${inDate.month}/${inDate.year} ${inDate.hour}:${inDate.minute}`,
      "D/M/YYYY H:mm"
    ).format(dateTimeFormat);
  };

  const CustomOption = ({ innerProps, isDisabled, ...rest }) => {
    if (!isDisabled)
      return (
        <div
          {...innerProps}
          className="custom-select-option"
          style={{ padding: "8px 6px", cursor: "pointer" }}
        >
          <div
            style={{
              marginRight: "8px",
              marginLeft: "6px",
            }}
            className={`availability-badge badge fill  ${
              rest.data.tech.availability === "Available"
                ? "success"
                : rest.data.tech.availability === "Busy"
                ? "warning"
                : "error"
            }`}
          >
            {" "}
          </div>
          {rest.data.label}
        </div>
      );

    return null;
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Technician</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Card
            content={
              <div className="flex flex-col gap-16">
                <FormGroup>
                  <ControlLabel>APPOINTMENT DATE</ControlLabel>
                  <FormControl
                    type="text"
                    name="appDate"
                    disabled={true}
                    readOnly={true}
                    value={formatDate(props.appointmentDate)}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Available Technicians</ControlLabel>
                  <Select
                    components={{ Option: CustomOption }}
                    name="tech-assign-select"
                    isClearable={false}
                    isLoading={loading}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Technician"
                    noOptionsMessage={(inp) => "No available technicians for that date & time"}
                    isMulti={false}
                    value={selectedTech}
                    onChange={(opt) => {
                      setSelectedTech(opt);
                    }}
                    options={techList}
                    formatGroupLabel={(data) => (
                      <div className="flex items-center text-theme font-semibold">{data.label}</div>
                    )}
                  />
                </FormGroup>

                {error && (
                  <div className="error-alert" style={{ padding: "0.6rem" }}>
                    {error}
                  </div>
                )}
              </div>
            }
          />

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button
              disabled={loading}
              bsStyle="danger"
              outline="true"
              onClick={() => props.onHide()}
            >
              Close
            </Button>

            <Button
              loading={loading}
              bsStyle="danger"
              fill
              onClick={() => onAssignAppointment()}
              disabled={loading || !selectedTech}
            >
              Assign Appointment
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReassignModal;
