import React, { useState, useEffect } from "react";
import moment from "moment";
import Datetime from "react-datetime";
import { Modal } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { parseError, renderDatePickerInput } from "api/common";
import { dateFormat } from "api/common.js";

const ReportsModal = (props) => {
  const [reportType, setReportType] = useState("registrations");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [timeRangeEnabled, setTimeRangeEnabled] = useState(false);

  useEffect(() => {
    setReportType("registrations");
    setFromDate(null);
    setToDate(null);
    setTimeRangeEnabled(false);
    setError(null);
    setLoading(false);
  }, [props.show]);

  const onDownloadReport = () => {
    setError(null);
    setLoading(true);

    var filters = timeRangeEnabled
      ? `?from=${fromDate ? moment(fromDate).format("yyyy-MM-DD") : ""}&to=${
          toDate ? moment(toDate).format("yyyy-MM-DD") : ""
        }`
      : "";

    axios({
      url: `${process.env.REACT_APP_API_URL}/reports/users/${reportType}${filters}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.headers["x-file-name"] || "Report.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        setLoading(false);
        const error = parseError(err);
        setError(error);
      });
  };

  const changeStartDate = (event) => {
    if (event && typeof event.toDate === "function") {
      setFromDate(event.toDate());
    } else {
      setFromDate(null);
    }
  };

  const changeEndDate = (event) => {
    if (event && typeof event.toDate === "function") {
      setToDate(event.toDate());
    } else {
      setToDate(null);
    }
  };

  const isValidFromDate = (current) => {
    return toDate ? current.isSameOrBefore(toDate) : current.isSameOrBefore(moment());
  };

  const isValidEndDate = (current) => {
    return fromDate
      ? current.isSameOrAfter(fromDate) && current.isSameOrBefore(moment())
      : current.isSameOrBefore(moment());
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Reports</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-12">
          <div className="grid grid-2col gap-16">
            <Card
              className="w-full"
              content={
                <div className="flex flex-col gap-16">
                  <div className="flex items-start gap-24">
                    <input
                      style={{ margin: "6px 0 0 0" }}
                      name="registrations-report-radio"
                      type="radio"
                      checked={reportType === "registrations"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setReportType("registrations");
                        }
                      }}
                    />
                    <div className="flex flex-col gap-4">
                      <span className="text-bold text-xl">Accounts</span>
                      <span className="sub-text text-xs">
                        Report includes details about registered customer accounts, including name
                        and address.
                      </span>
                    </div>
                  </div>
                </div>
              }
            />

            <Card
              className="w-full"
              content={
                <div className="flex flex-col gap-16">
                  <div className="flex items-start gap-24">
                    <input
                      style={{ margin: "6px 0 0 0" }}
                      name="registrations-report-radio"
                      type="radio"
                      checked={reportType === "orders"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setReportType("orders");
                        }
                      }}
                    />
                    <div className="flex flex-col gap-4">
                      <span className="text-bold text-xl">Orders</span>
                      <span className="sub-text text-xs">
                        Report includes details about ordered services for each appointment with
                        details about each patient and nurse for each service.
                      </span>
                    </div>
                  </div>
                </div>
              }
            />
          </div>

          <div className="separator horizontal" />

          <Card
            content={
              <div className="flex flex-col gap-16">
                <div className="flex items-start gap-12">
                  <input
                    style={{ margin: "4px 0 0 0" }}
                    name="registrations-report-radio"
                    type="checkbox"
                    checked={timeRangeEnabled}
                    onChange={(e) => {
                      setTimeRangeEnabled(e.target.checked);
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <span className="text-bold text-md">Time Period</span>
                    <span className="sub-text text-xs">
                      Report will include only records for the specified time period.
                    </span>
                  </div>
                </div>

                <div className="flex items-end gap-12" style={{ marginLeft: "26px" }}>
                  <div className="flex flex-col gap-6 w-full">
                    <span className="text-xxs text-bold">
                      FROM: <span className="star">*</span>
                    </span>
                    <Datetime
                      dateFormat={dateFormat}
                      timeFormat={false}
                      renderInput={renderDatePickerInput}
                      inputProps={{
                        placeholder: "",
                        clearable: false,
                        disabled: !timeRangeEnabled,
                      }}
                      isValidDate={isValidFromDate}
                      value={fromDate}
                      closeOnSelect={true}
                      onChange={changeStartDate}
                    />
                  </div>

                  <div className="flex flex-col gap-6 w-full">
                    <span className="text-xxs text-bold">
                      TO: <span className="star">*</span>
                    </span>
                    <Datetime
                      dateFormat={dateFormat}
                      renderInput={renderDatePickerInput}
                      closeOnSelect={true}
                      timeFormat={false}
                      inputProps={{
                        placeholder: "",
                        clearable: false,
                        disabled: !timeRangeEnabled,
                      }}
                      isValidDate={isValidEndDate}
                      value={toDate}
                      onChange={changeEndDate}
                    />
                  </div>
                </div>
              </div>
            }
          />

          {error && (
            <div className="error-alert" style={{ padding: "0.6rem" }}>
              {error}
            </div>
          )}

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button
              disabled={loading}
              bsStyle="danger"
              outline="true"
              onClick={() => props.onHide()}
            >
              Close
            </Button>

            <Button
              loading={loading}
              bsStyle="danger"
              fill
              onClick={() => onDownloadReport()}
              disabled={loading}
            >
              Get Report
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReportsModal;
