import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import ReportsModal from "./ReportsModal.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { formatPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { parseError } from "api/common.js";
import { debounce } from "debounce";
import Svg from "components/Svg/Svg.jsx";
import { formatCityAddress } from "api/common.js";

const Users = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(12);
  const [error, setError] = useState(null);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  useEffect(() => {
    if (props.location.search.length === 0 && data.length > 0 && !loading) {
      getUsers(null, true);
    }
  }, [props.location]);

  const getUsers = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setError(null);
    setData([]);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/account?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        const nextPageToken = res.data.previousPageToken;
        const prevPageToken = res.data.nextPageToken;

        setNextPageToken(nextPageToken);
        setPreviousPageToken(prevPageToken);
        setData(mapItems((res.data.items || []).reverse()));

        if (pageToken !== null) {
          if (!prevPageToken) {
            props.history.replace(props.history.location.pathname, null);
          } else {
            props.history.replace(
              `${props.history.location.pathname}?${
                pageToken !== null
                  ? `pageToken=${encodeURIComponent(pageToken)}&backward=${
                      isBackward ? "true" : "false"
                    }`
                  : ""
              }`,
              null
            );
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const onSearch = (filter) => {
    setLoading(true);
    setData([]);
    setError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/search`, filter)
      .then((res) => {
        setData(mapItems(res.data));
        setLoading(false);
        setNextPageToken(null);
        setPreviousPageToken(null);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const getFilters = (filter) => {
    var dataFilter = {};

    filter.forEach((f) => {
      dataFilter[f.id] = f.value;
    });

    return dataFilter;
  };

  const fetchData = (state) => {
    if (state.filtered.length > 0) {
      let dataFilter = getFilters(state.filtered);
      onSearch(dataFilter);
    } else {
      const searchParams = new URLSearchParams(props.location.search);
      const pageToken = searchParams.get("pageToken");
      const pageBackward = searchParams.get("backward");
      getUsers(pageToken, pageBackward === null || pageBackward === "true");
    }
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapUser(prop);
    });
  };

  const mapUser = (prop) => {
    return {
      id: prop.id,
      firstName: prop.firstName,
      lastName: prop.lastName,
      email: prop.email,
      phone: formatPhoneNumber(prop.phone),
      status: (
        <div className={`badge ${prop.status === "enabled" ? "success" : "error"}`}>
          {prop.status}
        </div>
      ),
      address: formatCityAddress(prop.address),
      adminNotes: prop.adminNotes,
      actions: (
        <div className="flex items-center gap-6 justify-center">
          {prop.status === "enabled" && (
            <Button
              bsStyle="danger"
              outline="true"
              title="Book Appointment"
              style={{ padding: "7px 10px" }}
              onClick={() => props.history.push(`/admin/appointment/create?patient=${prop.id}`)}
            >
              <Svg name="calendar-plus" className="w-4 h-4" />
            </Button>
          )}
          <Button
            bsStyle="danger"
            outline="true"
            title="View Patients Info"
            style={{ padding: "7px 10px" }}
            onClick={() => props.history.push(`/admin/users/${prop.id}`)}
          >
            <Svg name="edit" className="w-4 h-4" />
          </Button>
        </div>
      ),
    };
  };

  const bypassFilter = (filter, row, column) => {
    return true;
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center w-full">
            <div className="title">Customers</div>
            <Button
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
              onClick={() => {
                setReportModalVisible(true);
              }}
            >
              <Svg name="report" className="w-4 h-4" />
              Reports
            </Button>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <ReactTable
              loading={loading}
              loadingText="Loading accounts..."
              noDataText={error ? error : loading ? "" : "No accounts found..."}
              data={data}
              onFetchData={debounce(fetchData, 800)}
              defaultFilterMethod={bypassFilter}
              filterable
              columns={[
                {
                  Header: "First Name",
                  accessor: "firstName",
                  sortable: false,
                  filterable: true,
                },
                {
                  Header: "Last Name",
                  accessor: "lastName",
                  sortable: false,
                  filterable: true,
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                  sortable: false,
                  filterable: true,
                  maxWidth: 140,
                },
                {
                  Header: "Email",
                  accessor: "email",
                  sortable: false,
                  filterable: true,
                },
                {
                  Header: "Address",
                  accessor: "address",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Status",
                  accessor: "status",
                  sortable: false,
                  filterable: false,
                  maxWidth: 86,
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  minWidth: 100,
                  maxWidth: 100,
                },
              ]}
              defaultPageSize={pageSize}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <Button
                className="pagination-btn left btn-fill"
                disabled={!previousPageToken || loading}
                onClick={() => getUsers(previousPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={!nextPageToken || loading}
                onClick={() => getUsers(nextPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />

      <ReportsModal
        show={reportModalVisible}
        onHide={() => {
          setReportModalVisible(false);
        }}
      />
    </div>
  );
};

export default Users;
