import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import routes from "routes.js";
import { getSession, getAccessTokenFactory } from "api/auth";
import "assets/css/custom-css.css";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { style } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import "../assets/css/notification-css.css";
import axios from "axios";
import { parseError } from "api/common";
import useChatState from "../components/Chat/chat-state";
import { ChatEvents } from "components/Chat/chat-types";
import { getUser } from "api/auth";

const MainLayout = (props) => {
  const chatClient = useChatState((state) => state.chatClient);
  const createChatClient = useChatState((state) => state.createClient);
  const destroyChatClient = useChatState((state) => state.destroyClient);

  const [pageMini, setPageMini] = useState(false);
  const [connection, setConnection] = useState(null);
  const [startedConnection, setStartedConnection] = useState(null);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [roomStates, _setRoomStates] = useState([]);
  const roomStatesRef = React.useRef(roomStates);
  const setRoomStates = (states) => {
    roomStatesRef.current = states;
    _setRoomStates(states);
  };

  const navbar = false;
  const notificationSystem = useRef();
  const history = useHistory();

  useEffect(() => {
    if (getSession()) {
      const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}/SignalR/AdminHub`, getAccessTokenFactory())
        .withAutomaticReconnect()
        .build();
      setConnection(connection);
    }

    return history.listen(() => {
      if (history.action === "PUSH") {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
      }
      if (
        window.innerWidth < 993 &&
        history.action === "PUSH" &&
        document.documentElement.className.indexOf("nav-open") !== -1
      ) {
        document.documentElement.classList.toggle("nav-open");
      }
    });
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          setStartedConnection(connection);
          connection.on("NewNotification", (message) => {
            let msgData = JSON.parse(message);
            if (notificationSystem.current) {
              let actionTitle = "View Appointment";
              let actionRoute = "";
              let level = "info";

              switch (msgData.type) {
                case "lowTechnicianRating":
                case "asapFailedPushRequest":
                  actionTitle = "View Technician";
                  actionRoute = `/admin/lab-techs-details/${encodeURIComponent(
                    msgData.data.TechnicianId
                  )}`;
                  level = "warning";
                  break;
                case "appointmentPaymentIssue":
                case "appointmentPreAuthIssue":
                case "asapAppointmentRejected":
                case "asapAppointmentNoResponse":
                case "appointmentCancelledByTech":
                  actionTitle = "View Appointment";
                  actionRoute = `/admin/appointments/${encodeURIComponent(
                    msgData.data.AppointmentId
                  )}`;
                  level = "error";
                  break;
                case "newFeedback":
                  actionTitle = "View Feedback";
                  actionRoute = `/admin/feedback`;
                  level = "warning";
                  break;
              }

              const markAsRead = (id) => {
                axios
                  .put(`${process.env.REACT_APP_API_URL}/notifications/read`, { ids: [id] })
                  .then(() => {
                    window.dispatchEvent(new CustomEvent("notificationsRead", { detail: null }));
                  })
                  .catch((err) => {
                    parseError(err);
                  });
              };

              notificationSystem.current.addNotification({
                uid: msgData.id,
                title: `${msgData.title}`,
                message: `${msgData.body}`,
                level: level,
                autoDismiss: 6,
                dismissible: "both",
                action: {
                  label: actionTitle,
                  callback: function () {
                    history.push(actionRoute);
                    markAsRead(msgData.id);
                  },
                },
              });
            }

            window.dispatchEvent(new CustomEvent("newNotification", { detail: message }));
          });
        })
        .catch((e) => console.error(e));
    }
  }, [connection]);

  useEffect(() => {
    if (getSession() && process.env.REACT_APP_CHAT_ENABLED === "true") {
      createChatClient();
    }

    return () => {
      if (process.env.REACT_APP_CHAT_ENABLED === "true") {
        destroyChatClient();
      }
      if (document.documentElement.className.indexOf("nav-open") !== -1) {
        document.documentElement.classList.toggle("nav-open");
      }
    };
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_CHAT_ENABLED !== "true") {
      return;
    }

    if (chatClient) {
      chatClient.on(ChatEvents.onMessageReceived, onChatMessage);
      chatClient.on(ChatEvents.onChatRoomsList, onChatRoomListReceived);
      chatClient.on(ChatEvents.onMarkAsRead, onRoomRead);
      chatClient.on(ChatEvents.onConnected, onChatConnected);
      chatClient.connect();
    }

    return () => {
      if (chatClient) {
        chatClient.off(ChatEvents.onMessageReceived, onChatMessage);
        chatClient.off(ChatEvents.onChatRoomsList, onChatRoomListReceived);
        chatClient.off(ChatEvents.onMarkAsRead, onRoomRead);
        chatClient.off(ChatEvents.onConnected, onChatConnected);
      }
    };
  }, [chatClient]);

  useEffect(() => {
    if (roomStates) {
      const unreadTotalCount = roomStates.map((r) => r.unreadCount).reduce((a, b) => a + b, 0);
      setUnreadMessagesCount(unreadTotalCount);
    } else {
      setUnreadMessagesCount(0);
    }
  }, [roomStates]);

  const onChatConnected = (e) => {
    const authData = e.detail;
    if (authData.Result) {
      chatClient.getAllChatRooms(undefined, 999);
    }
  };

  const onChatRoomListReceived = (e) => {
    const data = e.detail;

    if (data.Result) {
      const roomsList = data.Data.Items;
      const states = roomsList.map((r) => {
        return { roomId: r.ChatRoomId, unreadCount: r.NumberOfUnReadMessages };
      });
      setRoomStates(states);
    }
  };

  const onChatMessage = (e) => {
    const data = e.detail;

    if (data.Result) {
      const currentUser = getUser();
      const msg = data.Data.hasOwnProperty("Message") ? data.Data.Message : data.Data;

      if (currentUser.id != msg.UserId && roomStatesRef && roomStatesRef.current) {
        const currentList = roomStatesRef.current;
        const isRoomExist = currentList.find((r) => r.roomId === msg.ChatRoomId);
        if (isRoomExist) {
          setRoomStates(
            currentList.map((r) => {
              if (r.roomId === msg.ChatRoomId) {
                return {
                  roomId: msg.ChatRoomId,
                  unreadCount: r.unreadCount + 1,
                };
              }
              return r;
            })
          );
        } else {
          setRoomStates([{ roomId: msg.ChatRoomId, unreadCount: 1 }, ...currentList]);
        }

        if (
          notificationSystem &&
          notificationSystem.current &&
          props.location.pathname !== "/admin/messaging"
        ) {
          notificationSystem.current.addNotification({
            uid: msg.Id,
            title: `New Message`,
            message: `${msg.MessageBody}`,
            level: "info",
            autoDismiss: 5,
            dismissible: "both",
            action: {
              label: "View Messages",
              callback: function () {
                history.push("/admin/messaging");
              },
            },
          });
        }
      }
    }
  };

  const onRoomRead = (e) => {
    const data = e.detail;

    if (data.Result) {
      const readData = data.Data;

      if (roomStatesRef && roomStatesRef.current) {
        const currentList = roomStatesRef.current;
        const isRoomExist = currentList.find((r) => r.roomId === readData.ChatRoomId);
        if (isRoomExist) {
          setRoomStates(
            currentList.map((r) => {
              if (r.roomId === readData.ChatRoomId) {
                return {
                  roomId: readData.ChatRoomId,
                  unreadCount: 0,
                };
              }
              return r;
            })
          );
        } else {
          setRoomStates([{ roomId: readData.ChatRoomId, unreadCount: 0 }, ...currentList]);
        }
      }
    }
  };

  const handleMiniClick = () => {
    setPageMini(!pageMini);
    document.body.classList.toggle("sidebar-mini");
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            exact={prop.exact || false}
            render={(routeProps) => (
              <prop.component {...routeProps} connection={startedConnection} />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  let session = getSession();

  if (!session) return <Redirect to={"/auth/login"} />;
  else
    return (
      <div className="wrapper">
        <NotificationSystem style={style} ref={notificationSystem} newOnTop={true} />
        <Sidebar {...props} unreadMessagesCount={unreadMessagesCount} mini={pageMini} />
        <div className="main-panel">
          <AdminNavbar {...props} handleMiniClick={handleMiniClick} navbar={navbar} />
          <Switch>{getRoutes(routes)}</Switch>
        </div>
      </div>
    );
};

export default MainLayout;
