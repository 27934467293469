import React from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import AppointmentsTables from "./AppointmentsTable.jsx";
import Svg from "components/Svg/Svg.jsx";
import AppointmentsStatistics from "./AppointmentsStatistics.jsx";

const Appointments = (props) => {
  return (
    <div className="main-content appointments flex flex-col gap-24" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center">
            <div className="title">Appointments</div>
            <Button
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
              onClick={() => {
                props.history.push("/admin/appointment/create");
              }}
            >
              <Svg name="calendar-plus" className="w-4 h-4" />
              New Appointment
            </Button>
          </div>
        }
        content={
          <div className="flex flex-col">
            <AppointmentsTables />
          </div>
        }
      />

      <Card title="Statistics" content={<AppointmentsStatistics />} />
    </div>
  );
};

export default Appointments;
