import React, { useState, useEffect } from "react";
import TextItem from "./TextListItemView.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Svg from "components/Svg/Svg";

const TextListControl = (props) => {
  const [items, setItems] = useState(props.items || []);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const onNewItem = () => {
    let items = [...props.items];
    items.push({ id: null, text: "", localizations: {} });

    if (props.onChange) {
      props.onChange(items);
    }
  };

  const onDelete = (index) => {
    let newItems = [...props.items];
    newItems.splice(index, 1);
    setItems(newItems);

    if (props.onChange) {
      props.onChange(newItems);
    }
  };

  const onChange = (item, index) => {
    let newItems = props.items.map((pItem, pInd) => {
      if (pInd === index) {
        return item;
      }

      return pItem;
    });

    setItems(newItems);

    if (props.onChange) {
      props.onChange(newItems);
    }
  };

  return (
    <div className="flex flex-col w-full">
      {items.map((item, ind) => (
        <TextItem
          disabled={props.disabled || false}
          onDelete={() => {
            onDelete(ind);
          }}
          onChange={onChange}
          text={item.text}
          index={ind}
          textLabel={props.textLabel}
          id={item.id}
          key={ind}
          localizations={item.localizations}
        />
      ))}
      {(!items || items.length === 0) && <div className="sub-text">No items...</div>}
      <Button
        disabled={props.disabled || false}
        bsStyle="danger"
        fill
        style={{ marginLeft: "auto" }}
        onClick={() => onNewItem()}
        type="button"
      >
        <Svg name="plus" className="w-4 h-4" />
        Add Item
      </Button>
    </div>
  );
};

export default TextListControl;
