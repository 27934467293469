import React, { useState, useEffect } from "react";
import { FormControl, Dropdown, MenuItem } from "react-bootstrap";
import { languages } from "../../../api/common.js";
import Svg from "components/Svg/Svg";

const LocalizableTextArea = ({ fieldName, localizations, value, ...props }) => {
  const defaultLang = Object.keys(languages)[0];
  const [currentLang, setCurrentLang] = useState(defaultLang);
  const [text, setText] = useState(value || "");

  useEffect(() => {
    if (currentLang === defaultLang) {
      setText(value);
    }
  }, [value]);

  const handleTextChange = (e) => {
    let newText = e.target.value;

    if (props.maxLength) {
      let mLength = parseInt(props.maxLength);
      newText = newText.length > mLength ? newText.substring(0, mLength - 1) : newText;
    }

    setText(newText);

    if (defaultLang === currentLang) {
      props.onChange(newText);
    } else {
      let newTranslation = {};
      newTranslation[fieldName] = {};
      newTranslation[fieldName][currentLang] = newText;
      props.onLocalizationChange(newTranslation);
    }
  };

  const onLangChange = (langKey) => {
    setCurrentLang(langKey);
    if (langKey === defaultLang) {
      setText(value || "");
    } else {
      let localizedValue = "";
      if (Object.keys(localizations).includes(fieldName)) {
        localizedValue = localizations[fieldName][langKey] || "";
      }
      setText(localizedValue);
    }
  };

  return (
    <div
      className="flex items-center localizable-input"
      style={Object.assign({ position: "relative" }, props.style || {})}
    >
      <FormControl
        disabled={props.disabled || false}
        type="textarea"
        maxLength={props.maxLength || 500}
        componentClass="textarea"
        rows={props.rows || 4}
        style={{ paddingRight: "76px" }}
        placeholder={props.placeholder || ""}
        value={text}
        onChange={handleTextChange}
      />

      <Dropdown
        id="localizable-selector-dropdown"
        style={{ position: "absolute", right: "1px", top: "1px" }}
      >
        <Dropdown.Toggle>
          <Svg name="language" className="w-4 h-4" />
          {languages[currentLang]}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.keys(languages).map((langKey) => (
            <MenuItem onClick={() => onLangChange(langKey)} key={langKey}>
              {languages[langKey]}
            </MenuItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LocalizableTextArea;
