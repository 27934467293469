import React, { useState, useEffect, useRef } from "react";
import { FormGroup, ControlLabel, OverlayTrigger, Popover } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Swal from "sweetalert2";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import Svg from "components/Svg/Svg.jsx";
import PlaceholderImage from "../../../assets/img/placeholder.svg";
import { parseError } from "api/common.js";
import LocalizableTextArea from "../LocalizableInputs/LocalizableTextArea.jsx";
import { SketchPicker } from "react-color";

const defaultColors = [
  "#003fa3",
  "#6bd8e7",
  "#000000",
  "#004E9A",
  "#DE3C63",
  "#EEA620",
  "#37AB85",
  "#014751",
  "#CE9FB7",
  "#FF9382",
  "#CDAD7A",
  "#AABB5D",
  "#F96300",
  "#6C47FF",
  "#587052",
  "#7D3D54",
  "#AF2C2C",
  "#00A656",
];

const BannerPanel = (props) => {
  const imageFileInput = useRef();
  const [title, setTitle] = useState(props.banner ? props.banner.title : "");
  const [description, setDescription] = useState(props.banner ? props.banner.description : "");
  const [pictureData, setPictureData] = useState(
    props.banner && props.banner.images
      ? [props.banner.images.normal, props.banner.images.large]
      : []
  );
  const [picturePreview, setPicturePreview] = useState(null);
  const [localizations, setLocalizations] = useState({});
  const [titleColor, setTitleColor] = useState("#003fa3");
  const [descriptionColor, setDescriptionColor] = useState("#003fa3");

  useEffect(() => {
    if (props.banner) {
      initializeData(props.banner);
    } else {
      setTitle("");
      setDescription("");
      setPictureData([]);
      setPicturePreview(null);
      setLocalizations({});
      setTitleColor("#003fa3");
      setDescriptionColor("#003fa3");
    }
  }, [props.banner]);

  useEffect(() => {
    return function cleanup() {
      if (picturePreview) {
        URL.revokeObjectURL(picturePreview);
      }
    };
  }, [picturePreview]);

  const initializeData = (banner) => {
    setTitle(banner.title || "");
    setTitleColor(banner.titleColor);
    setDescriptionColor(banner.descriptionColor);
    setDescription(banner.description || "");
    if (banner.images) {
      setPictureData([banner.images.normal, banner.images.large]);
    }
    setLocalizations(banner.localizations || {});
  };

  const onImageFileUpload = () => {
    imageFileInput.current.click();
  };

  const onImageFileChange = (e) => {
    uploadImageFile(e.target.files[0]);
    try {
      setPicturePreview(URL.createObjectURL(e.target.files[0]));
    } catch (err) {
      console.error(err);
      setPicturePreview(null);
    }
  };

  const uploadImageFile = async (imgFile) => {
    try {
      let formData = new FormData();
      formData.append("file", imgFile, imgFile.name);
      formData.append("type", "banner");
      formData.append("sizes[]", 720);
      formData.append("sizes[]", 1600);

      let fileRes = await axios.post(
        `${process.env.REACT_APP_API_URL}/file/upload/image/multi`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setPictureData(fileRes.data);
      if (fileRes.data.length > 1) {
        onBannerChange({ images: { normal: fileRes.data[0], large: fileRes.data[1] } });
      }
    } catch (err) {
      setPictureData([]);
      if (picturePreview) {
        URL.revokeObjectURL(picturePreview);
        setPicturePreview(null);
      }

      let error = parseError(err);
      Swal.fire("Image Upload Error", error, "error");
    }
  };

  const onBannerChange = (changedData) => {
    if (props.onChange) {
      if (changedData) {
        props.onChange(Object.assign(props.banner || {}, changedData));
      } else {
        props.onChange(undefined);
      }
    }
  };

  return (
    <Card
      content={
        <div className="flex flex-col gap-24">
          <div className="flex items-start gap-24">
            <div className="flex flex-col gap-16">
              <FormGroup style={{ flexShrink: "0", marginBottom: "0" }}>
                <ControlLabel>
                  Banner Image: <span className="star">*</span>
                </ControlLabel>
                <div
                  className="flex flex-col gap-12 items-center"
                  style={{ flexShrink: "0", marginBottom: "0" }}
                >
                  <div
                    className="flex"
                    style={{
                      overflow: "hidden",
                      position: "relative",
                      width: "375px",
                      height: "240px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #eee",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "5px",
                      }}
                      src={
                        picturePreview
                          ? picturePreview
                          : pictureData.length > 0
                          ? pictureData[0].url
                          : PlaceholderImage
                      }
                      alt="Banner Preview"
                    />

                    <div
                      className="flex flex-col"
                      style={{
                        position: "absolute",
                        bottom: "24px",
                        left: "24px",
                        maxWidth: "50%",
                      }}
                    >
                      <div
                        className="text-bold"
                        style={{
                          color: titleColor,
                          fontFamily: "'Open Sans', sans-serif",
                          fontSize: "15px",
                          lineHeight: "22px",
                        }}
                      >
                        {title}
                      </div>
                      <div
                        style={{
                          color: descriptionColor,
                          fontFamily: "'Open Sans', sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {description}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-6 w-full">
                    <Button
                      style={{ width: "100%" }}
                      bsStyle="danger"
                      fill
                      onClick={onImageFileUpload}
                    >
                      <Svg name="upload" className="w-4 h-4" />
                      Upload Image
                    </Button>
                    <Button
                      style={{ width: "100%" }}
                      className="btn-error"
                      outline="true"
                      onClick={() => {
                        onBannerChange(undefined);
                      }}
                    >
                      Clear Banner
                    </Button>
                  </div>

                  <span className="sub-text text-xxs text-center">
                    Only JPEG/PNG images are allowed.
                  </span>

                  <input
                    accept="image/png,image/jpeg"
                    ref={imageFileInput}
                    id="file-upload"
                    style={{ display: "none" }}
                    type="file"
                    onChange={onImageFileChange}
                  />
                </div>
              </FormGroup>
            </div>

            <div className="separator vertical"></div>

            <div className="flex flex-col gap-16" style={{ width: "100%", marginBottom: "0" }}>
              <FormGroup style={{ marginBottom: "0" }}>
                <ControlLabel>Banner Title:</ControlLabel>
                <LocalizableTextArea
                  placeholder="Enter the title (up to 50 chacraters)..."
                  value={title}
                  maxLength={50}
                  rows={1}
                  onChange={(text) => {
                    setTitle(text);
                    onBannerChange({ title: text });
                  }}
                  onLocalizationChange={(translation) => {
                    let newLocalizations = Object.assign({}, localizations, translation);
                    setLocalizations(newLocalizations);
                    onBannerChange({ localizations: newLocalizations });
                  }}
                  fieldName="Title"
                  localizations={localizations}
                />
              </FormGroup>

              <FormGroup style={{ marginBottom: "0" }}>
                <ControlLabel>Banner Description:</ControlLabel>
                <LocalizableTextArea
                  placeholder="Enter the description (up to 200 characters)..."
                  value={description}
                  maxLength={200}
                  rows={3}
                  onChange={(text) => {
                    setDescription(text);
                    onBannerChange({
                      description: text,
                    });
                  }}
                  onLocalizationChange={(translation) => {
                    let newLocalizations = Object.assign({}, localizations, translation);
                    setLocalizations(newLocalizations);
                    onBannerChange({ localizations: newLocalizations });
                  }}
                  fieldName="Description"
                  localizations={localizations}
                />
              </FormGroup>

              <div className="flex items-start gap-32">
                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>Title Colour:</ControlLabel>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="left"
                    overlay={
                      <Popover id="title-color-popover" positionLeft={0} arrowOffsetLeft={0}>
                        <SketchPicker
                          disableAlpha={true}
                          presetColors={defaultColors}
                          color={titleColor}
                          onChange={(color) => {
                            setTitleColor(color.hex);
                            onBannerChange({ titleColor: color.hex });
                          }}
                        />
                      </Popover>
                    }
                  >
                    <Button bsStyle="danger" outline="true" style={{ padding: "10px 12px" }}>
                      <div className="color-preview" style={{ background: titleColor }}></div>
                    </Button>
                  </OverlayTrigger>
                </FormGroup>

                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>Description Colour:</ControlLabel>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="left"
                    overlay={
                      <Popover
                        id="descr-color-popover"
                        className="color-popover"
                        positionLeft={0}
                        arrowOffsetLeft={0}
                      >
                        <SketchPicker
                          disableAlpha={true}
                          presetColors={defaultColors}
                          color={descriptionColor}
                          onChange={(color) => {
                            setDescriptionColor(color.hex);
                            onBannerChange({ descriptionColor: color.hex });
                          }}
                        />
                      </Popover>
                    }
                  >
                    <Button bsStyle="danger" outline="true" style={{ padding: "10px 12px" }}>
                      <div className="color-preview" style={{ background: descriptionColor }}></div>
                    </Button>
                  </OverlayTrigger>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default BannerPanel;
