import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { FormControl } from "react-bootstrap";
import axios from "axios";
import { parseError } from "api/common.js";
import Svg from "components/Svg/Svg.jsx";
import Loader from "../../../views/Components/Loader/Loader.jsx";
import Swal from "sweetalert2";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import PlaceholderImage from "../../../assets/img/placeholder.svg";

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="service-drag-container"
    >
      <div className="service-card">
        <img
          src={
            props.service.picture && props.service.picture.url
              ? props.service.picture.url
              : PlaceholderImage
          }
          alt={props.service.title || ""}
        />
        <div className="flex flex-col gap-2">
          <span className="service-title">{props.service.title}</span>
          <span className="service-price">{`$${new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            signDisplay: "never",
          }).format(props.service.price > 0 ? props.service.price / 100.0 : 0)}`}</span>
        </div>

        <div className="flex flex-col items-center gap-4 ml-auto">
          <Button
            bsStyle="danger"
            outline="true"
            style={{ padding: "2px 4px" }}
            title="View or Edit Service"
            onClick={() => {
              if (props.onEdit) {
                props.onEdit(props.service);
              }
            }}
          >
            <Svg name="edit" className="w-4 h-4" />
          </Button>
          <Button
            bsStyle="default"
            outline="true"
            className="btn-error"
            title="Delete Service"
            style={{ padding: "2px 4px" }}
            onClick={() => {
              if (props.onDelete) {
                props.onDelete(props.service);
              }
            }}
          >
            <Svg name="trash" className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}

const ServicesView = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [searchQuery, setSearchQuery] = useState("");

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    getServices();
  }, []);

  const getServices = (state) => {
    if (state && state.pageSize > 15) {
      return;
    }

    setLoading(true);
    setError(null);
    setData([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/services`)
      .then((res) => {
        if (res.data.length > pageSize) {
          setPageSize(res.data.length);
        }
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const deleteService = (item) => {
    Swal.fire({
      title: "Delete Service",
      text: `Are you sure you want to delete '${item.title}' service? If service already has assigments`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Service",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/services/${item.id}`)
          .then(() => {
            getServices();

            Swal.fire({
              title: `Service '${item.title}' has been deleted successfully.`,
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setData((items) => {
        const oldIndex = items.findIndex((s) => s.id === active.id);
        const newIndex = items.findIndex((s) => s.id === over.id);

        let orderedList = arrayMove(items, oldIndex, newIndex);
        updateOrder(orderedList);
        return orderedList;
      });
    }
  };

  const updateOrder = (services) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/services/order`, {
        services: Object.fromEntries(services.map((s) => [`SE#${s.id}`, services.indexOf(s) + 1])),
      })
      .then(() => {})
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Order Update Error", error, "error");
      });
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center">
            <div className="title">Services</div>
            <Button
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
              onClick={() => {
                props.history.push("/admin/services/new");
              }}
            >
              <Svg name="plus" className="w-4 h-4" />
              New Service
            </Button>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <div style={{ position: "relative" }} className="flex items-center gap-12">
              <Svg
                name="search"
                style={{
                  color: "var(--sub-color)",
                  position: "absolute",
                  left: "12px",
                  top: "10px",
                  width: "20px",
                  height: "20px",
                }}
              />
              <FormControl
                style={{ paddingLeft: "36px" }}
                disabled={loading}
                type="text"
                maxLength="100"
                name="reports-search-query"
                value={searchQuery}
                placeholder="Search services..."
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
            </div>

            <div className="separator horizontal"></div>

            {loading && (
              <div className="flex flex-col items-center justify-center">
                <Loader title="Loading services..." />
              </div>
            )}

            {error && (
              <div
                className="error-alert"
                style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
              >
                {error}
              </div>
            )}

            <div className="services-grid">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToParentElement]}
              >
                <SortableContext
                  items={data.filter(
                    (s) => s.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
                  )}
                  strategy={rectSortingStrategy}
                >
                  {data
                    .filter((s) => s.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
                    .map((service) => (
                      <SortableItem
                        key={service.id}
                        id={service.id}
                        service={service}
                        onDelete={deleteService}
                        onEdit={(item) => {
                          props.history.push(`/admin/services/${item.id}`);
                        }}
                        index={data.findIndex((s) => s.id === service.id) + 1}
                      />
                    ))}
                </SortableContext>
              </DndContext>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ServicesView;
