import React, { useEffect, useRef, useState } from "react";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import moment from "moment";
import { parseError, timeFormat, dateTimeFormat } from "api/common.js";
import Svg from "components/Svg/Svg.jsx";
import throttle from "lodash.throttle";
import Loader from "views/Components/Loader/Loader.jsx";
import { FormControl } from "react-bootstrap";
import debounce from "lodash.debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import useChatState from "components/Chat/chat-state";
import { ChatEvents } from "components/Chat/chat-types";
import { getUser } from "../../../api/auth.js";
import { formatPhoneNumber } from "react-phone-number-input";
import Button from "components/CustomButton/CustomButton.jsx";

const ChatRoomItem = ({ item, onClick = () => {}, selected = false, ...props }) => {
  return (
    <div
      {...props}
      onClick={() => onClick(item)}
      className={`chat-room-list-item ${selected ? "selected" : ""}`}
    >
      {item.NumberOfUnReadMessages > 0 && <div className="chat-unread-badge"></div>}
      {item.Technician.picture && item.Technician.picture.url ? (
        <img
          alt="Profile Picture"
          src={item.Technician.picture.url}
          className="round chat-room-avatar"
        />
      ) : (
        <Svg name="profile" className="chat-room-avatar" />
      )}

      <div className="chat-room-titles">
        <div className="chat-room-title">
          {item.Technician.firstName} {item.Technician.lastName}
        </div>
        <div className="chat-room-subtitle">
          {item.ChatRoom.LastMessage ? item.ChatRoom.LastMessage.MessageBody : "--"}
        </div>
      </div>
    </div>
  );
};

const ChatMessage = ({ message, chatRoom, technicianId, ...props }) => {
  const messageDate = moment(message.CreatedAt);
  const currentUser = getUser();

  return (
    <div
      className={`chat-message ${technicianId !== message.UserId ? "self" : "other-party"}`}
      {...props}
    >
      <div className="chat-message-text">{message.MessageBody}</div>
      <div className="chat-message-time">
        {currentUser.id !== message.UserId && message.UserId !== technicianId && (
          <span>{`${
            chatRoom.ChatRoom.Participants.find((p) => p.UserId === message.UserId).User.FullName
          } • `}</span>
        )}
        {messageDate.isSame(new Date(), "date")
          ? messageDate.format(timeFormat)
          : messageDate.format(dateTimeFormat)}
      </div>
    </div>
  );
};

const MessagingView = (props) => {
  const pageLimit = 20;
  const currentUser = getUser();

  const chatClient = useChatState((state) => state.chatClient);
  const [roomsLoading, setRoomsLoading] = useState(true);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [error, setError] = useState(null);

  const [technicians, setTechnicians] = useState([]);
  const [chatRooms, setChatRooms] = useState([]);
  const [rooms, _setRooms] = useState([]);
  const [selectedRoom, _setSelectedRoom] = useState();
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [typingUserId, setTypingUserId] = useState();
  const [nextPageToken, setNextPageToken] = useState();

  const timelineRef = React.useRef();
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const [chatDisabled, setChatDisabled] = useState(true);

  const selectedRoomRef = React.useRef(selectedRoom);
  const setSelectedRoom = (room) => {
    selectedRoomRef.current = room;
    _setSelectedRoom(room);
  };

  const roomsRef = React.useRef(rooms);
  const setRooms = (rooms) => {
    roomsRef.current = rooms;
    _setRooms(rooms);
  };

  useEffect(() => {
    if (technicians.length === 0) {
      getTechnicians();
    }
  }, []);

  useEffect(() => {
    if (
      chatRooms &&
      technicians &&
      chatRooms.length > 0 &&
      technicians.length > 0 &&
      rooms.length === 0
    ) {
      const roomsList = chatRooms.map((room) => {
        const tech = technicians.find((t) => room.ChatRoom.Members.includes(t.id));
        return Object.assign(room, { Technician: tech });
      });

      setRooms(roomsList);
      setRoomsLoading(false);
    }
  }, [chatRooms, technicians]);

  useEffect(() => {
    if (chatClient) {
      chatClient.on(ChatEvents.onConnected, onChatConnected);
      chatClient.on(ChatEvents.onDisconnected, onDisconnected);
      chatClient.on(ChatEvents.onChatRoomsList, onChatRoomsListReceived);
      chatClient.on(ChatEvents.onTypingStatusChanged, onTypingStatusChanged);
      chatClient.on(ChatEvents.onMessagesHistoryReceived, onMessagesHistoryReceived);
      chatClient.on(ChatEvents.onMessageReceived, addNewMessage);
      chatClient.on(ChatEvents.onMarkAsRead, onChatRoomRead);

      if (rooms.length === 0) {
        chatClient.getAllChatRooms(undefined, 999);
      }

      setChatDisabled(false);
    }

    return () => {
      if (chatClient) {
        chatClient.off(ChatEvents.onConnected, onChatConnected);
        chatClient.off(ChatEvents.onDisconnected, onDisconnected);
        chatClient.off(ChatEvents.onChatRoomsList, onChatRoomsListReceived);
        chatClient.off(ChatEvents.onTypingStatusChanged, onTypingStatusChanged);
        chatClient.off(ChatEvents.onMessagesHistoryReceived, onMessagesHistoryReceived);
        chatClient.off(ChatEvents.onMessageReceived, addNewMessage);
        chatClient.off(ChatEvents.onMarkAsRead, onChatRoomRead);
      }
    };
  }, [chatClient]);

  const onChatConnected = (e) => {
    try {
      const authData = e.detail;
      onChatResponse(authData);

      if (authData.Result) {
        setChatDisabled(false);
        if (rooms.length === 0) {
          useChatState.getState().chatClient.getAllChatRooms(undefined, 999);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onDisconnected = (e) => {
    setChatDisabled(true);
  };

  const onChatRoomsListReceived = (e) => {
    try {
      const data = e.detail;
      onChatResponse(data);

      if (data.Result) {
        setChatRooms(data.Data.Items);
      } else {
        setError(data.ErrorMessage);
        setRoomsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getTechnicians = (state) => {
    setRoomsLoading(true);
    setError(null);
    setTechnicians([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/technicians?pageSize=999`)
      .then((res) => {
        setTechnicians(res.data.items);
      })
      .catch((err) => {
        let error = parseError(err);
        setRoomsLoading(false);
        setError(error);
      });
  };

  const onChatRoomSelected = (room) => {
    try {
      if (
        messagesLoading ||
        (selectedRoom && selectedRoom.ChatRoomId === room.ChatRoomId) ||
        chatDisabled
      ) {
        return;
      }

      setNextPageToken();
      setMessages([]);
      setSelectedRoom(room);
      setMessagesLoading(true);
      setMessageText("");
      setIsTyping(false);
      setTypingUserId();

      setTimeout(() => {
        useChatState.getState().chatClient.getMessages(room.ChatRoomId, undefined, pageLimit);
      }, 500);
    } catch (err) {
      console.error(err);
    }
  };

  const onMessagesHistoryReceived = (e) => {
    try {
      const historyData = e.detail;
      onChatResponse(historyData);

      if (historyData && historyData.Result) {
        const oldMessages = historyData.Data.Items;
        setMessages((prev) =>
          prev.concat(oldMessages.filter((om) => !prev.find((m) => m.Id === om.Id)))
        );
        setNextPageToken(historyData.Data.NextToken);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setMessagesLoading(false);
      markAllAsReadFunc();
    }
  };

  const onFetchMoreMessages = () => {
    if (chatClient && selectedRoom && !messagesLoading) {
      setMessagesLoading(true);
      chatClient.getMessages(selectedRoom.ChatRoomId, nextPageToken, pageLimit);
    }
  };

  const onTypingStatusChanged = (e) => {
    try {
      const messageData = e ? e.detail : null;

      if (
        selectedRoomRef &&
        selectedRoomRef.current &&
        messageData &&
        messageData.Data &&
        messageData.Data.ChatRoomId === selectedRoomRef.current.ChatRoomId &&
        currentUser.id !== messageData.Data.UserId
      ) {
        setIsTyping(messageData.Data.IsTyping);
        setTypingUserId(messageData.Data.UserId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addNewMessage = (e) => {
    try {
      const messageData = e.detail;
      onChatResponse(messageData);

      if (messageData && messageData.Result && messageData.Data) {
        const msg = messageData.Data.hasOwnProperty("Message")
          ? messageData.Data.Message
          : messageData.Data;

        if (
          selectedRoomRef &&
          selectedRoomRef.current &&
          msg.ChatRoomId === selectedRoomRef.current.ChatRoomId
        ) {
          setScrollDisabled(true);
          setMessages((prev) => [msg, ...prev]);

          setTimeout(() => {
            timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
            setScrollDisabled(false);
          }, 200);

          markAllAsReadFunc();
        }

        updateLatestChatRoomMessage(msg.ChatRoomId, msg);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateLatestChatRoomMessage = (roomId, message) => {
    try {
      if (roomsRef && roomsRef.current) {
        let roomsList = roomsRef.current;
        const roomToUpdate = roomsRef.current.find((r) => r.ChatRoomId === roomId);

        if (roomToUpdate) {
          let updatedRoom = JSON.parse(JSON.stringify(roomToUpdate));
          updatedRoom.ChatRoom.LastMessage = message;
          updatedRoom.ChatRoom.LastMessageId = message.Id;
          updatedRoom.ChatRoom.LastChatRoomMessageCreatedAt = message.CreatedAt;
          updatedRoom.LastChatRoomMessageCreatedAt = message.CreatedAt;

          if (message.UserId !== getUser().id) {
            updatedRoom.NumberOfUnReadMessages += 1;
          }

          roomsList = roomsList.map((r) => {
            return r.ChatRoomId === roomId ? updatedRoom : r;
          });
          setRooms(sortRoomsList(roomsList));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onChatRoomRead = (e) => {
    try {
      const readData = e ? e.detail : null;

      if (readData && readData.Result && roomsRef && roomsRef.current) {
        let roomsList = roomsRef.current;
        const readParams = readData.Data;
        const roomToUpdate = roomsRef.current.find((r) => r.ChatRoomId === readParams.ChatRoomId);

        if (roomToUpdate) {
          let updatedRoom = JSON.parse(JSON.stringify(roomToUpdate));
          updatedRoom.LastReadMessageId = readParams.LastReadMessageId;
          updatedRoom.NumberOfUnReadMessages = 0;

          roomsList = roomsList.map((r) => {
            return r.ChatRoomId === readParams.ChatRoomId ? updatedRoom : r;
          });
          setRooms(sortRoomsList(roomsList));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sortRoomsList = (source) => {
    return source.sort((a, b) => {
      const aDate = new Date(a.LastChatRoomMessageCreatedAt);
      const bDate = new Date(b.LastChatRoomMessageCreatedAt);
      return aDate > bDate ? -1 : aDate === bDate ? (aDate > bDate ? -1 : 1) : 1;
    });
  };

  const sendMessage = (message) => {
    message = (message || "").trim();

    if (message.length > 497) {
      message = message.substring(0, 497) + "...";
    }

    const chat = useChatState.getState().chatClient;
    if (chat && selectedRoom && message && message.length > 0) {
      chat.sendMessage(selectedRoom.ChatRoomId, message);
      setMessageText("");
    }
  };

  const onChatResponse = (response) => {
    if (!response.Result) {
      setError(response.ErrorMessage);
    }
  };

  const getTypingUserName = (userId) => {
    if (userId && selectedRoom) {
      const typingUser = selectedRoom.ChatRoom.Participants.find((p) => p.UserId === userId);
      if (typingUser) {
        return typingUser.User.FullName;
      }
    }

    return "";
  };

  const setTypingStatusFunc = React.useRef(
    throttle(() => {
      const chat = useChatState.getState().chatClient;
      if (chat && selectedRoomRef && selectedRoomRef.current) {
        chat.setTypingStatus(selectedRoomRef.current.ChatRoomId, true);
      }
    }, 3000)
  ).current;

  const resetTypingStatusFunc = React.useRef(
    debounce(() => {
      const chat = useChatState.getState().chatClient;
      if (chat && selectedRoomRef && selectedRoomRef.current) {
        chat.setTypingStatus(selectedRoomRef.current.ChatRoomId, false);
      }
    }, 2000)
  ).current;

  const markAllAsReadFunc = React.useRef(
    debounce(() => {
      const chat = useChatState.getState().chatClient;
      if (chat && selectedRoomRef && selectedRoomRef.current) {
        chat.markChatRoomAsRead(selectedRoomRef.current.ChatRoomId);
      }
    }, 1500)
  ).current;

  return (
    <div className="main-content messaging-page flex flex-col" style={{ padding: "24px" }}>
      <Card
        content={
          <div className="chat-container">
            {error && (
              <div className="chat-error-container">
                <div
                  className="error-alert"
                  style={{ margin: "12px auto", padding: "12px", maxWidth: "500px" }}
                >
                  {error}
                </div>
                <Button bsStyle="error" fill onClick={() => setError()}>
                  Close
                </Button>
              </div>
            )}
            <div className="chat-rooms-container">
              {roomsLoading && rooms.length === 0 && (
                <div className="chat-rooms-placeholder">
                  <Loader title="Loading chat..." />
                </div>
              )}
              {rooms.map((cr, ind) => (
                <React.Fragment key={cr.ChatRoomId}>
                  <ChatRoomItem
                    onClick={onChatRoomSelected}
                    selected={selectedRoom && selectedRoom.ChatRoomId === cr.ChatRoomId}
                    item={cr}
                  />
                  {ind !== rooms.length - 1 ? <div className="separator horizontal"></div> : null}
                </React.Fragment>
              ))}
            </div>
            <div className="separator vertical"></div>
            <div className="chat-messages-container">
              {!selectedRoom && (
                <div className="chat-messages-placeholder">
                  Select one of the chat rooms on the left
                  <br />
                  to view conversation history.
                </div>
              )}
              {selectedRoom && (
                <React.Fragment>
                  <div className="chat-header">
                    {selectedRoom.Technician.picture && selectedRoom.Technician.picture.url ? (
                      <img
                        alt="Profile Picture"
                        src={selectedRoom.Technician.picture.url}
                        className="round chat-header-avatar"
                      />
                    ) : (
                      <Svg name="profile" className="chat-header-avatar" />
                    )}
                    <div className="chat-header-titles">
                      <div className="chat-header-title">{`${selectedRoom.Technician.firstName} ${selectedRoom.Technician.lastName}`}</div>
                      <div className="chat-header-subtitle">
                        {`${formatPhoneNumber(selectedRoom.Technician.phone)} • ${
                          selectedRoom.Technician.email
                        }`}
                      </div>
                    </div>
                  </div>
                  <div className="chat-messages-list">
                    {chatDisabled && (
                      <div className="chat-loader">
                        <Loader title="Connecting to the chat..." />
                      </div>
                    )}

                    {!chatDisabled && !messagesLoading && messages.length === 0 && (
                      <div className="chat-loader text-center">
                        <div className="sub-text">
                          No messages here yet...
                          <br />
                          Start conversation by typing your message below.
                        </div>
                      </div>
                    )}
                    <div
                      id="scrollableDiv"
                      style={{
                        height: "calc(100vh - 68px - 82px - 112px)",
                        overflowY: "auto",
                        overflowX: "visible",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                      ref={timelineRef}
                    >
                      {isTyping && typingUserId && (
                        <span className="chat-typing-status">{`${getTypingUserName(
                          typingUserId
                        )} is typing...`}</span>
                      )}
                      <InfiniteScroll
                        dataLength={messages.length}
                        next={onFetchMoreMessages}
                        style={{ display: "flex", flexDirection: "column-reverse" }}
                        inverse={true}
                        hasMore={nextPageToken !== null && !scrollDisabled}
                        loader={<Loader title="Loading messages..." />}
                        scrollableTarget="scrollableDiv"
                      >
                        {messages.map((item) => (
                          <ChatMessage
                            chatRoom={selectedRoom}
                            key={item.Id}
                            message={item}
                            technicianId={selectedRoom.Technician.id}
                          />
                        ))}
                      </InfiniteScroll>
                    </div>
                  </div>
                  <div className="chat-input">
                    <FormControl
                      disabled={chatDisabled}
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                      type="text"
                      maxLength={500}
                      placeholder="Enter message..."
                      onKeyPress={(e) => {
                        if (e.charCode === 13 && e.target.value && e.target.value.length > 0) {
                          sendMessage(e.target.value);
                        }
                      }}
                      onBlur={resetTypingStatusFunc}
                      onKeyUp={resetTypingStatusFunc}
                      onKeyDown={setTypingStatusFunc}
                    />
                    <Svg
                      name="send"
                      className={chatDisabled || messageText.trim().length === 0 ? "disabled" : ""}
                      title="Send Message"
                      onClick={() => {
                        if (!chatDisabled && messageText.trim().length > 0) {
                          sendMessage(messageText);
                        }
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MessagingView;
