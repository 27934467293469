import React, { useEffect, useState } from "react";
import axios from "axios";
import { parseError } from "api/common.js";
import Loader from "../../../views/Components/Loader/Loader.jsx";
import Chart from "react-google-charts";

const AppointmentsStatistics = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [asapTasks, setAsapTasks] = useState([]);
  const [scheduledTasks, setScheduledTasks] = useState([]);

  useEffect(() => {
    loadStats();
  }, []);

  const loadStats = () => {
    setLoading(true);
    setError(false);

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/statistics`)
      .then((res) => {
        setLoading(false);
        prepareData(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const prepareData = (data) => {
    const scheduledData = data.summary.find((s) => s.type === "scheduled");
    const asapData = data.summary.find((s) => s.type === "asap");

    setAsapTasks([
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
      [
        "booked",
        `Booked ➜ Accepted (${secondsToDhms(asapData.bookedToAccepted)})`,
        null,
        null,
        asapData.bookedToAccepted * 1000,
        100,
        null,
      ],
      [
        "accept",
        `Accepted ➜ Started (${secondsToDhms(asapData.acceptedToStarted)})`,
        null,
        null,
        asapData.acceptedToStarted * 1000,
        100,
        "booked",
      ],
      [
        "start",
        `Started ➜ Completed (${secondsToDhms(asapData.startedToFinished)})`,
        null,
        null,
        asapData.startedToFinished * 1000,
        100,
        "accept",
      ],
      [
        "finish",
        `Completed ➜ Dropped Off (${secondsToDhms(asapData.finishedToDroppedOff)})`,
        null,
        null,
        asapData.finishedToDroppedOff * 1000,
        100,
        "start",
      ],
    ]);

    setScheduledTasks([
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
      [
        "accept",
        `Accepted ➜ Started (${secondsToDhms(scheduledData.acceptedToStarted)})`,
        null,
        null,
        scheduledData.acceptedToStarted * 1000,
        100,
        null,
      ],
      [
        "start",
        `Started ➜ Completed (${secondsToDhms(scheduledData.startedToFinished)})`,
        null,
        null,
        scheduledData.startedToFinished * 1000,
        100,
        "accept",
      ],
      [
        "finish",
        `Completed ➜ Dropped Off (${secondsToDhms(scheduledData.finishedToDroppedOff)})`,
        null,
        null,
        scheduledData.finishedToDroppedOff * 1000,
        100,
        "start",
      ],
    ]);
  };

  const secondsToDhms = (secs, long = false) => {
    if (isNaN(secs)) {
      return "--";
    }

    const d = Math.floor(secs / (3600 * 24));
    const h = Math.floor((secs / 3600) % 24);
    const m = Math.floor((secs / 60) % 60);
    const s = Math.floor(secs % 60);
    const dDisplay = d > 0 ? d + (long ? (d === 1 ? " day, " : " days, ") : "d ") : "";
    const hDisplay = h > 0 ? h + (long ? (h === 1 ? " hour, " : " hours, ") : "h ") : "";
    const mDisplay = m > 0 ? m + (long ? (m === 1 ? " minute, " : " minutes, ") : "m ") : "";
    const sDisplay = s > 0 ? s + (long ? (s === 1 ? " second" : " seconds") : "s") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  };

  return (
    <div className="flex flex-col gap-16 w-full">
      <span className="sub-text text-xxs" style={{ marginTop: "-12px" }}>
        These are average numbers across all the appointments that have completed any of the
        observed stages of the appointment flow.
      </span>

      {loading && (
        <div className="flex flex-col items-center justify-center">
          <Loader title="Loading Statistics..." />
        </div>
      )}

      {error && (
        <div
          className="error-alert"
          style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
        >
          {error}
        </div>
      )}

      {!loading && !error && (
        <div className="flex flex-col">
          <div className="flex flex-col gap-6">
            <div className="main-text text-xs text-semibold">SCHEDULED APPOINTMENTS</div>
            <span className="sub-text text-xxs">
              The stage 'Accepted ➜ Started' reflects time from the moment when technicians starts
              navigation to the appointment's location and the moment when the appointment has
              started.
            </span>
            <div className="flex flex-col" id="scheduled-gannt">
              {scheduledTasks.length === 0 && <span>No data available...</span>}
              {scheduledTasks.length > 0 && (
                <Chart
                  legendToggle
                  width={"100%"}
                  height={"120px"}
                  chartType="Gantt"
                  options={{
                    legend: { position: "top", maxLines: 3 },
                    gantt: {
                      barHeight: 24,
                      shadowEnabled: false,
                      barCornerRadius: 4,
                      criticalPathEnabled: false,
                      percentEnabled: false,
                      arrow: {
                        radius: 24,
                      },
                      labelStyle: {
                        fontName: "Inter",
                        fontSize: 13,
                      },
                      palette: [
                        {
                          color: "#0051d2",
                          dark: "#002766",
                          light: "#99c0ff",
                        },
                      ],
                    },
                  }}
                  loader={<div>Loading data...</div>}
                  data={scheduledTasks}
                  chartEvents={[
                    {
                      eventName: "ready",
                      callback: ({ chartWrapper, google }) => {
                        var observer = new MutationObserver(function (nodes) {
                          Array.prototype.forEach.call(nodes, function (node) {
                            if (node.addedNodes.length > 0) {
                              Array.prototype.forEach.call(node.addedNodes, function (addedNode) {
                                if (
                                  addedNode.tagName === "rect" &&
                                  addedNode.getAttribute("fill") === "white"
                                ) {
                                  addedNode.setAttribute("fill", "transparent");
                                  addedNode.setAttribute("stroke", "transparent");
                                  Array.prototype.forEach.call(
                                    addedNode.parentNode.getElementsByTagName("text"),
                                    function (label) {
                                      label.setAttribute("fill", "transparent");
                                    }
                                  );
                                }
                              });
                            }
                          });
                        });
                        observer.observe(document.getElementById("scheduled-gannt"), {
                          childList: true,
                          subtree: true,
                        });
                      },
                    },
                  ]}
                />
              )}
            </div>
          </div>

          <div className="separator horizontal" style={{ margin: "24px 0" }}></div>

          <div className="flex flex-col gap-6">
            <div className="main-text text-xs text-semibold">ASAP APPOINTMENTS</div>
            <div className="flex flex-col" id="asap-gannt">
              {asapTasks.length === 0 && <span>No data available...</span>}
              {asapTasks.length > 0 && (
                <Chart
                  width={"100%"}
                  height={"160px"}
                  chartType="Gantt"
                  options={{
                    gantt: {
                      barHeight: 24,
                      percentEnabled: false,
                      shadowEnabled: false,
                      barCornerRadius: 4,
                      criticalPathEnabled: false,
                      arrow: {
                        radius: 24,
                      },
                      labelStyle: {
                        fontName: "Inter",
                        fontSize: 13,
                      },
                      palette: [
                        {
                          color: "#0051d2",
                          dark: "#002766",
                          light: "#99c0ff",
                        },
                      ],
                    },
                  }}
                  loader={<div>Loading data...</div>}
                  data={asapTasks}
                  chartEvents={[
                    {
                      eventName: "ready",
                      callback: ({ chartWrapper, google }) => {
                        var observer = new MutationObserver(function (nodes) {
                          Array.prototype.forEach.call(nodes, function (node) {
                            if (node.addedNodes.length > 0) {
                              Array.prototype.forEach.call(node.addedNodes, function (addedNode) {
                                if (
                                  addedNode.tagName === "rect" &&
                                  addedNode.getAttribute("fill") === "white"
                                ) {
                                  addedNode.setAttribute("fill", "transparent");
                                  addedNode.setAttribute("stroke", "transparent");
                                  Array.prototype.forEach.call(
                                    addedNode.parentNode.getElementsByTagName("text"),
                                    function (label) {
                                      label.setAttribute("fill", "transparent");
                                    }
                                  );
                                }
                              });
                            }
                          });
                        });
                        observer.observe(document.getElementById("asap-gannt"), {
                          childList: true,
                          subtree: true,
                        });
                      },
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentsStatistics;
