import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Modal, FormControl } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import "react-phone-number-input/style.css";
import Svg from "components/Svg/Svg";
import FormPreviewModal from "./FormPreviewModal.jsx";

const ServiceFillFormModal = (props) => {
  const requisitionFileInput = useRef();
  const insuranceFileInput = useRef();
  const [questions, setQuestions] = useState([]);
  const [requisitionRequired, setRequisitionRequired] = useState(false);
  const [requisitionFile, setRequisitionFile] = useState();
  const [insuranceFile, setInsuranceFile] = useState();
  const [serviceData, setServiceData] = useState(null);
  const [insuranceFormId, setInsuranceFormId] = useState(null);
  const [requisitionFormId, setRequisitionFormId] = useState(null);
  const [formPreviewOpen, setFormPreviewOpen] = useState(false);

  useEffect(() => {
    setRequisitionRequired(false);
    setQuestions([]);
    setRequisitionFile(null);
    setRequisitionFormId(null);
    setInsuranceFile(null);
    setInsuranceFormId(null);

    if (props.service) {
      if (props.service.requisitionFormFile) {
        if (typeof props.service.requisitionFormFile === "string") {
          setRequisitionFormId(props.service.requisitionFormFile);
        } else {
          setRequisitionFile(props.service.requisitionFormFile);
        }
      }

      if (props.service.insuranceFormFile) {
        if (typeof props.service.insuranceFormFile === "string") {
          setInsuranceFormId(props.service.insuranceFormFile);
        } else {
          setInsuranceFile(props.service.insuranceFormFile);
        }
      }

      axios
        .get(`${process.env.REACT_APP_API_URL.replace("v1", "v2")}/services/${props.service.id}`)
        .then((res) => {
          setServiceData(res.data);

          let items = res.data.questionnaire;
          items.forEach((q) => {
            q.completed = false;
            q.optionIds = [];
            q.notes = "";
            q.version = "v2";

            if (props.service.questionnaire) {
              var question = props.service.questionnaire.find((qs) => qs.id === q.id);
              if (question) {
                q.completed = question.completed;
                q.optionIds = question.optionIds;
                q.notes = question.notes || "";
              }
            }
          });

          setQuestions(items);
          setRequisitionRequired(res.data.isRequisitionFormRequired);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.service]);

  const onRequisitionFileUpload = () => {
    requisitionFileInput.current.click();
  };

  const onRequisitionFileChange = (e) => {
    setRequisitionFile(e.target.files[0]);
    setRequisitionFormId(null);
  };

  const onInsuranceFileUpload = () => {
    insuranceFileInput.current.click();
  };

  const onInsuranceFileChange = (e) => {
    setInsuranceFile(e.target.files[0]);
    setInsuranceFormId(null);
  };

  const onComplete = () => {
    var svc = props.service;

    props.onConfirm({
      key: svc.key || null,
      id: svc.id,
      title: svc.title,
      price: svc.price,
      questionnaire: questions,
      requisitionFormFile: requisitionFile || requisitionFormId,
      insuranceFormFile: insuranceFile || insuranceFormId,
    });
  };

  const getServicePicture = () => {
    if (props.service && props.service.picture && props.service.picture.url) {
      return props.service.picture.url;
    } else if (serviceData && serviceData.picture && serviceData.picture.url) {
      return serviceData.picture.url;
    }

    return null;
  };

  const gerServiceDescription = () => {
    if (props.service && props.service.description) {
      return props.service.description;
    } else if (serviceData && serviceData.description) {
      return serviceData.description;
    }

    return "";
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{props.service ? props.service.title : "Service"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card
          content={
            <div className="flex flex-col gap-12">
              <div className="flex items-start gap-24">
                {getServicePicture() && (
                  <img
                    style={{
                      flexShrink: "0",
                      border: "1px solid var(--border-color)",
                      width: "72px",
                      height: "72px",
                      borderRadius: "6px",
                      overflow: "hidden",
                      objectFit: "cover",
                    }}
                    alt="Service Preview"
                    src={getServicePicture()}
                  />
                )}
                <div
                  className="text-regular text-normal"
                  dangerouslySetInnerHTML={{
                    __html: gerServiceDescription(),
                  }}
                ></div>
              </div>

              {questions.length > 0 && (
                <React.Fragment>
                  <div className="separator horizontal"></div>

                  <div className="flex flex-col gap-6">
                    <div className="main-text text-semibold">
                      QUESTIONNAIRE <span className="star">*</span>
                    </div>
                    <div className="grid grid-2col gap-24" style={{ marginTop: "12px" }}>
                      {questions.map((q) =>
                        q.options.length > 0 ? (
                          <div
                            className="flex flex-col gap-12"
                            key={`${q.id}-${q.completed}-${q.optionIds.length}`}
                          >
                            <span className="text-semibold">{q.description}</span>
                            <div className="flex flex-col gap-6">
                              {q.options.map((opt) => (
                                <div
                                  className="checkbox"
                                  key={`${opt.id}-${q.optionIds.includes(opt.id)}`}
                                >
                                  <input
                                    name={opt.id}
                                    type="checkbox"
                                    checked={q.optionIds.includes(opt.id)}
                                    onChange={() => {}}
                                  />
                                  <label
                                    onClick={() => {
                                      let question = questions.find((qq) => qq.id === q.id);
                                      setQuestions((list) =>
                                        list.map((qq) => {
                                          if (qq.id === question.id) {
                                            question.optionIds = question.optionIds.includes(opt.id)
                                              ? question.optionIds.filter((o) => o !== opt.id)
                                              : question.optionIds.concat([opt.id]);
                                            question.completed = question.optionIds.length > 0;
                                            return question;
                                          }
                                          return qq;
                                        })
                                      );
                                    }}
                                    htmlFor={opt.id}
                                  >
                                    {opt.description}
                                  </label>
                                </div>
                              ))}
                            </div>

                            <FormControl
                              required
                              type="text"
                              placeholder="Additional notes..."
                              value={q.notes}
                              onChange={(e) => {
                                let text = e.target.value;
                                let question = questions.find((qq) => qq.id === q.id);
                                setQuestions((list) =>
                                  list.map((qq) => {
                                    if (qq.id === question.id) {
                                      question.notes = text;
                                      return question;
                                    }
                                    return qq;
                                  })
                                );
                              }}
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col" key={q.id}>
                            <label>
                              <span className="text-semibold">{q.description}</span>
                            </label>

                            <FormControl
                              required
                              type="text"
                              placeholder="Additional notes..."
                              value={q.notes}
                              onChange={(e) => {
                                let text = e.target.value;
                                let question = questions.find((qq) => qq.id === q.id);
                                setQuestions((list) =>
                                  list.map((qq) => {
                                    if (qq.id === question.id) {
                                      question.completed = text.length > 0;
                                      question.notes = text;
                                      return question;
                                    }
                                    return qq;
                                  })
                                );
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}

              {requisitionRequired && (
                <React.Fragment>
                  <div className="separator horizontal"></div>

                  <div className="flex items-center gap-16">
                    <div className="flex flex-col gap-4">
                      <span className="text-semibold main-text">REQUISITION FORM</span>
                      <span className="text-xxs sub-text text-light">
                        UPLOAD PNG, JPEG OR PDF FILES
                      </span>
                    </div>

                    <input
                      accept="image/png, image/jpeg, application/pdf"
                      ref={requisitionFileInput}
                      id="file-upload"
                      style={{ display: "none" }}
                      type="file"
                      onChange={onRequisitionFileChange}
                    />

                    {!requisitionFile && !requisitionFormId && (
                      <Button
                        fill
                        bsStyle="danger"
                        style={{ marginLeft: "auto" }}
                        onClick={onRequisitionFileUpload}
                      >
                        <Svg name="upload" className="w-4 h-4" />
                        <span>Upload File</span>
                      </Button>
                    )}
                  </div>

                  {(requisitionFile || requisitionFormId) && (
                    <div className="flex items-center">
                      <div className="text-bold text-lg main-text">
                        {requisitionFile ? requisitionFile.name : "Requisition Form"}
                      </div>
                      <Button
                        fill
                        bsStyle="danger"
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          setRequisitionFile(null);
                          setRequisitionFormId(null);
                        }}
                      >
                        <Svg name="trash" className="w-4 h-4" />
                        <span>Remove File</span>
                      </Button>
                    </div>
                  )}
                </React.Fragment>
              )}

              <div className="separator horizontal"></div>

              {false && (
                <div className="flex items-center gap-16">
                  <div className="flex flex-col gap-4">
                    <span className="text-semibold main-text">INSURANCE FORM</span>
                    <span className="text-xxs sub-text text-light">
                      UPLOAD PNG, JPEG OR PDF FILES
                    </span>
                  </div>

                  <input
                    accept="image/png, image/jpeg, application/pdf"
                    ref={insuranceFileInput}
                    id="file-upload"
                    style={{ display: "none" }}
                    type="file"
                    onChange={onInsuranceFileChange}
                  />

                  {!insuranceFile && !insuranceFormId && (
                    <Button
                      fill
                      bsStyle="danger"
                      style={{ marginLeft: "auto" }}
                      onClick={onInsuranceFileUpload}
                    >
                      <Svg name="upload" className="w-4 h-4" />
                      <span>Upload File</span>
                    </Button>
                  )}
                </div>
              )}

              {(insuranceFile || insuranceFormId) && (
                <div className="flex items-center">
                  <div className="text-bold text-lg main-text">
                    {insuranceFile ? insuranceFile.name : "Insurance Form"}
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    {props.service && props.service.key && insuranceFormId && (
                      <React.Fragment>
                        <Button
                          bsStyle="danger"
                          fill
                          onClick={() => {
                            setFormPreviewOpen(true);
                          }}
                        >
                          <Svg name="eye" className="w-4 h-4" />
                          Preview
                        </Button>
                        <FormPreviewModal
                          show={formPreviewOpen}
                          formKey={props.service.key}
                          onHide={() => setFormPreviewOpen(null)}
                        />
                      </React.Fragment>
                    )}
                    <Button
                      fill
                      bsStyle="danger"
                      style={{ marginLeft: "6px" }}
                      onClick={() => {
                        setInsuranceFile(null);
                        setInsuranceFormId(null);
                      }}
                    >
                      <Svg name="trash" className="w-4 h-4" />
                      <span>Remove File</span>
                    </Button>
                  </div>
                </div>
              )}

              <div className="separator horizontal"></div>

              <Button
                bsStyle="danger"
                disabled={questions.filter((q) => q.completed).length !== questions.length}
                fill
                onClick={onComplete}
              >
                Add Service
              </Button>
            </div>
          }
        />
      </Modal.Body>
    </Modal>
  );
};

export default ServiceFillFormModal;
