import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { NavDropdown, MenuItem } from "react-bootstrap";
import Svg from "../../components/Svg/Svg.jsx";
import { logout, getUser } from "api/auth";
import axios from "axios";
import { parseError } from "api/common.js";

const HeaderLinks = (props) => {
  const [userData, setUserData] = useState(getUser());
  const [hasNotifications, setHasNotifications] = useState(false);

  useEffect(() => {
    subscribeForEvents();
    getUnreadNotifications();

    return function cleanup() {
      unsubscribeFromEvents();
    };
  }, []);

  const onLogout = () => {
    logout();
    props.history.push("/auth/login");
  };

  const getUnreadNotifications = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/notifications?pageSize=${1}&onlyUnread=true`)
      .then((res) => {
        setHasNotifications(res.data && res.data.items.length > 0);
      })
      .catch((err) => {
        parseError(err);
      });
  };

  const subscribeForEvents = () => {
    window.addEventListener("notificationsRead", getUnreadNotifications, true);
  };

  const unsubscribeFromEvents = () => {
    window.removeEventListener("notificationsRead", getUnreadNotifications, true);
  };

  return (
    <div className="nav flex items-center">
      <button
        title="View Notifications"
        className="btn btn-empty flex-shrink-0"
        style={{ position: "relative" }}
        onClick={() => {
          props.history.push("/admin/my-notifications");
        }}
      >
        <span className="notification-badge"></span>
        <Svg name="bell" className="flex-shrink-0" style={{ width: "24px", height: "24px" }} />
      </button>

      <NavDropdown
        style={{ height: "44px" }}
        direction="down right"
        id="user-profile-dropdown"
        title={
          <div
            className="flex items-center gap-12"
            style={{ height: "44px", margin: "-8px 0px -12px 0px" }}
          >
            <div className="flex flex-col text-right">
              <span className="main-text text-semibold text-xxs">
                {userData.firstName} {userData.lastName}
              </span>
              <span className="sub-text text-regular text-xxs text-nowrap">{userData.email}</span>
            </div>
            <Svg
              name="profile"
              className="flex-shrink-0"
              style={{ width: "40px", height: "40px" }}
            />
            <Svg
              name="chevron-down"
              className="flex-shrink-0"
              style={{ width: "20px", height: "20px", transform: "rotate(180deg)" }}
            />
          </div>
        }
        noCaret
      >
        <MenuItem style={{ width: "240px" }} title="Logout from Account" onClick={() => onLogout()}>
          <div className="main-text flex items-center gap-4">
            <Svg name="logout" className="w-5 h-5 sub-text" />
            Logout
          </div>
        </MenuItem>
      </NavDropdown>
    </div>
  );
};

export default withRouter(HeaderLinks);
