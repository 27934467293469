import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "../../Components/Loader/Loader.jsx";
import { parseError } from "api/common.js";
import "react-phone-number-input/style.css";
import Svg from "components/Svg/Svg.jsx";

const QuestionnaireModal = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    setQuestions([]);
    setError(null);
    setLoading(true);

    if (props.show && props.itemKey && props.appointmentCode) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/appointments/${
            props.appointmentCode
          }/questionnaire?key=${encodeURIComponent(props.itemKey)}`
        )
        .then((res) => {
          setLoading(false);

          setQuestions(res.data);
        })
        .catch((err) => {
          let error = parseError(err);
          setLoading(false);
          setError(error);
        });
    }
  }, [props.show, props.itemKey, props.appointmentCode]);

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Service Questionnaire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="flex flex-col gap-16">
            {loading && (
              <div className="flex flex-col items-center justify-center">
                <Loader title="Loading questionnaire..." />
              </div>
            )}

            {error && (
              <div className="error-alert" style={{ padding: "0.6rem" }}>
                {error}
              </div>
            )}
          </div>

          <div className="flex flex-col gap-32" style={{ padding: "6px 12px" }}>
            {questions.map((question, idx) =>
              question.options.length > 0 ? (
                <div className="flex flex-col gap-6" key={question.id}>
                  <span className="theme-dark-text text-semibold">
                    {idx + 1}. {question.description}
                  </span>
                  <div className="flex flex-col gap-6" style={{ marginLeft: "14px" }}>
                    {question.options.map((opt, idx) => (
                      <div key={idx} className="flex items-center gap-6">
                        <Svg name="check-circle" className="w-4 h-4 success-text" /> {opt}
                      </div>
                    ))}
                  </div>
                  <div
                    style={{ marginLeft: "14px", marginTop: "4px" }}
                    className="questionnaire-notes"
                  >
                    {question.notes || "--"}
                  </div>
                </div>
              ) : (
                <div
                  className="theme-dark-text text-semibold flex flex-col gap-6"
                  key={question.id}
                >
                  <div className="flex items-center gap-6">
                    {idx + 1}. {question.description}{" "}
                    <Svg name="check-circle" className="w-4 h-4 success-text" />
                  </div>

                  <div
                    style={{ marginLeft: "14px", marginTop: "4px" }}
                    className="questionnaire-notes"
                  >
                    {question.notes || "--"}
                  </div>
                </div>
              )
            )}
          </div>

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionnaireModal;
