import React, { useEffect, useState, useRef } from "react";
import AppointmentsMap from "./AppointmentsMap";
import DashboardAggregates from "./DashboardAggregates";
import axios from "axios";
import { parseError } from "../../../api/common";
import Loader from "../../Components/Loader/Loader";
import moment from "moment";

const Dashboard = (props) => {
  const [technicians, setTechnicians] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statuses] = useState(["ended", "completed", "cancelled"]);
  const [serviceCentres, setServiceCentres] = useState([]);
  const [defaultCentre, setDefaultCentre] = useState({});
  const [appointmentsSummary, setAppointmentsSummary] = useState({});
  const [aggregates, setAggregates] = useState({
    totalUsers: "0",
    companyEarnings: "$0",
    totalAppointments: "0",
  });

  const appointmentsRef = useRef();
  const summaryRef = useRef();
  appointmentsRef.current = appointments;
  summaryRef.current = appointmentsSummary;

  useEffect(() => {
    appointmentsRef.current = appointments;
    summaryRef.current = appointmentsSummary;
  }, [appointments, appointmentsSummary]);

  const loadInitialData = (centre) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/${centre.id}`)
      .then((res) => {
        const data = res.data;
        if (res.status === 200) {
          setAppointments(data.appointments);
          setTechnicians(data.technicians);
          setAppointmentsSummary(data.appointmentsSummary);
          setAggregates({
            totalUsers: `${data.totalUsers}`,
            companyEarnings: `$${(data.companyEarnings / 100).toLocaleString("en-US")}`,
            totalAppointments: `${data.totalAppointments}`,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(parseError(error));
      });
  };

  const loadServiceCentres = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${process.env.REACT_APP_API_URL}/service-centres`);
      let centres = res.data.items.map((serviceCentre) => {
        return {
          id: serviceCentre.id,
          name: serviceCentre.name,
        };
      });
      setServiceCentres(centres);
      if (centres.length > 0)
        setDefaultCentre({
          id: centres[0].id,
          name: centres[0].name,
        });
      return centres;
    } catch (e) {
      setLoading(false);
      setError(parseError(e));
    }
  };

  useEffect(() => {
    loadServiceCentres()
      .then((centres) => loadInitialData(centres[0]))
      .catch((e) => parseError(e));
  }, []);

  useEffect(() => {
    const self = this;

    if (props.connection) {
      props.connection.on("UpdateAggregates", (message) => {
        let data = JSON.parse(message);
        setAggregates({
          totalUsers: `${data.totalUsers}`,
          companyEarnings: `$${(data.companyEarnings / 100).toLocaleString("en-US")}`,
          totalAppointments: `${data.totalAppointments}`,
        });
      });

      props.connection.on("AppointmentUpdate", (message) => {
        let data = JSON.parse(message);

        let today = moment();
        let appDate = moment(
          `${data.startTime.day}/${data.startTime.month}/${data.startTime.year}`,
          "D/M/YYYY"
        );
        let todaysAppointment = today.isSame(appDate, "day");
        onUpdateAppointmentsSummary(data, todaysAppointment);

        if (statuses.includes(data.status) || !todaysAppointment) {
          setAppointments((appts) => appts.filter((appt) => appt.id !== data.id));
        } else {
          setAppointments((appts) => {
            let found = false;
            for (let index = 0; index < appts.length && !found; index++)
              if (appts[index].id === data.id) {
                appts[index] = data;
                found = true;
              }
            if (!found) appts = [...appts, data];
            return appts;
          });
        }
      });

      props.connection.on("TechnicianUpdate", (message) => {
        let data = JSON.parse(message);
        setTechnicians((techs) => {
          let found = false;
          for (let index = 0; index < techs.length && !found; index++)
            if (techs[index].id === data.id) {
              techs[index] = data;
              found = true;
            }
          if (!found) techs = [...techs, data];
          return techs;
        });
        setUpdateCounter(prevState => (prevState+1) % Number.MAX_SAFE_INTEGER);
      });
    }
  }, [props.connection]);

  const onUpdateAppointmentsSummary = (app, isTodayApp) => {
    try {
      let existingApp = appointmentsRef.current.find((a) => a.id === app.id);
      let summary = Object.assign({}, summaryRef.current);

      if (app.status === "technician_assigned") {
        if (!existingApp) {
          summary.scheduledCount += 1;
          summary.total += 1;
        } else if (!isTodayApp && existingApp) {
          summary.scheduledCount -= 1;
          summary.total -= 1;
        }
      } else if (app.status === "cancelled" && isTodayApp) {
        summary.cancelledCount += 1;
        if (existingApp) {
          switch (existingApp.status) {
            case "technician_assigned":
              summary.scheduledCount -= 1;
              break;
            case "technician_in_transit":
            case "technician_at_location":
              summary.inTransitCount -= 1;
              break;
            case "started":
              summary.startedCount -= 1;
              break;
          }
        }
      } else if (app.status === "technician_in_transit") {
        summary.scheduledCount -= 1;
        summary.inTransitCount += 1;
      } else if (app.status === "started") {
        summary.inTransitCount -= 1;
        summary.startedCount += 1;
      } else if (app.status === "ended") {
        summary.startedCount -= 1;
        summary.completedCount += 1;
      }

      summary.scheduledCount = Math.max(summary.scheduledCount, 0);
      summary.inTransitCount = Math.max(summary.inTransitCount, 0);
      summary.startedCount = Math.max(summary.startedCount, 0);
      summary.completedCount = Math.max(summary.completedCount, 0);
      summary.cancelledCount = Math.max(summary.cancelledCount, 0);
      summary.total = Math.max(summary.total, 0);

      setAppointmentsSummary(summary);
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <div className="main-content dashboard">
      {loading && (
        <div className="flex flex-col items-center justify-center">
          <Loader title="Loading Dashboard..." />
        </div>
      )}
      {error && (
        <div
          className="error-alert"
          style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
        >
          {error}
        </div>
      )}
      {!loading && !error && (
        <div className="flex flex-col gap-16">
          <DashboardAggregates
            totalUsers={aggregates.totalUsers}
            companyEarnings={aggregates.companyEarnings}
            totalAppointments={aggregates.totalAppointments}
          />

          <div className="dashboard-map">
            <AppointmentsMap
              height={640}
              appointments={appointments}
              technicians={technicians}
              summaryData={appointmentsSummary}
              updateCounter={updateCounter}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
