import React, { useEffect, useState } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { FormControl } from "react-bootstrap";
import { parseError } from "../../../api/common";
import Svg from "../../../components/Svg/Svg";
import PlaceholderImage from "../../../assets/img/placeholder.svg";

const CorporateListPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [nextPageToken, setNextPageToken] = useState("");
  const [previousPageToken, setPreviousPageToken] = useState("");
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const pageToken = searchParams.get("pageToken");
    const pageBackward = searchParams.get("backward");

    getData(pageToken, pageBackward === "true" || false);
  }, []);

  useEffect(() => {
    if (props.location.search.length === 0 && data.length > 0 && !loading) {
      getData();
    }
  }, [props.location]);

  const getData = (pageToken = null, isBackward = false) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/corporate?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward}&query=${searchQuery}`
      )
      .then((res) => {
        const nextPageToken = res.data.nextPageToken;
        const prevPageToken = res.data.previousPageToken;

        if (res.data.items.length > pageSize) {
          setPageSize(res.data.items.length);
        }
        setPreviousPageToken(prevPageToken);
        setNextPageToken(nextPageToken);
        setError(null);
        setData(
          res.data.items.map((item) => {
            return {
              id: item.id,
              picture: (
                <img
                  style={{
                    width: "64px",
                    height: "64px",
                    overflow: "hidden",
                    borderRadius: "6px",
                    objectFit: "cover",
                    border: "1px solid var(--border-color)",
                  }}
                  src={item.picture && item.picture.url ? item.picture.url : PlaceholderImage}
                  alt="Corporate Profile Picture"
                />
              ),
              name: item.name,
              email: item.email,
              status: (
                <div className={`badge ${item.status === "enabled" ? "success" : "error"}`}>
                  {item.status}
                </div>
              ),
              actions: (
                <div className="flex items-center gap-6">
                  <Button
                    bsStyle="danger"
                    outline="true"
                    title="View / Edit Profile"
                    style={{ padding: "7px 10px" }}
                    onClick={() => props.history.push(`/admin/corporate/${item.id}`)}
                  >
                    <Svg name="edit" className="w-4 h-4" />
                  </Button>
                </div>
              ),
            };
          })
        );

        if (pageToken !== null) {
          if (!prevPageToken) {
            props.history.replace(props.history.location.pathname, null);
          } else {
            props.history.replace(
              `${props.history.location.pathname}?${
                pageToken !== null
                  ? `pageToken=${encodeURIComponent(pageToken)}&backward=${
                      isBackward ? "true" : "false"
                    }`
                  : ""
              }`,
              null
            );
          }
        }

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(parseError(e));
      });
  };

  return (
    <div className="main-content flex flex-col">
      <Card
        title={
          <div className="flex items-center w-full">
            <div className="title">Corporate Profiles</div>
            <div style={{ marginLeft: "auto" }}>
              <Button
                style={{ marginLeft: "auto" }}
                bsStyle="danger"
                fill
                onClick={() => {
                  props.history.push("/admin/corporate/new");
                }}
              >
                <Svg name="plus" className="w-4 h-4" />
                New Profile
              </Button>
            </div>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <div style={{ position: "relative" }} className="flex items-center gap-12">
              <Svg
                name="search"
                style={{
                  color: "var(--sub-color)",
                  position: "absolute",
                  left: "12px",
                  top: "10px",
                  width: "20px",
                  height: "20px",
                }}
              />
              <FormControl
                style={{ paddingLeft: "36px" }}
                disabled={loading}
                type="text"
                maxLength="100"
                name="reports-search-query"
                value={searchQuery}
                placeholder="Search profiles by name..."
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    getData();
                  }
                }}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
              <Button
                disabled={loading}
                loading={loading}
                style={{ height: "38px" }}
                bsStyle="danger"
                fill
                onClick={() => getData()}
              >
                <Svg name="search" className="w-4 h-4" />
                Search
              </Button>
            </div>

            <div className="separator horizontal"></div>

            <ReactTable
              loading={loading}
              loadingText={"Loading corporate profiles..."}
              noDataText={error ? error : loading ? "" : "No corporate profiles found..."}
              sortable={false}
              filterable={false}
              data={data}
              columns={[
                {
                  Header: "Picture",
                  accessor: "picture",
                  sortable: false,
                  maxWidth: 80,
                },
                {
                  Header: "Name",
                  accessor: "name",
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "Email",
                  accessor: "email",
                  sortable: false,
                },
                {
                  Header: "Profile Status",
                  accessor: "status",
                  sortable: false,
                  filterable: false,
                  maxWidth: 124,
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  minWidth: 60,
                  maxWidth: 60,
                },
              ]}
              pageSize={pageSize}
              defaultPageSize={pageSize}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            <div style={{ marginLeft: "auto" }} className="flex items-center">
              <Button
                className="pagination-btn left btn-fill"
                disabled={!previousPageToken || loading}
                onClick={() => getData(previousPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={!nextPageToken || loading}
                onClick={() => getData(nextPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CorporateListPage;
