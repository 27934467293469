import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import AddLabTechModal from "./AddLabTechModal.jsx";
import GroupSchedule from "./GroupSchedule.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { formatPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import Svg from "components/Svg/Svg.jsx";
import { debounce } from "debounce";
import { parseError } from "api/common.js";

const LabTechs = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(15);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.location.search.length === 0 && data.length > 0 && !loading) {
      getTechnicians(null, true);
    }
  }, [props.location]);

  const getTechnicians = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setError(null);
    setData([]);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/technicians?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        const nextPageToken = res.data.nextPageToken;
        const prevPageToken = res.data.previousPageToken;

        setNextPageToken(nextPageToken);
        setPreviousPageToken(prevPageToken);
        setData(mapItems(res.data.items));

        if (pageToken !== null) {
          if (!prevPageToken) {
            props.history.replace(props.history.location.pathname, null);
          } else {
            props.history.replace(
              `${props.history.location.pathname}?${
                pageToken !== null
                  ? `pageToken=${encodeURIComponent(pageToken)}&backward=${
                      isBackward ? "true" : "false"
                    }`
                  : ""
              }`,
              null
            );
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const mapAvailabilityStatus = (status) => {
    switch (status) {
      case "Available":
        return "Online";
      case "Busy":
        return "Busy";
      default:
        return "Offline";
    }
  };

  const mapRegistrationStatus = (tech) => {
    if (tech.isEnabled) {
      return tech.isPasswordSet ? "Enabled" : "Not Signed Up";
    } else {
      return "Disabled";
    }
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return {
        id: prop.id,
        firstName: prop.firstName,
        lastName: prop.lastName,
        phone: formatPhoneNumber(prop.phone),
        email: prop.email,
        regStatus: mapRegistrationStatus(prop),
        status: (
          <div
            title={
              prop.availability === "Available"
                ? "The technician has started their shift"
                : prop.availability === "Busy"
                ? "The technician is performing an appointment at the moment"
                : "The technician has not started or already ended their shift"
            }
            className={`badge ${
              prop.availability === "Available"
                ? "success"
                : prop.availability === "Busy"
                ? "warning"
                : "error"
            }`}
          >
            {mapAvailabilityStatus(prop.availability)}
          </div>
        ),

        actions: (
          <div className="flex flex-col items-center">
            <Button
              title="View/Edit Details"
              bsStyle="danger"
              outline="true"
              style={{ padding: "7px 10px" }}
              onClick={() => {
                props.history.push(`/admin/lab-techs-details/${prop.id}`);
              }}
            >
              <Svg name="edit" className="w-4 h-4" />
            </Button>
          </div>
        ),
      };
    });
  };

  const onSearch = (filter) => {
    setLoading(true);
    setData([]);
    setError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/technicians/search`, filter)
      .then((res) => {
        setData(mapItems(res.data));
        setLoading(false);
        setNextPageToken(null);
        setPreviousPageToken(null);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const getFilters = (filter) => {
    var dataFilter = {};

    filter.forEach((f) => {
      dataFilter[f.id] = f.value;
    });

    return dataFilter;
  };

  const fetchData = (state) => {
    if (state.filtered.length > 0) {
      let dataFilter = getFilters(state.filtered);
      onSearch(dataFilter);
    } else {
      const searchParams = new URLSearchParams(props.location.search);
      const pageToken = searchParams.get("pageToken");
      const pageBackward = searchParams.get("backward");
      getTechnicians(pageToken, pageBackward === "true");
    }
  };

  const bypassFilter = (filter, row, column) => {
    return true;
  };

  return (
    <div className="main-content flex flex-col gap-24" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center w-full">
            <div className="title">All Technicians / Nurses</div>
            <div style={{ marginLeft: "auto" }}>
              <AddLabTechModal onConfirm={() => getTechnicians()} />
            </div>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <ReactTable
              loading={loading}
              loadingText="Loading accounts..."
              noDataText={error ? error : loading ? "" : "No accounts found..."}
              data={data}
              onFetchData={debounce(fetchData, 800)}
              defaultFilterMethod={bypassFilter}
              columns={[
                {
                  Header: "First Name",
                  accessor: "firstName",
                  sortable: false,
                  filterable: true,
                },
                {
                  Header: "Last Name",
                  accessor: "lastName",
                  sortable: false,
                  filterable: true,
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                  sortable: false,
                  filterable: true,
                  maxWidth: 140,
                },
                {
                  Header: "Email",
                  accessor: "email",
                  minWidth: 160,
                  sortable: false,
                  filterable: true,
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "Status",
                  accessor: "status",
                  sortable: false,
                  filterable: false,
                  maxWidth: 80,
                  minWidth: 80,
                },
                {
                  Header: "Registered",
                  accessor: "regStatus",
                  sortable: false,
                  filterable: false,
                  maxWidth: 112,
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  maxWidth: 60,
                  resizable: false,
                },
              ]}
              defaultPageSize={pageSize}
              showPaginationBottom={false}
              className="-striped -highlight"
            />

            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <Button
                className="pagination-btn left btn-fill"
                disabled={!previousPageToken || loading}
                onClick={() => getTechnicians(previousPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={!nextPageToken || loading}
                onClick={() => getTechnicians(nextPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />

      <Card
        title={
          <div className="flex items-center w-full">
            <div className="title">Working Schedule</div>
          </div>
        }
        content={
          <div className="flex flex-col">
            <GroupSchedule />
          </div>
        }
      />
    </div>
  );
};

export default LabTechs;
