import React, { useState } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Swal from "sweetalert2";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { NavLink } from "react-router-dom";
import { forgotPassword } from "../../api/auth";
import { parseError } from "api/common";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailValid = validateEmail(email);

    if (!emailValid) {
      setEmailError("Email is required and format should be john@doe.com.");
    } else {
      setEmailError("");
    }
  };

  const triggerPasswordTeset = () => {
    let self = this;

    Swal.fire({
      title: "The password reset code will be sent to your email address. Do you want to conitue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#0051d2",
      confirmButtonText: "Send Me Code",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        forgotPassword(email)
          .then((res) => {
            Swal.fire("Password Reset", "Please check your email for password reset email.", "success").then(() => {
              props.history.push(`/auth/reset-password?email=${encodeURIComponent(email)}`);
            });
          })
          .catch((err) => {
            setLoading(false);
            let error = parseError(err);
            Swal.fire("Password Reset Error", error, "error");
          });
      }
    });
  };

  return (
    <div className="flex flex-col" style={{ width: "90%", maxWidth: "400px", margin: "0 auto" }}>
      <Card
        style={{ padding: "16px 0px" }}
        className="w-full"
        title={
          <div className="text-center title" style={{ padding: "0" }}>
            Forgot Password
          </div>
        }
        content={
          <div>
            <FormGroup>
              <ControlLabel>Email address</ControlLabel>
              <FormControl
                placeholder="Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailChange}
                name="email"
                required
              />
              {emailError && (
                <span className="text-danger text-xxs" style={{ marginTop: "6px" }}>
                  {emailError}
                </span>
              )}
            </FormGroup>
          </div>
        }
        legend={
          <div className="flex flex-col items-center">
            <Button
              onClick={() => triggerPasswordTeset()}
              bsStyle="danger"
              loading={loading}
              style={{ width: "70%" }}
              fill
              disabled={!email || emailError || loading}
            >
              Send Password Reset Email
            </Button>
            <div className="forgot">
              <NavLink to="/auth/login" className="nav-link text-semibold" activeClassName="active">
                Back to Login
              </NavLink>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ForgotPassword;
