import React from "react";
import { Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import "react-phone-number-input/style.css";

const MedicalNotesModal = (props) => {
  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Medical Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="flex flex-col gap-32" style={{ padding: "6px 12px" }}>
            <div
              style={{ marginTop: "4px", whiteSpace: "pre-line" }}
              className="questionnaire-notes"
            >
              {props.notes || "--"}
            </div>
          </div>

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MedicalNotesModal;
