import React, { useState, useEffect } from "react";
import axios from "axios";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Modal } from "react-bootstrap";
import Svg from "components/Svg/Svg.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "../../Components/Loader/Loader.jsx";
import { parseError } from "api/common.js";
import "react-phone-number-input/style.css";

const ServiceReportPreviewModal = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [originalType, setOriginalType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [formType, setFormType] = useState("pdf");

  useEffect(() => {
    setFileData(null);
    setOriginalData(null);
    setOriginalType(null);
    setError(null);
    setLoading(true);
    setFormType("pdf");
    setNumPages(null);
    setPageNumber(1);

    if (props.show && props.reportKey) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/${
            props.type === "medical-notes" ? "service-notes/reports" : "service-reports"
          }/${encodeURIComponent(props.reportKey)}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          setLoading(false);

          let fileType = res.headers["content-type"];
          const base64 = btoa(
            new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
          );
          setOriginalType(fileType);
          setOriginalData(res.data);
          setFormType(fileType === "application/pdf" ? "pdf" : "image");
          setFileData(
            (fileType === "application/pdf"
              ? `data:application/pdf;base64,`
              : `data:${fileType};base64,`) + base64
          );
        })
        .catch((err) => {
          let error = parseError(err);
          setLoading(false);
          setError(error);
        });
    }
  }, [props.show, props.reportKey]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const downloadFile = () => {
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([originalData], { type: originalType }));
    link.download =
      props.type === "medical-notes" ? "Medical Notes Report" : `Service Result Report`;
    document.body.appendChild(link);
    link.click();

    setTimeout(function () {
      window.URL.revokeObjectURL(link);
      link.remove();
    }, 200);
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()} dialogClassName="wide">
      <Modal.Header closeButton>
        <Modal.Title>Preview Service Result Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="flex flex-col gap-16">
            {loading && (
              <div className="flex flex-col items-center justify-center">
                <Loader />
                <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
                  Loading Preview...
                </p>
              </div>
            )}

            {error && (
              <div className="error-alert" style={{ padding: "0.6rem", margin: "24px auto" }}>
                {error}
              </div>
            )}

            {formType === "image" && fileData && (
              <img alt="Service Result Report Preview" src={fileData} />
            )}
            {formType === "pdf" && fileData && (
              <div className="pdf-container flex items-center flex-col">
                <Document
                  className="pdf-preview-container"
                  loading="Loading PDF preview..."
                  error="Unable to load PDF preview due to unexpected issue."
                  file={fileData}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <div className="ml-auto mr-auto flex items-center">
                  <Button
                    bsStyle="danger"
                    disabled={pageNumber <= 1}
                    outline="true"
                    onClick={previousPage}
                  >
                    <Svg name="chevron-left" className="w-6 h-6" />
                  </Button>
                  <span className="sub-text" style={{ margin: "0 12px" }}>
                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                  </span>
                  <Button
                    bsStyle="danger"
                    disabled={pageNumber >= numPages}
                    outline="true"
                    onClick={nextPage}
                  >
                    <Svg name="chevron-right" className="w-6 h-6" />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            {originalData && (
              <Button bsStyle="danger" fill onClick={() => downloadFile()}>
                <Svg name="download" className="w-6 h-6" />
                Download Report
              </Button>
            )}
            <Button bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceReportPreviewModal;
