import React, { useState, useRef, useCallback, useEffect } from "react";
import MapGL, { NavigationControl, Layer, Source } from "@urbica/react-map-gl";
import Draw from "@urbica/react-map-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import axios from "axios";
import { getBoundsForPoints } from "../../../api/common.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function ServiceLocationsMap(props) {
  const [viewport, setViewport] = useState({
    latitude: 51.0447,
    longitude: -114.0719,
    zoom: 10,
  });
  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const [allZonesData, setAllZonesData] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: [[]],
          type: "Polygon",
        },
      },
    ],
  });

  useEffect(() => {
    getAllZones();
  }, []);

  const getAllZones = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/zones/detailed`).then((res) => {
      const features = res.data;

      setAllZonesData({
        type: "FeatureCollection",
        features: features
          .filter((s) => (props.zoneId ? s.id !== props.zoneId : true))
          .map((feature) => {
            return {
              type: "Feature",
              properties: {},
              geometry: {
                coordinates: [feature.coordinates],
                type: "Polygon",
              },
            };
          }),
      });

      let points = (features || [])
        .flatMap((s) => s.coordinates)
        .map((coord) => {
          return {
            longitude: coord[0],
            latitude: coord[1],
          };
        });

      try {
        if (points.length !== 0) {
          let centre = getBoundsForPoints(points, props.height || 400);
          setViewport({
            latitude: centre.latitude,
            longitude: centre.longitude,
            zoom: (centre.zoom > 13 ? 13 : centre.zoom) || props.zoom,
            width: "100%",
            height: `${props.height || 400}px`,
          });
        }
      } catch (mErr) {
        console.log(mErr);
      }
    });
  };

  const saveCoordinates = (features) => {
    if (props.onUpdate) {
      props.onUpdate(features);
    }
  };

  return (
    <MapGL
      ref={mapRef}
      {...viewport}
      style={{ width: "100%", height: `${props.height || 600}px` }}
      mapStyle="mapbox://styles/mapbox/light-v10"
      boxSelect={false}
      accessToken={process.env.REACT_APP_MAPBOX_API_KEY}
      onViewportChange={handleViewportChange}
    >
      {props.mode === "edit" && (
        <Draw
          data={{
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  coordinates: props.coordinates || [[]],
                  type: "Polygon",
                },
              },
            ],
          }}
          onChange={(data) => {
            saveCoordinates(data.features || []);
          }}
          lineStringControl={false}
          combineFeaturesControl={false}
          uncombineFeaturesControl={false}
          pointControl={false}
          onDrawCreate={({ features }) => {
            saveCoordinates(features);
          }}
          onDrawUpdate={({ features }) => {
            saveCoordinates(features);
          }}
        />
      )}

      {props.mode === "new" && (
        <Draw
          lineStringControl={false}
          combineFeaturesControl={false}
          uncombineFeaturesControl={false}
          pointControl={false}
          onDrawCreate={({ features }) => {
            saveCoordinates(features);
          }}
          onDrawUpdate={({ features }) => {
            saveCoordinates(features);
          }}
        />
      )}

      <Source id="route" type="geojson" data={allZonesData} />
      <Layer id="route" type="fill" source="route" paint={{ "fill-color": "#0051d2", "fill-opacity": 0.18 }} />

      <NavigationControl style={navControlStyle} />
    </MapGL>
  );
}
