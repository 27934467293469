import React, { useState, useEffect } from "react";
import Card from "components/Card/Card.jsx";
import { Chart } from "react-google-charts";

const AppointmentsChart = ({ data }) => {
  const defaultState = [["Status", "Count"]];
  const [chartData, setChartData] = useState(defaultState);

  useEffect(() => {
    let resultData = [...defaultState];

    resultData.push([`Scheduled (${data.scheduledCount || 0})`, data.scheduledCount || 0]);
    resultData.push([`In Transit (${data.inTransitCount || 0})`, data.inTransitCount || 0]);
    resultData.push([`In Progress (${data.startedCount || 0})`, data.startedCount || 0]);
    resultData.push([`Completed (${data.completedCount || 0})`, data.completedCount || 0]);
    resultData.push([`Cancelled (${data.cancelledCount || 0})`, data.cancelledCount || 0]);

    setChartData(resultData);
  }, [data]);

  return (
    <Card
      title={
        <div className="flex items-start">
          <div className="title">{`Appointments State (Total: ${data.total || 0})`}</div>
        </div>
      }
      content={
        <div>
          <Chart
            width={"100%"}
            height={"320px"}
            chartType="PieChart"
            loader={<div>Loading Appointments Data</div>}
            data={chartData}
            options={{
              colors: ["#004DE5", "#EEA620", "#207c41", "#FF6224", "#DE3C63"],
              pieSliceText: "percentage",
              pieStartAngle: 75,
              pieHole: 0.4,
              legend: {
                alignment: "center",
                position: "bottom",
                textStyle: {
                  fontName: "Inter",
                  bold: true,
                },
              },
              tooltip: { trigger: "none" },
              chartArea: { width: "100%", height: "70%", top: "5%" },
            }}
            rootProps={{ "data-testid": "4" }}
          />
        </div>
      }
    />
  );
};

export default AppointmentsChart;
