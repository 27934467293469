import React, { useState, useEffect } from "react";
import DateItem from "./DateListItemView.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Svg from "components/Svg/Svg";

const DatesListControl = (props) => {
  const [items, setItems] = useState(props.items || []);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const onNewItem = () => {
    let item = {
      date: null,
      text: "",
      localizations: {},
      id: `${items.length}-${Math.floor(Math.random() * 999999)}`,
    };

    if (props.onAdd) {
      props.onAdd(item);
    }
  };

  const onDelete = (item) => {
    if (props.onChange) {
      props.onDelete(item);
    }
  };

  const onChange = (id, item) => {
    if (props.onChange) {
      props.onChange(Object.assign({ id: id }, item));
    }
  };

  return (
    <div className="flex flex-col w-full">
      {items.map((item) => (
        <DateItem
          disabled={props.disabled || false}
          onDelete={() => {
            onDelete(item);
          }}
          onChange={(updItem, id) => onChange(id, updItem)}
          text={item.text}
          id={item.id}
          textLabel={props.textLabel}
          date={item.date}
          key={item.id}
          localizations={item.localizations}
        />
      ))}
      {(!items || items.length === 0) && <div className="sub-text">No dates...</div>}
      <Button
        disabled={props.disabled || false}
        bsStyle="danger"
        fill
        style={{ marginLeft: "auto" }}
        onClick={() => onNewItem()}
        type="button"
      >
        <Svg name="plus" className="w-4 h-4" />
        Add Date
      </Button>
    </div>
  );
};

export default DatesListControl;
