import React, { useState, useEffect } from "react";
import { Modal, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Svg from "../../../components/Svg/Svg";
import { parseError } from "api/common";

const ServiceReportsModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [servicesWithReports, setServicesWithReports] = useState([]);
  const [itemKey, setItemKey] = useState();
  const [patient, setPatient] = useState();
  const [patients, setPatients] = useState([]);
  const [serviceResult, setServiceResult] = useState();
  const [resultOptions] = useState([
    { label: "Negative", value: "negative" },
    { label: "Positive", value: "positive" },
  ]);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    setError(null);
    setLoading(false);
    setItemKey();
    setPatient();
    setServiceResult();

    if (props.show) {
      loadServices();
    }
  }, [props.show]);

  useEffect(() => {
    if (props.appointment) {
      setPatients(
        props.appointment.appointmentPatients.map((p, ind) => {
          return { value: ind, label: `${p.firstName} ${p.lastName} (${p.email})`, data: p };
        })
      );
    } else {
      setPatients([]);
    }
  }, [props.appointment]);

  const loadServices = () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/services/with-report`)
      .then((res) => {
        setServicesWithReports(res.data);
      })
      .catch((err) => {
        parseError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSendReports = () => {
    setError(null);
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/service-reports/${itemKey.value}`, {
        result: serviceResult ? serviceResult.value : "negative",
        notes: notes,
      })
      .then((res) => {
        props.onConfirm(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Appointment Service Results</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Card
            content={
              <div className="flex flex-col gap-6">
                <FormGroup className="flex flex-col">
                  <ControlLabel>Patient:</ControlLabel>
                  <Select
                    name="services-report-select"
                    isClearable={false}
                    isDisabled={loading}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select patient"
                    noOptionsMessage={() => "No patients..."}
                    isMulti={false}
                    value={patient}
                    onChange={(opt) => {
                      setPatient(opt);
                      setItemKey(null);
                      setNotes("");
                      setServiceResult(null);
                    }}
                    options={patients}
                  />
                </FormGroup>

                <FormGroup className="flex flex-col">
                  <ControlLabel>Service:</ControlLabel>
                  <Select
                    name="services-report-select"
                    isClearable={true}
                    isDisabled={loading}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select service"
                    noOptionsMessage={() => "No services that support results..."}
                    isMulti={false}
                    value={itemKey}
                    onChange={(opt) => {
                      setItemKey(opt);
                    }}
                    options={
                      patient
                        ? patient.data.appointmentItems
                            .filter((s) => servicesWithReports.some((r) => r === s.service.id))
                            .map((ai) => {
                              return {
                                value: ai.key,
                                label: ai.service.title,
                                service: ai.service,
                              };
                            })
                        : []
                    }
                  />
                </FormGroup>

                <FormGroup className="flex flex-col">
                  <ControlLabel>Test Result:</ControlLabel>

                  {(!itemKey || itemKey.service.reportId === null) && (
                    <Select
                      name="services-report-select"
                      isClearable={false}
                      isDisabled={loading}
                      className="react-select react-select-icon"
                      isSearchable={false}
                      placeholder="Select test result"
                      noOptionsMessage={() => "No services that support results..."}
                      isMulti={false}
                      value={serviceResult}
                      onChange={(opt) => {
                        setServiceResult(opt);
                      }}
                      options={resultOptions}
                    />
                  )}

                  {(!itemKey || itemKey.service.reportId === null) && (
                    <span className="text-xxs sub-text" style={{ marginTop: "6px" }}>
                      Please set the correct test result for this service. This action cannot be
                      undone and result cannot be changed later. You can only re-send an email with
                      the report after setting the results for this service.
                    </span>
                  )}
                  {itemKey && itemKey.service.reportId !== null && (
                    <span className="text-xxs sub-text" style={{ marginTop: "6px" }}>
                      For existing service reports you can only re-send the existing service results
                      to the customer.
                    </span>
                  )}
                </FormGroup>

                {(!itemKey || itemKey.service.reportId === null) && (
                  <FormGroup>
                    <ControlLabel>Additional Notes (included into report):</ControlLabel>
                    <FormControl
                      disabled={loading}
                      type="textarea"
                      maxLength={500}
                      componentClass="textarea"
                      rows={5}
                      value={notes}
                      onChange={(event) => {
                        setNotes(event.target.value);
                      }}
                    />
                  </FormGroup>
                )}
              </div>
            }
          />

          {error && (
            <div className="error-alert" style={{ padding: "0.6rem", marginTop: "12px" }}>
              {error}
            </div>
          )}

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button
              disabled={loading}
              bsStyle="danger"
              outline="true"
              onClick={() => props.onHide()}
            >
              Close
            </Button>

            <Button
              loading={loading}
              bsStyle="danger"
              fill
              onClick={() => onSendReports()}
              disabled={
                loading || !itemKey || (itemKey.service.reportId === null && !serviceResult)
              }
            >
              <Svg name="send" className="w-4 h-4" />
              Send Service Result
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceReportsModal;
