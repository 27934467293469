import React, { useState, useEffect } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from "react-select";
import axios from "axios";
import { parseError } from "api/common";

const CancelAppointmentModal = (props) => {
  const [notes, setNotes] = useState("");
  const [paymentMode, setPaymentMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([
    {
      label: "Refund all the payments",
      value: "refund",
    },
    {
      label: "Charge full appointment price as cancellation fee",
      value: "fullLiability",
    },
  ]);

  useEffect(() => {
    if (props.show && props.appointmentId && paymentOptions.find((p) => p.value === "regularLiability")) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/appointments/settings`)
        .then((res) => {
          if (res.data.cancellationCharge > 0) {
            setPaymentOptions((list) =>
              list.map((pp) => {
                if (pp.value === "regularLiability") {
                  return {
                    label: `Charge regular cancellation fee ($${new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      signDisplay: "never",
                    }).format(res.data.cancellationCharge > 0 ? res.data.cancellationCharge / 100 : 0)})`,
                    value: pp.value,
                  };
                }
                return pp;
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setNotes("");
    setPaymentMode(paymentOptions.find((p) => p.value === "refund"));
    setError(null);
    setLoading(false);
  }, [props.show, props.appointmentId]);

  const onCancelAppointment = () => {
    setError(null);
    setLoading(true);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/appointments/${props.appointmentId}/cancel-admin`, {
        data: {
          notes: notes,
          paymentMode: paymentMode.value,
        },
      })
      .then((res) => {
        if (props.onConfirm) {
          props.onConfirm(res.data);
        }

        setLoading(false);
        props.onHide();
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Appointment Cancellation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Card
            content={
              <div className="flex flex-col gap-16">
                <FormGroup>
                  <ControlLabel>Payment Behaviour</ControlLabel>
                  <Select
                    name="payment-mode-select"
                    isClearable={false}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Payment Behaviour"
                    isMulti={false}
                    value={paymentMode}
                    onChange={(opt) => {
                      setPaymentMode(opt);
                    }}
                    options={paymentOptions}
                    formatGroupLabel={(data) => <div className="flex items-center text-theme font-semibold">{data.label}</div>}
                  />
                </FormGroup>

                <FormGroup>
                  <ControlLabel>Notes</ControlLabel>
                  <FormControl
                    type="textarea"
                    maxLength={500}
                    componentClass="textarea"
                    rows={5}
                    value={notes}
                    onChange={(event) => {
                      setNotes(event.target.value);
                    }}
                  />
                </FormGroup>

                {error && (
                  <div className="error-alert" style={{ padding: "0.6rem" }}>
                    {error}
                  </div>
                )}
              </div>
            }
          />

          <div className="flex items-center gap-10" style={{ marginLeft: "auto", marginTop: "16px" }}>
            <Button disabled={loading} bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Close
            </Button>

            <Button loading={loading} bsStyle="danger" fill onClick={() => onCancelAppointment()} disabled={loading || !paymentMode}>
              Cancel Appointment
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelAppointmentModal;
