import React, { useState, useEffect, useRef } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Button from "components/CustomButton/CustomButton.jsx";
import Swal from "sweetalert2";
import Switch from "react-switch";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import axios from "axios";
import Svg from "components/Svg/Svg.jsx";
import ReactTable from "react-table";
import moment from "moment";
import PlaceholderImage from "../../../assets/img/placeholder.svg";
import ReactDatetime from "react-datetime";
import { parseError, dateFormat, renderDatePickerInput } from "api/common.js";
import LocalizableTextInput from "../../Components/LocalizableInputs/LocalizableTextInput.jsx";
import BannerPanel from "../../Components/BannerPanel/BannerPanel.jsx";

const CorporateProfilePage = (props) => {
  const imageFileInput = useRef();
  const [loading, setLoading] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [loadError, setLoadError] = useState(null);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [name, setName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDeleted, setIsDeleted] = useState(true);

  const [pictureFile, setPictureFile] = useState(null);
  const [pictureData, setPictureData] = useState(null);
  const [picturePreview, setPicturePreview] = useState(null);
  const [localizations, setLocalizations] = useState({});

  const [qrCodeData, setQrCodeData] = useState(null);
  const [corporateCode, setCorporateCode] = useState("");

  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [profileServices, setProfileServices] = useState([]);

  const [banner, setBanner] = useState(null);

  useEffect(() => {
    let prId = props.match.params.id;
    if (prId !== "new") {
      setProfileId(prId);
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}/corporate/${encodeURIComponent(prId)}`)
        .then((res) => {
          initializeData(res.data);
          loadRelatedData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          setLoadError(error);
          Swal.fire("Profile Error", error, "error");
        });
    } else {
      setLocalizations({});
      loadRelatedData();
      setIsDeleted(false);
      setIsDisabled(false);
    }
  }, [props.location]);

  useEffect(() => {
    return function cleanup() {
      if (picturePreview) {
        URL.revokeObjectURL(picturePreview);
      }
    };
  }, [picturePreview]);

  const loadRelatedData = (profileData = null) => {
    if (profileData != null) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/corporate/${profileData.id}/code`)
        .then((res) => {
          setQrCodeData(res.data.qrCodeData);
          setCorporateCode(res.data.id);
        })
        .catch((err) => {
          parseError(err);
        });
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/services`)
      .then((res) => {
        const servicesList = res.data.map((svc) => ({
          value: svc.id,
          label: svc.title,
        }));
        setServiceOptions(servicesList);

        if (profileData !== null && profileData.services) {
          let currentServices = profileData.services.map((pSvc) =>
            servicesList.find((s) => s.value === pSvc.serviceId)
          );
          setSelectedServices(currentServices);

          let tableServices = profileData.services
            .filter((s) => servicesList.some((svc) => svc.value === s.serviceId))
            .map((pSvc) => {
              let fromDate =
                pSvc.availableFrom !== null
                  ? moment(
                      `${pSvc.availableFrom.month}/${pSvc.availableFrom.day}/${pSvc.availableFrom.year}`,
                      "M/D/YYYY"
                    ).toDate()
                  : null;
              let toDate =
                pSvc.availableTo !== null
                  ? moment(
                      `${pSvc.availableTo.month}/${pSvc.availableTo.day}/${pSvc.availableTo.year}`,
                      "M/D/YYYY"
                    ).toDate()
                  : null;

              return {
                serviceId: pSvc.serviceId,
                serviceName: servicesList.find((s) => s.value === pSvc.serviceId).label,
                familyMembersAllowed: pSvc.familyMembersAllowed,
                basePrice: pSvc.basePrice !== null ? pSvc.basePrice / 100.0 : 0,
                useCustomPrice: pSvc.basePrice !== null,
                availableFrom: fromDate,
                availableTo: toDate,
              };
            });

          setProfileServices(tableServices);
        }
        setLoading(false);
      })
      .catch((err) => {
        parseError(err);
        setLoading(false);
      });
  };

  const handleEmailChange = (event) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(event.target.value) === true;
    if (!isValid && event.target.value) {
      setEmailError(
        <small className="text-danger">
          Invalid email address format. Please make sure that you are using valid email address.
        </small>
      );
    } else {
      setEmailError(null);
    }
  };

  const initializeData = (profileData) => {
    setName(profileData.name);
    setEmail(profileData.email);
    setPictureData(profileData.picture);
    setLocalizations(profileData.localizations || {});
    setIsDisabled(profileData.status === "disabled");
    setIsDeleted(profileData.deletedAt > 0);
    setBanner(profileData.banner);
  };

  const onImageFileUpload = () => {
    imageFileInput.current.click();
  };

  const onImageFileChange = (e) => {
    setPictureFile(e.target.files[0]);
    try {
      setPicturePreview(URL.createObjectURL(e.target.files[0]));
    } catch (err) {
      console.error(err);
      setPicturePreview(null);
    }
  };

  const saveProfile = async (e) => {
    const isUpdate = profileId !== null;
    setLoading(true);

    let uploadCompleted = false;
    let imageId = pictureData ? pictureData.id : null;

    try {
      if (pictureFile) {
        const file = pictureFile;
        let formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("type", "profilePicture");

        let fileRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/file/upload/image`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        imageId = fileRes.data.id;
      }

      uploadCompleted = true;
    } catch (err) {
      setLoading(false);
      let error = parseError(err);
      Swal.fire("Image Upload Error", error, "error");
    }

    if (!uploadCompleted) {
      setLoading(false);
      return;
    }

    const profileData = {
      id: profileId !== null ? profileId : null,
      name: name,
      email: email,
      localizations: Object.assign({}, localizations),
      picture: {
        fileName: imageId || null,
      },
      banner: banner ? banner : null,
      services: profileServices.map((s) => {
        return {
          serviceId: s.serviceId,
          basePrice: s.useCustomPrice ? parseInt(s.basePrice * 100) : null,
          familyMembersAllowed: s.familyMembersAllowed,
          availableFrom:
            s.availableFrom != null
              ? {
                  year: s.availableFrom.getFullYear(),
                  month: s.availableFrom.getMonth() + 1,
                  day: s.availableFrom.getDate(),
                }
              : null,
          availableTo:
            s.availableTo != null
              ? {
                  year: s.availableTo.getFullYear(),
                  month: s.availableTo.getMonth() + 1,
                  day: s.availableTo.getDate(),
                }
              : null,
        };
      }),
    };

    const requestOptions = {
      method: isUpdate ? "PUT" : "POST",
      url: `${process.env.REACT_APP_API_URL}/corporate`,
      data: profileData,
    };

    axios(requestOptions)
      .then((res) => {
        if (isUpdate) {
          initializeData(res.data);
        }
        setLoading(false);
        Swal.fire({
          title: `Corporate Profile ${isUpdate ? "Updated" : "Created"}`,
          text: `The corporate profile '${profileData.name}' has been ${
            isUpdate ? "updated" : "created"
          } successfully.`,
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          if (!isUpdate) {
            props.history.push("/admin/corporate");
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Corporate Profile Error", error, "error");
      });
  };

  const limitPrice = ({ floatValue }) => {
    return floatValue == undefined || floatValue < 999999.99;
  };

  const onSelectedServicesChanged = (servicesList) => {
    setSelectedServices(servicesList);
    setProfileServices((prev) =>
      servicesList.map((svc) => {
        if (prev.some((s) => s.serviceId === svc.value)) {
          return prev.find((s) => s.serviceId === svc.value);
        }

        return {
          serviceName: svc.label,
          serviceId: svc.value,
          basePrice: 0,
          familyMembersAllowed: false,
          availableFrom: null,
          availableTo: null,
          useCustomPrice: false,
        };
      })
    );
  };

  const deleteAccount = async () => {
    Swal.fire({
      title:
        "Are you sure you want to delete this corporate profile? All the connected customers will be unlinked from this corporate profile after the deletion is completed.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Profile",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .delete(`${process.env.REACT_APP_API_URL}/corporate/${profileId}`)
          .then((res) => {
            setLoading(false);
            setIsDeleted(true);

            Swal.fire({
              title: "The corporate profile has been deleted.",
              icon: "success",
              showCancelButton: false,
            }).then((s) => {
              props.history.push("/admin/corporate");
            });
          })
          .catch((err) => {
            let error = parseError(err);
            setLoading(false);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  const disableAccount = async () => {
    Swal.fire({
      title: "Are you sure you want to disable this corporate profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Disable Profile",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .patch(`${process.env.REACT_APP_API_URL}/corporate/${profileId}/disable`)
          .then((res) => {
            setLoading(false);
            setIsDisabled(true);

            Swal.fire({
              title: "The corporate profile has been disabled.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            setLoading(false);
            Swal.fire("Update Error", error, "error");
          });
      }
    });
  };

  const enableAccount = async () => {
    Swal.fire({
      title: "Are you sure you want to enable this corporate profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Enable Profile",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .patch(`${process.env.REACT_APP_API_URL}/corporate/${profileId}/enable`)
          .then((res) => {
            setLoading(false);
            setIsDisabled(false);

            Swal.fire({
              title: "The corporate profile has been enabled.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            setLoading(false);
            Swal.fire("Update Error", error, "error");
          });
      }
    });
  };

  return (
    <div className="main-content">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "12px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Go Back</span>
      </Button>

      <Card
        content={
          <div className="flex flex-col gap-24">
            {isDeleted && !loading && profileId !== null && !loadError && (
              <div className="error-alert" style={{ padding: "6px 16px" }}>
                This corporate profile has been deleted.
              </div>
            )}

            <div className="flex items-start gap-24">
              <div className="flex flex-col gap-16">
                <FormGroup style={{ flexShrink: "0", marginBottom: "0" }}>
                  <ControlLabel>
                    Profile Image: <span className="star">*</span>
                  </ControlLabel>
                  <div
                    className="flex flex-col gap-12 items-center"
                    style={{ width: "200px", flexShrink: "0", marginBottom: "0" }}
                  >
                    <img
                      src={
                        picturePreview
                          ? picturePreview
                          : pictureData
                          ? pictureData.url
                          : PlaceholderImage
                      }
                      alt="Service Picture"
                      style={{
                        width: "100%",
                        aspectRatio: "1 / 1",
                        overflow: "hidden",
                        borderRadius: "6px",
                        objectFit: "cover",
                        border: "1px solid var(--border-color)",
                      }}
                    />
                    <Button
                      style={{ width: "100%" }}
                      disabled={loading || isDeleted}
                      bsStyle="danger"
                      fill
                      onClick={onImageFileUpload}
                    >
                      <Svg name="upload" className="w-4 h-4" />
                      Upload Image
                    </Button>

                    <span
                      className="sub-text text-xxs text-center"
                      style={{
                        width: "144px",
                      }}
                    >
                      Only JPEG/PNG images are allowed.
                    </span>

                    <input
                      accept="image/png,image/jpeg"
                      ref={imageFileInput}
                      id="file-upload"
                      style={{ display: "none" }}
                      type="file"
                      onChange={onImageFileChange}
                    />
                  </div>
                </FormGroup>

                <div className="separator horizontal"></div>

                <div
                  className="flex flex-col gap-16"
                  style={{ width: "200px", flexShrink: "0", marginBottom: "0" }}
                >
                  <FormGroup style={{ flexShrink: "0", marginBottom: "0" }}>
                    <ControlLabel>Profile Code:</ControlLabel>
                    <div className="flex flex-col gap-12">
                      <img
                        src={qrCodeData || PlaceholderImage}
                        alt="Profile QR Code"
                        style={{
                          width: "100%",
                          overflow: "hidden",
                          borderRadius: "6px",
                          objectFit: qrCodeData ? "contain" : "cover",
                          aspectRatio: "1 / 1.2941",
                          border: "1px solid var(--border-color)",
                        }}
                      />

                      {profileId !== null && qrCodeData && (
                        <React.Fragment>
                          <div
                            style={{
                              border: "2px dashed var(--theme-dark-text)",
                              borderRadius: "7px",
                              background: "var(--theme-hover-color)",
                              padding: "6px 12px",
                              textAlign: "center",
                              fontWeight: "700",
                              fontSize: "16px",
                              color: "var(--theme-dark-text)",
                            }}
                            className="flex items-center flex-col"
                          >
                            {corporateCode}
                          </div>
                          <a
                            download="Corporate Code.jpg"
                            href={qrCodeData}
                            disabled={!qrCodeData}
                            className="btn btn-fill btn-danger flex flex-col items-center"
                          >
                            <div className="flex items-center gap-6">
                              <Svg name="download" className="w-4 h-4" />
                              Download Image
                            </div>
                          </a>
                        </React.Fragment>
                      )}

                      <span className="sub-text text-xxs text-center">
                        {profileId !== null
                          ? "The customers can use either barcode or the printed code to create their corporate profile through the mobile apps or on the web site."
                          : "The QR code for corporate profile will be automatically generated and available only after the profile is created."}
                      </span>
                    </div>
                  </FormGroup>
                </div>
              </div>

              <div className="separator vertical"></div>

              <div className="flex flex-col gap-16" style={{ width: "100%", marginBottom: "0" }}>
                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>
                    Profile Name: <span className="star">*</span>
                  </ControlLabel>
                  <LocalizableTextInput
                    disabled={loading || isDeleted}
                    placeholder="Enter name for this profile..."
                    value={name}
                    maxLength={100}
                    onChange={(text) => {
                      setName(text);
                    }}
                    onLocalizationChange={(translation) => {
                      setLocalizations((prev) => {
                        let newObj = Object.assign({}, prev);
                        Object.assign(newObj, translation);
                        return newObj;
                      });
                    }}
                    fieldName="Name"
                    localizations={localizations}
                  />
                </FormGroup>

                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>
                    Email Address: <span className="star">*</span>
                  </ControlLabel>
                  <FormControl
                    disabled={isDeleted || loading}
                    placeholder="Enter email address for this profile..."
                    maxLength="100"
                    type="text"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={(event) => handleEmailChange(event)}
                    required
                  />
                  {emailError}
                </FormGroup>

                <div className="flex flex-col gap-10">
                  <FormGroup className="flex flex-col">
                    <ControlLabel>Available Services:</ControlLabel>
                    <Select
                      name="services-tech-select"
                      isClearable={true}
                      isDisabled={loading || isDeleted}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Services"
                      noOptionsMessage={() => "No Services"}
                      isMulti={true}
                      value={selectedServices}
                      onChange={(opt) => {
                        onSelectedServicesChanged(opt);
                      }}
                      options={serviceOptions}
                    />
                    <span
                      className="text-xxs sub-text"
                      style={{ marginTop: "6px", marginLeft: "4px" }}
                    >
                      The list of available services for this corporate profile. These services will
                      be available to book for any customer linked with this corporate profile.
                    </span>
                  </FormGroup>

                  <div className="separator horizontal"></div>

                  <ReactTable
                    loading={loading}
                    noDataText={loading ? "Loading services..." : "No services selected..."}
                    sortable={false}
                    filterable={false}
                    data={profileServices.map((item) => {
                      return {
                        id: item.serviceId,
                        serviceName: item.serviceName,
                        basePrice: (
                          <FormGroup
                            className="flex items-center gap-6"
                            style={{ marginBottom: 0 }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginTop: "0px" }}
                              checked={item.useCustomPrice}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setProfileServices((prev) =>
                                  prev.map((s) => {
                                    if (s.serviceId === item.serviceId) {
                                      return Object.assign(s, {
                                        useCustomPrice: isChecked,
                                        basePrice: isChecked ? s.basePrice : 0,
                                      });
                                    }

                                    return s;
                                  })
                                );
                              }}
                            />
                            <NumberFormat
                              value={item.basePrice}
                              defaultValue={item.basePrice}
                              decimalScale={2}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              prefix="$"
                              disabled={isDeleted || !item.useCustomPrice}
                              className="form-control"
                              inputMode="numeric"
                              isAllowed={limitPrice}
                              onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                let newPrice = value ? parseFloat(value) : 0;

                                setProfileServices((prev) =>
                                  prev.map((s) => {
                                    if (s.serviceId === item.serviceId) {
                                      return Object.assign(s, {
                                        basePrice: newPrice,
                                      });
                                    }

                                    return s;
                                  })
                                );
                              }}
                            />
                          </FormGroup>
                        ),
                        familyMembers: (
                          <div className="flex flex-col" style={{ paddingLeft: "12px" }}>
                            <Switch
                              disabled={loading || isDeleted}
                              onChange={(checked) => {
                                setProfileServices((prev) =>
                                  prev.map((s) => {
                                    if (s.serviceId === item.serviceId) {
                                      return Object.assign(s, {
                                        familyMembersAllowed: checked,
                                      });
                                    }

                                    return s;
                                  })
                                );
                              }}
                              checked={item.familyMembersAllowed}
                              offColor="#E6E6E6"
                              onColor="#e0eafa"
                              onHandleColor="#0051d2"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={20}
                              width={40}
                            />
                          </div>
                        ),
                        availableFrom: (
                          <ReactDatetime
                            dateFormat={dateFormat}
                            value={item.availableFrom}
                            closeOnSelect={true}
                            renderInput={renderDatePickerInput}
                            inputProps={{
                              disabled: loading,
                              placeholder: "Available From",
                            }}
                            timeFormat={false}
                            isValidDate={(current) => {
                              return item.availableTo
                                ? current.isSameOrBefore(item.availableTo, "day")
                                : current.isSameOrAfter(moment(), "day");
                            }}
                            onChange={(e) => {
                              let fromDate = e ? moment(e).toDate() : null;

                              setProfileServices((prev) =>
                                prev.map((s) => {
                                  if (s.serviceId === item.serviceId) {
                                    return Object.assign(s, {
                                      availableFrom: fromDate,
                                    });
                                  }

                                  return s;
                                })
                              );
                            }}
                          />
                        ),
                        availableTo: (
                          <ReactDatetime
                            dateFormat={dateFormat}
                            value={item.availableTo}
                            closeOnSelect={true}
                            renderInput={renderDatePickerInput}
                            inputProps={{
                              disabled: loading,
                              placeholder: "Available To",
                            }}
                            timeFormat={false}
                            isValidDate={(current) => {
                              return item.availableFrom
                                ? current.isSameOrAfter(item.availableFrom, "day")
                                : current.isSameOrAfter(moment(), "day");
                            }}
                            onChange={(e) => {
                              let toDate = e ? moment(e).toDate() : null;

                              setProfileServices((prev) =>
                                prev.map((s) => {
                                  if (s.serviceId === item.serviceId) {
                                    return Object.assign(s, {
                                      availableTo: toDate,
                                    });
                                  }

                                  return s;
                                })
                              );
                            }}
                          />
                        ),
                      };
                    })}
                    columns={[
                      {
                        Header: "Service Name",
                        accessor: "serviceName",
                        style: { whiteSpace: "unset" },
                      },
                      {
                        Header: "Custom Price",
                        accessor: "basePrice",
                        style: { whiteSpace: "unset" },
                      },
                      {
                        Header: "Available From",
                        accessor: "availableFrom",
                        style: { whiteSpace: "unset" },
                      },
                      {
                        Header: "Available To",
                        accessor: "availableTo",
                        style: { whiteSpace: "unset" },
                      },
                      {
                        Header: "Family Members",
                        accessor: "familyMembers",
                        maxWidth: 132,
                        style: { whiteSpace: "unset" },
                      },
                    ]}
                    pageSize={profileServices.length > 0 ? profileServices.length : 4}
                    defaultPageSize={profileServices.length > 0 ? profileServices.length : 4}
                    showPaginationBottom={false}
                    className="-striped -highlight corporate-services-table"
                  />

                  <div className="block-group flex flex-col gap-16">
                    <span className="text-xxs sub-text">
                      If <strong>Family Members</strong> option is enabled, then the customers will
                      be able to book appointment with this service not only for themselves, but
                      also for their family members.
                      <br />
                      The availability dates indicate a period of time when this service can be
                      booked by the customers linked with this corporate profile. The availability
                      dates are optional, the service will be permanently available for the
                      corporate profile if dates are not specified.
                      <br />
                      Please note that the <strong>end date</strong> for the service availability is{" "}
                      <strong>not inclusive</strong>. I.e. service will be abailable until this date
                      (e.g. if a service is available until 30th of June, it means that it becomes
                      unavailable for customer to book on 30th of June at 12:00 AM). The
                      availability <strong>start date</strong> is the date when service becomes
                      available, e.g. if a service is available from 30th of June then it means that
                      it will become available for bookings on 30th of June at 12:00 AM.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="separator horizontal"></div>

            <BannerPanel
              banner={banner}
              onChange={(uBanner) => {
                setBanner(uBanner);
              }}
            />

            <div className="flex ml-auto gap-10">
              {!isDeleted && profileId !== null && !loadError && (
                <Button
                  disabled={loading || isDeleted}
                  className="btn-error"
                  outline="true"
                  onClick={() => {
                    deleteAccount();
                  }}
                >
                  <Svg name="trash" className="w-4 h-4" />
                  Delete Profile
                </Button>
              )}

              {!isDeleted && profileId !== null && !loadError && (
                <Button
                  disabled={loading}
                  outline="true"
                  className="btn-error"
                  onClick={() => {
                    if (isDisabled) {
                      enableAccount();
                    } else {
                      disableAccount();
                    }
                  }}
                >
                  <Svg name="user-remove" className="w-4 h-4" />
                  {isDisabled ? "Enable Profile" : "Disable Profile"}
                </Button>
              )}

              <Button
                loading={loading}
                disabled={
                  isDeleted ||
                  loadError !== null ||
                  loading ||
                  !name ||
                  !email ||
                  emailError != null ||
                  (!pictureData && !pictureFile)
                }
                onClick={() => saveProfile()}
                style={{ marginLeft: "auto" }}
                bsStyle="danger"
                fill
              >
                Save Profile
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CorporateProfilePage;
