import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import ReactDatetime from "react-datetime";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Select from "react-select";
import Svg from "components/Svg/Svg.jsx";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import { parseError, getAppointmentStatusName, renderDatePickerInput } from "api/common.js";
import { debounce } from "debounce";
import { dateTimeFormat } from "api/common";
import { dateFormat } from "api/common";
import { formatShortAddress } from "api/common";

const AppointmentsTable = (props) => {
  let history = useHistory();
  let location = useLocation();
  let locationSearchParams = new URLSearchParams(location.search);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(locationSearchParams.get("pageToken"));
  const [pageSize] = useState(props.pageSize || 12);
  const [error, setError] = useState(null);
  const [searchCode, setSearchCode] = useState(locationSearchParams.get("code") || "");
  const [fromDate, setFromDate] = useState(
    locationSearchParams.get("from") ? moment(locationSearchParams.get("from")).toDate() : null
  );
  const [endDate, setEndDate] = useState(
    locationSearchParams.get("to") ? moment(locationSearchParams.get("to")).toDate() : null
  );
  const [initialized, setInitialized] = useState(false);
  const [firstName, setFirstname] = useState(locationSearchParams.get("firstName") || "");
  const [lastName, setLastname] = useState(locationSearchParams.get("lastName") || "");

  const defaultStatuses = [
    {
      label: getAppointmentStatusName("technician_assigned").replace("Technician", ""),
      value: "technician_assigned",
    },
    {
      label: getAppointmentStatusName("technician_in_transit").replace("Technician", ""),
      value: "technician_in_transit",
    },
    {
      label: getAppointmentStatusName("technician_at_location").replace("Technician", ""),
      value: "technician_at_location",
    },
    {
      label: getAppointmentStatusName("started"),
      value: "started",
    },
    {
      label: getAppointmentStatusName("ended"),
      value: "ended",
    },
    {
      label: getAppointmentStatusName("completed"),
      value: "completed",
    },
    {
      label: getAppointmentStatusName("cancelled"),
      value: "cancelled",
    },
    {
      label: getAppointmentStatusName("pending"),
      value: "pending",
    },
  ];

  const [selectedStatuses, setSelectedStatuses] = useState(
    locationSearchParams.get("status")
      ? locationSearchParams
          .get("status")
          .split(",")
          .map((s) => defaultStatuses.find((ss) => ss.value === s))
          .filter((s) => s !== undefined)
      : defaultStatuses
  );
  const [statusOptions] = useState(defaultStatuses);

  useEffect(() => {
    if (initialized && !searchCode) {
      getAppointments();
    }
  }, [selectedStatuses]);

  useEffect(() => {
    if (location.search.length === 0 && initialized) {
      if (selectedStatuses.length > 0) {
        setSelectedStatuses([]);
      } else {
        getAppointments();
      }
    }
  }, [location]);

  const getAppointments = (pageToken = null, isBackward = null, savedFilters = null) => {
    setLoading(true);
    setError(null);
    setData([]);

    let statuses = selectedStatuses.length > 0 ? selectedStatuses : defaultStatuses;
    const filters = {
      patientId: props.patientId ? props.patientId : null,
      technicianId: props.techId ? props.techId : null,
      code: searchCode ? searchCode : null,
      firstName: firstName ? firstName : null,
      lastName: lastName ? lastName : null,
      from: !fromDate
        ? null
        : {
            year: fromDate.getFullYear(),
            month: fromDate.getMonth() + 1,
            day: fromDate.getDate(),
          },
      to: !endDate
        ? null
        : {
            year: endDate.getFullYear(),
            month: endDate.getMonth() + 1,
            day: endDate.getDate(),
          },
      status: statuses.map((status) => status.value),
      pageSize: pageSize,
      paginationToken: pageToken || null,
      isBackward: isBackward || false,
    };

    if (savedFilters !== null) {
      Object.assign(filters, savedFilters);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/Appointments/Search`, filters)
      .then((res) => {
        const nextPageToken = res.data.nextPageToken;
        const prevPageToken = res.data.previousPageToken;

        setNextPageToken(nextPageToken);
        setPreviousPageToken(prevPageToken);
        setData(mapItems(res.data.items));
        setLoading(false);
        setInitialized(true);

        if (!props.patientId && !props.techId) {
          let hasFilters =
            filters.firstName ||
            filters.lastName ||
            filters.code ||
            filters.from ||
            filters.to ||
            filters.status ||
            filters.paginationToken;

          if (hasFilters) {
            var filterParams = new URLSearchParams();

            if (filters.firstName) {
              filterParams.set("firstName", filters.firstName);
            }

            if (filters.lastName) {
              filterParams.set("lastName", filters.lastName);
            }

            if (filters.code) {
              filterParams.set("code", filters.code);
            }

            if (filters.status) {
              filterParams.set("status", filters.status.join(","));
            }

            if (filters.from) {
              filterParams.set(
                "from",
                `${filters.from.year}-${filters.from.month}-${filters.from.day}`
              );
            }

            if (filters.to) {
              filterParams.set("to", `${filters.to.year}-${filters.to.month}-${filters.to.day}`);
            }

            if (filters.paginationToken) {
              filterParams.set("pageToken", filters.paginationToken);
            }

            if (filters.isBackward) {
              filterParams.set("backward", (filters.isBackward || false).toString());
            }

            history.replace(`${history.location.pathname}?${filterParams.toString()}`, null);
          }
        }
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const fetchData = () => {
    const searchParams = new URLSearchParams(location.search);
    const pageToken = searchParams.get("pageToken");
    const pageBackward = searchParams.get("backward");
    const fromDate = searchParams.get("from") ? moment(searchParams.get("from")).toDate() : null;
    const toDate = searchParams.get("to") ? moment(searchParams.get("to")).toDate() : null;

    let additionalFilters = {
      firstName: searchParams.get("firstName"),
      lastName: searchParams.get("lastName"),
      code: searchParams.get("code"),
      status: searchParams.get("status") ? searchParams.get("status").split(",") : null,
      from: fromDate
        ? { year: fromDate.getFullYear(), month: fromDate.getMonth() + 1, day: fromDate.getDate() }
        : null,
      to: toDate
        ? { year: toDate.getFullYear(), month: toDate.getMonth() + 1, day: toDate.getDate() }
        : null,
    };

    getAppointments(
      pageToken,
      pageBackward === "true",
      Object.values(additionalFilters).some((f) => f !== null) ? additionalFilters : null
    );
  };

  const mapItems = (items) => {
    if (props.techId) {
      return items
        .filter((t) => t.technician && t.technician.id === props.techId)
        .map((prop) => {
          return mapAppointment(prop);
        });
    }

    if (props.patientId) {
      return items
        .filter((t) => t.patient.id === props.patientId)
        .map((prop) => {
          return mapAppointment(prop);
        });
    }

    return items.map((prop) => {
      return mapAppointment(prop);
    });
  };

  const mapAppointment = (prop) => {
    return {
      id: prop.id,
      status: formatStatus(prop.status),
      type: formatType(prop.type),
      startTime: formatDate(prop.startTime),
      phone: prop.phone,
      cost: prop.cost,
      bookedAt: prop.bookedAt,
      patient: prop.patient,
      technician: prop.technician,
      patient: formatPatient(prop.patient),
      technician: formatTechnician(prop.technician),
      province: prop.address.province,
      address: formatShortAddress(prop.address),
      services: formatServices(prop),
      actions: (
        <div className="flex items-center gap-6">
          <Button
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px" }}
            title="View Appointment Details"
            onClick={() => {
              history.push(`/admin/appointments/${prop.id}`);
            }}
          >
            <Svg name="eye" className="w-4 h-4" />
          </Button>
        </div>
      ),
    };
  };

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--";
    }
    return moment(
      `${inDate.day}/${inDate.month}/${inDate.year} ${inDate.hour}:${inDate.minute}`,
      "D/M/YYYY H:mm"
    ).format(dateTimeFormat);
  };

  const formatStatus = (status) => {
    let badgeColor = "primary";
    switch (status) {
      case "technician_assigned":
      case "technician_in_transit":
      case "technician_at_location":
      case "pending":
        badgeColor = "info";
        break;
      case "started":
      case "ended":
        badgeColor = "primary";
        break;
      case "completed":
        badgeColor = "success";
        break;
      case "cancelled":
        badgeColor = "warning";
        break;
    }

    return <div className={`badge ${badgeColor}`}>{getAppointmentStatusName(status)}</div>;
  };

  const formatType = (type) => {
    return type === "asap" ? "ASAP" : "Scheduled";
  };

  const formatPatient = (patient) => {
    return (
      <Link to={`/admin/users/${encodeURIComponent(patient.id)}`} title="View Account">
        {patient.firstName} {patient.lastName}
      </Link>
    );
  };

  const formatTechnician = (tech) => {
    if (!tech) {
      return "--";
    }

    return (
      <Link to={`/admin/lab-techs-details/${encodeURIComponent(tech.id)}`} title="View Account">
        {tech.firstName} {tech.lastName}
      </Link>
    );
  };

  const bypassFilter = (filter, row, column) => {
    return true;
  };

  const isValidFromDate = (current) => {
    return endDate ? current.isSameOrBefore(endDate) : true;
  };

  const isValidEndDate = (current) => {
    return fromDate ? current.isSameOrAfter(fromDate) : true;
  };

  const formatServices = (app) => {
    const services = app.appointmentPatients.flatMap((p) =>
      p.appointmentItems.map((ai) => ai.service)
    );

    let uniqueServices = [...new Map(services.map((item) => [item["id"], item])).values()];

    return (
      <div>
        {uniqueServices.slice(0, 1).map((s) => (
          <React.Fragment key={s.id}>
            <Link to={`/admin/services/${encodeURIComponent(s.id)}`} title="View Service">
              {s.title}
            </Link>
          </React.Fragment>
        ))}
        {uniqueServices.length > 1 && <span> and {uniqueServices.length - 1} more</span>}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-16">
      <Card
        content={
          <div className="flex flex-col gap-10">
            <div className="flex items-end gap-16">
              <div className="flex flex-col gap-4" style={{ flexBasis: "0", flex: "1 1 0px" }}>
                <span className="text-xxs text-bold">Appointment Code</span>
                <FormControl
                  disabled={loading}
                  type="text"
                  maxLength="32"
                  name="searchCode"
                  value={searchCode}
                  placeholder="Appointment Code"
                  onChange={(event) => {
                    setSearchCode(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-4" style={{ flexBasis: "0", flex: "1 1 0px" }}>
                <span className="text-xxs text-bold">
                  {props.techId ? "Patient's " : props.patientId ? "Technician's " : "Patient's "}
                  Firstname
                </span>
                <FormControl
                  disabled={loading}
                  style={{ width: "100%" }}
                  type="text"
                  maxLength="32"
                  name="firstName"
                  value={firstName}
                  placeholder="Firstname"
                  onChange={(event) => {
                    setFirstname(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-4" style={{ flexBasis: "0", flex: "1 1 0px" }}>
                <span className="text-xxs text-bold">
                  {props.techId ? "Patient's " : props.patientId ? "Technician's " : "Patient's "}
                  Lastname
                </span>
                <FormControl
                  disabled={loading}
                  style={{ width: "100%" }}
                  type="text"
                  maxLength="32"
                  name="lastName"
                  value={lastName}
                  placeholder="Lastname"
                  onChange={(event) => {
                    setLastname(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-4" style={{ flexBasis: "0", flex: "1 1 0px" }}>
                <span className="text-xxs text-bold">From Date</span>
                <ReactDatetime
                  dateFormat={dateFormat}
                  value={fromDate}
                  closeOnSelect={true}
                  renderInput={renderDatePickerInput}
                  inputProps={{
                    disabled: loading,
                    placeholder: "From Date",
                  }}
                  timeFormat={false}
                  isValidDate={isValidFromDate}
                  onChange={(e) => setFromDate(e ? moment(e).toDate() : null)}
                />
              </div>
              <div className="flex flex-col gap-4" style={{ flexBasis: "0", flex: "1 1 0px" }}>
                <span className="text-xxs text-bold">To Date</span>
                <ReactDatetime
                  dateFormat={dateFormat}
                  value={endDate}
                  closeOnSelect={true}
                  renderInput={renderDatePickerInput}
                  inputProps={{
                    disabled: loading,
                    placeholder: "To Date",
                  }}
                  timeFormat={false}
                  isValidDate={isValidEndDate}
                  onChange={(e) => setEndDate(e ? moment(e).toDate() : null)}
                />
              </div>
              <Button
                disabled={loading}
                loading={loading}
                style={{ height: "38px", marginLeft: "-6px" }}
                bsStyle="danger"
                fill
                onClick={() => getAppointments()}
              >
                <Svg name="search" className="w-4 h-4" />
                Search
              </Button>
            </div>

            <div className="w-full">
              <Select
                name="status-select"
                isClearable={true}
                className="react-select react-select-icon"
                isSearchable={false}
                placeholder="Select Statuses"
                isMulti={true}
                value={selectedStatuses}
                onChange={(opt) => {
                  setSelectedStatuses(opt);
                }}
                isDisabled={loading}
                options={statusOptions}
                formatGroupLabel={(data) => (
                  <div className="flex items-center text-theme font-semibold">{data.label}</div>
                )}
              />
            </div>
          </div>
        }
      />
      <ReactTable
        loading={loading}
        loadingText="Loading appointments..."
        noDataText={error ? error : loading ? "" : "No appointments found..."}
        data={data}
        onFetchData={debounce(fetchData, 800)}
        defaultFilterMethod={bypassFilter}
        columns={[
          {
            Header: "Code",
            accessor: "id",
            sortable: false,
            maxWidth: 115,
            minWidth: 115,
          },
          {
            Header: "Patient",
            accessor: "patient",
            sortable: false,
          },
          {
            Header: "Technician",
            accessor: "technician",
            sortable: false,
          },
          {
            Header: "Services",
            accessor: "services",
            sortable: false,
            style: { whiteSpace: "normal" },
          },
          {
            Header: "Address",
            accessor: "address",
            sortable: false,
            style: { whiteSpace: "normal" },
          },
          {
            Header: "Province",
            accessor: "province",
            sortable: false,
            maxWidth: 80,
            style: { whiteSpace: "normal" },
          },
          {
            Header: "Date & Time",
            accessor: "startTime",
            sortable: false,
            maxWidth: 160,
            style: { whiteSpace: "normal" },
          },
          {
            Header: "Status",
            accessor: "status",
            sortable: false,
            maxWidth: 160,
            style: { textOverflow: "clip" },
          },
          {
            Header: "Type",
            accessor: "type",
            sortable: false,
            maxWidth: 80,
          },
          {
            Header: "",
            accessor: "actions",
            sortable: false,
            minWidth: 60,
            maxWidth: 60,
          },
        ]}
        defaultPageSize={pageSize}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
      <div className="flex items-center" style={{ marginLeft: "auto" }}>
        <Button
          className="pagination-btn left btn-fill"
          disabled={!previousPageToken || loading}
          onClick={() => getAppointments(previousPageToken, true)}
          bsStyle="default"
          fill
        >
          <Svg name="chevron-left" className="w-6 h-6" />
        </Button>
        <Button
          className="pagination-btn right btn-fill"
          disabled={!nextPageToken || loading}
          onClick={() => getAppointments(nextPageToken, false)}
          bsStyle="default"
          fill
        >
          <Svg name="chevron-right" className="w-6 h-6" />
        </Button>
      </div>
    </div>
  );
};

export default AppointmentsTable;
