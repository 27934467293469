import create from "zustand";
import { getSession } from "../../api/auth.js";
import ChatClient from "./chat";

type ChatState = {
  chatClient: ChatClient | undefined;
  createClient: () => void;
  destroyClient: () => void;
};

const useChatState = create<ChatState>((set, get) => ({
  chatClient: undefined,
  createClient: () => {
    const client = get().chatClient;
    if (!client) {
      const wsUrl = process.env.REACT_APP_CHAT_URL || "";
      const client = new ChatClient(wsUrl, () => {
        let session = getSession();
        return session ? session.accessToken : undefined;
      });

      set({ chatClient: client });
    }
  },
  destroyClient: () => {
    const client = get().chatClient;
    if (client) {
      client.disconnect();
      set({ chatClient: undefined });
    }
  },
}));

export default useChatState;
