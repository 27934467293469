import React, { useState, useRef, useCallback, useEffect } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import Button from "components/CustomButton/CustomButton.jsx";
import { formatFullAddress } from "api/common";
import LocationMarker from "../../../assets/img/appointment-location.png";

const AddLocationModal = (props) => {
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const [unitNumber, setUnitNumber] = useState(props.address ? props.address.unitNumber || "" : "");
  const [address, setAddress] = useState(null);
  const [addressInput, setAddressInput] = useState("");
  const [location, setLocation] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 45.4994169,
    longitude: -73.6651472,
    zoom: 12,
  });
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);

  useEffect(() => {
    setAddress(props.address || null);
    setUnitNumber(props.address ? props.address.unitNumber || "" : "");

    let location = props.location || props.address;
    if (location && location.latitude && location.longitude) {
      setLocation({ latitude: location.latitude, longitude: location.longitude });
      var vp = Object.assign({}, viewport, {
        latitude: location.latitude,
        longitude: location.longitude,
      });
      setViewport(vp);
    }
  }, [props.address]);

  const setMarkerLocation = (result) => {
    let place = result.context.find((c) => c.id.indexOf("place.") > -1);
    let postCode = result.context.find((c) => c.id.indexOf("postcode.") > -1);
    let region = result.context.find((c) => c.id.indexOf("region.") > -1);
    let street = `${result.address || ""} ${result.text || ""}`.trim();

    if (!place || !postCode || !region || !street) {
      return;
    }

    let data = {
      place: place.text,
      city: place.text,
      postCode: postCode.text,
      postalCode: postCode.text,
      region: region.short_code.replace("CA-", ""),
      province: region.short_code.replace("CA-", ""),
      street: street,
      unitNumber: unitNumber,
      latitude: result.center[1],
      longitude: result.center[0],
    };

    setAddress(data);
    setLocation({ latitude: data.latitude, longitude: data.longitude });
    setAddressInput("");
  };

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const onConfirmAddress = () => {
    if (props.onSelectAddress) {
      props.onSelectAddress(address, location);
    }

    props.onHide();
  };

  return (
    <div>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Search Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <MapGL
              ref={mapRef}
              {...viewport}
              width="100%"
              height="420px"
              onViewportChange={handleViewportChange}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
            >
              <NavigationControl style={navControlStyle} />
              <Geocoder
                mapRef={mapRef}
                onViewportChange={handleGeocoderViewportChange}
                containerRef={geocoderContainerRef}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                position="top-left"
                placeholder="Search Address"
                className="overWrite"
                marker={false}
                onResult={(result) => setMarkerLocation(result.result)}
                inputValue={addressInput}
                countries="ca"
                language="en,fr"
              />

              {location && location.latitude && location.longitude && (
                <Marker
                  latitude={location.latitude}
                  longitude={location.longitude}
                  offsetLeft={-20}
                  offsetTop={-10}
                >
                  <img width={30} src={LocationMarker} alt="Map Marker" />
                </Marker>
              )}
            </MapGL>
            <div className="flex flex-col gap-10" style={{ paddingTop: 12 }}>
              <FormGroup>
                <ControlLabel>Apartment, unit, suite, etc. (optional):</ControlLabel>
                <FormControl
                  value={unitNumber}
                  maxLength="50"
                  type="text"
                  name="apt-number"
                  onChange={(event) => {
                    setUnitNumber(event.target.value);
                    let addr = Object.assign({}, address);
                    addr.unitNumber = event.target.value || null;
                    setAddress(addr);
                  }}
                />
              </FormGroup>
              <div className="separator horizontal"></div>
              <div className="sub-text font-semibold">
                {address ? formatFullAddress(address) : "No address selected"}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="danger" fill block onClick={onConfirmAddress} disabled={!address}>
            Confirm Address
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddLocationModal;
