import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";

const SelectServiceModal = (props) => {
  const [allServices, setAllServices] = useState([]);

  useEffect(() => {
    if (props.show) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/services?corporateId=${
            props.corporateId ? encodeURIComponent(props.corporateId) : ""
          }`
        )
        .then((res) => {
          setAllServices(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setAllServices([]);
    }
  }, [props.show]);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Services</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-2col gap-16" style={{ padding: "10px" }}>
          {allServices.map((service) => {
            return (
              <Button
                key={service.id}
                bsStyle="danger"
                disabled={
                  props.person !== null &&
                  props.person !== undefined &&
                  props.person.services.find((s) => s.id === service.id) !== undefined
                }
                onClick={() => {
                  if (props.onConfirm) {
                    props.onConfirm(service);
                  }
                }}
              >
                <div className="flex flex-col gap-6 text-center">
                  <span className="text-semibold text-lg" style={{ whiteSpace: "normal" }}>
                    {service.title}
                  </span>
                  <span className="text-normal text-lg">
                    ${" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      signDisplay: "never",
                    }).format(service.price > 0 ? service.price / 100 : 0)}
                  </span>
                </div>
              </Button>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectServiceModal;
