import React, { useState, useEffect } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import NumberFormat from "react-number-format";
import moment from "moment";
import TimePicker from "react-time-picker";
import Svg from "components/Svg/Svg";

const PriceModal = (props) => {
  const [percentage, setPercentage] = useState(100.0);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [timeError, setTimeError] = useState(true);

  useEffect(() => {
    setPercentage(props.data ? (props.data.percentage >= 0 ? props.data.percentage : 100.0) : 0);
    setStartTime(props.data ? moment(props.data.start).format("H:mm") : "0:00");
    setEndTime(props.data ? moment(props.data.end).format("H:mm") : "23:59");
  }, [props.show, props.data]);

  useEffect(() => {
    if (props.data && props.show) {
      var start = moment(startTime, ["H:mm"]).day(props.data.dayOfWeek);
      var end = moment(endTime, ["H:mm"]).day(props.data.dayOfWeek);

      if (start.isSameOrAfter(end)) {
        setTimeError("The start time cannot be after or equal to the end time.");
      } else if (endTime == "00:00") {
        setTimeError("The latest possible time for the schedule is be 11:59pm.");
      } else {
        setTimeError(undefined);
      }
    }
  }, [startTime, endTime]);

  const onConfirm = () => {
    let priceData = {
      id: props.data.id || null,
      start: moment(startTime, ["H:mm"]).day(props.data.dayOfWeek).toDate(),
      end: moment(endTime, ["H:mm"]).day(props.data.dayOfWeek).toDate(),
      dayOfWeek: props.data.dayOfWeek,
      percentage: percentage,
    };
    props.onConfirm(priceData);
  };

  const onDeletePrice = () => {
    props.onDelete({
      id: props.data.id,
    });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Service Price Multiplier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Card
            content={
              <div className="flex flex-col gap-16">
                <div className="grid grid-2col gap-16">
                  <FormGroup>
                    <ControlLabel>DAY</ControlLabel>
                    <FormControl
                      type="text"
                      name="day-of-week"
                      disabled={true}
                      readOnly={true}
                      value={props.data ? props.data.dayOfWeek : ""}
                    />
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>TIME</ControlLabel>
                    <div className="flex items-center gap-4">
                      <TimePicker
                        disableClock
                        clearIcon=""
                        className="w-full"
                        value={startTime}
                        hourPlaceholder="h"
                        minutePlaceholder="mm"
                        locale="en-CA"
                        maxDetail="minute"
                        format="h:mm a"
                        onChange={(time) => setStartTime(time)}
                      />
                      <span> - </span>
                      <TimePicker
                        className="w-full"
                        disableClock
                        clearIcon=""
                        value={endTime}
                        hourPlaceholder="h"
                        minutePlaceholder="mm"
                        locale="en-CA"
                        maxDetail="minute"
                        format="h:mm a"
                        onChange={(time) => setEndTime(time)}
                      />
                    </div>
                  </FormGroup>
                </div>

                {timeError && (
                  <div
                    className="error-alert"
                    style={{ margin: "0px 0", marginTop: "-10px", padding: "0.6rem" }}
                  >
                    {timeError}
                  </div>
                )}

                <FormGroup>
                  <ControlLabel>PRICE MULTIPLIER (0% - 999%)</ControlLabel>
                  <div className="flex items-center">
                    <NumberFormat
                      value={percentage}
                      defaultValue={100.0}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      suffix="%"
                      className="form-control"
                      inputMode="numeric"
                      isAllowed={({ floatValue }) => {
                        return floatValue == undefined || (floatValue >= 0 && floatValue <= 999.0);
                      }}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setPercentage(value ? parseFloat(value) : 0);
                      }}
                    />
                  </div>
                </FormGroup>
              </div>
            }
          />

          <div className="flex items-center gap-10" style={{ marginTop: "16px" }}>
            {props.data && props.data.id && (
              <Button
                bsStyle="danger"
                className="mr-auto"
                outline="true"
                onClick={() => onDeletePrice()}
              >
                <Svg name="trash" className="w-4 h-4" />
                Delete Price Multiplier
              </Button>
            )}

            <Button
              bsStyle="danger"
              className="ml-auto"
              outline="true"
              onClick={() => props.onHide()}
            >
              Cancel
            </Button>

            <Button
              bsStyle="danger"
              fill
              onClick={() => onConfirm()}
              disabled={percentage < 0 || percentage > 999.0 || timeError !== undefined}
            >
              {props.data && props.data.id ? "Save Price Multiplier" : "Add Price Multiplier"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PriceModal;
