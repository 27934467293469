import React, { useState } from "react";
import { FormGroup, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Svg from "components/Svg/Svg";
import LocalizableTextInput from "../../../Components/LocalizableInputs/LocalizableTextInput.jsx";

const TextListItemView = (props) => {
  const [text, setText] = useState(props.text || "");
  const [localizations, setLocalizations] = useState(props.localizations || {});

  const handleTextChange = (newText) => {
    setText(newText);

    if (props.onChange) {
      props.onChange(
        {
          id: props.id,
          text: newText,
          localizations: localizations,
        },
        props.index
      );
    }
  };

  const handleLocalizationsChange = (translation) => {
    let newObj = Object.assign({}, localizations);
    Object.assign(newObj, translation);
    setLocalizations(newObj);

    if (props.onChange) {
      props.onChange(
        {
          id: props.id,
          text: text,
          localizations: newObj,
        },
        props.index
      );
    }
  };

  return (
    <div className="flex items-center gap-10">
      <FormGroup style={{ width: "100%" }}>
        <ControlLabel>{props.textLabel || "DESCRIPTION"}</ControlLabel>
        <LocalizableTextInput
          disabled={props.disabled || false}
          value={text}
          maxLength={150}
          onChange={handleTextChange}
          onLocalizationChange={handleLocalizationsChange}
          fieldName="Description"
          localizations={localizations}
        />
      </FormGroup>

      <Button
        bsStyle="danger"
        disabled={props.disabled || false}
        style={{ marginLeft: "auto", marginTop: "7px", padding: "8px 10px" }}
        title="Delete Item"
        onClick={props.onDelete}
        type="button"
      >
        <Svg name="trash" className="w-4 h-4" />
      </Button>
    </div>
  );
};

export default TextListItemView;
