import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import MapGL, { Marker, Popup, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import LocationMarker from "../../../assets/img/drop-off-marker.png";
import { useComponentSize, useWindowSize, getBoundsForPoints } from "api/common";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const DropOffLocationsMap = (props) => {
  let history = useHistory();
  const contentRef = React.useRef(null);
  const { width: contentWidth } = useComponentSize(contentRef);
  const { width: windowWidth } = useWindowSize();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 45.4994169,
    longitude: -73.6651472,
    zoom: props.zoom || 10,
    width: "100%",
    height: `${props.height || 400}px`,
  });

  useEffect(() => {
    window.addEventListener("keydown", mapKeyListener);

    let points = (props.locations || []).map((dropOff) => {
      return {
        longitude: parseFloat(dropOff.longitude),
        latitude: parseFloat(dropOff.latitude),
      };
    });

    try {
      if (points.length !== 0) {
        let centre = getBoundsForPoints(points, props.height || 400);
        setViewport({
          latitude: centre.latitude,
          longitude: centre.longitude,
          zoom: (centre.zoom > 13 ? 13 : centre.zoom) || props.zoom,
          width: "100%",
          height: `${props.height || 400}px`,
        });
      }
    } catch (mErr) {
      console.log(mErr);
    }

    return () => {
      window.removeEventListener("keydown", mapKeyListener);
    };
  }, [props.locations]);

  useEffect(() => {
    let vw = viewport;
    vw.width = `${contentWidth}px`;
    setViewport(vw);
  }, [contentWidth, windowWidth]);

  const mapKeyListener = (e) => {
    if (e.key === "Escape") {
      setSelectedLocation(null);
    }
  };

  return (
    <Card
      contentStyle={{ padding: "0" }}
      content={
        <div ref={contentRef} style={{ position: "relative", padding: "0" }}>
          <MapGL
            width="100%"
            height="100%"
            scrollZoom={true}
            maxPitch={85}
            {...viewport}
            onViewportChange={(viewport) => setViewport(viewport)}
            mapStyle="mapbox://styles/mapbox/light-v10"
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
          >
            <NavigationControl
              style={{
                right: 12,
                top: 12,
              }}
            />

            {(props.locations || []).map((dropOff) => (
              <Marker key={dropOff.id} longitude={parseFloat(dropOff.longitude)} latitude={parseFloat(dropOff.latitude)}>
                <button
                  className="marker-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedLocation(dropOff);
                  }}
                >
                  <img src={LocationMarker} alt="Drop Off Location Marker" />
                </button>
              </Marker>
            ))}

            {selectedLocation && (
              <Popup
                offsetLeft={36}
                offsetTop={16}
                dynamicPosition={true}
                anchor="bottom"
                closeOnClick={false}
                latitude={parseFloat(selectedLocation.latitude)}
                longitude={parseFloat(selectedLocation.longitude)}
                onClose={() => {
                  setSelectedLocation(null);
                }}
              >
                <div className="flex flex-col items-center justify-center gap-12" style={{ maxWidth: "300px", padding: "16px" }}>
                  <div className="flex flex-col gap-6 items-center text-center">
                    <div className="main-text text-xl text-semibold">{selectedLocation.name}</div>
                    <div className="sub-text text-xs">{selectedLocation.address}</div>
                  </div>

                  <Button
                    bsStyle="danger"
                    style={{ marginTop: "16px" }}
                    outline="true"
                    onClick={() => {
                      history.push(`/admin/drop-off/${selectedLocation.id}`);
                    }}
                  >
                    View Details
                  </Button>
                </div>
              </Popup>
            )}
          </MapGL>
        </div>
      }
    />
  );
};

export default DropOffLocationsMap;
