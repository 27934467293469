import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import moment from "moment";
import Datetime from "react-datetime";
import Select from "react-select";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import { parseError } from "api/common";
import Svg from "components/Svg/Svg";
import NumberFormat from "react-number-format";
import "react-phone-number-input/style.css";
import { dateFormat } from "api/common";

const AddPromocodeModal = (props) => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [value, setValue] = useState(1);
  const [expireAt, setExpiration] = useState(null);
  const [type, setType] = useState({ value: "fixed", label: "Fixed" });
  const [maxRedemptions, setRedemptions] = useState(1);
  const [multiRedemptionsEnabled, setMultiRedemptionsEnabled] = useState(false);
  const [zones, setZones] = useState([]);
  const [services, setServices] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [types] = useState([
    { label: "Fixed", value: "fixed" },
    { label: "Percentage", value: "percentage" },
  ]);

  useEffect(() => {
    if (props.show && (zoneOptions.length === 0 || serviceOptions.length === 0)) {
      loadRelatedData();
    }
  }, [props.show]);

  const loadRelatedData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/zones`)
      .then((res) => {
        const zonesList = res.data.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        setZoneOptions(zonesList);
      })
      .catch((err) => {
        parseError(err);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/services`)
      .then((res) => {
        const servicesList = res.data.map((svc) => ({
          value: svc.id,
          label: svc.title,
        }));
        setServiceOptions(servicesList);
      })
      .catch((err) => {
        parseError(err);
      });
  };

  const disablePastDt = (current) => {
    return current.isAfter(moment());
  };

  const changeDate = (event) => {
    if (event && typeof event.toDate === "function") {
      setExpiration(event.toDate());
    } else {
      setExpiration(null);
    }
  };

  const saveDiscount = () => {
    setLoading(true);

    let priceValue = value;
    if (type.value == "fixed") {
      priceValue = priceValue * 100;
    }

    const obj = {
      name: name,
      code: code,
      value: priceValue,
      expireAt: {
        year: expireAt.getFullYear(),
        month: expireAt.getMonth() + 1,
        day: expireAt.getDate(),
      },
      type: type.value,
      maxRedemptions: maxRedemptions,
      zones: zones.map((s) => s.value),
      services: services.map((s) => s.value),
      allowMultiAccountRedemptions: multiRedemptionsEnabled,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/discounts`, obj)
      .then((res) => {
        setLoading(false);

        Swal.fire({
          title: "Promo Code Created",
          icon: "success",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            props.onHide();
            props.onCreated();
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Promo Code Error", error, "error");
      });
  };

  const generateCode = async () => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_API_URL}/discounts/generate-code`)
      .then((res) => {
        setLoading(false);
        setCode(res.data.code);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Generate Error", error, "error");
      });
  };

  return (
    <div className="add-promocode">
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>New Promo Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-promocode">
            <Card
              content={
                <div className="grid grid-2col gap-16">
                  <div className="flex items-start gap-16" style={{ gridColumn: "1 / span 2" }}>
                    <FormGroup className="w-full">
                      <ControlLabel>Promo Name:</ControlLabel>
                      <FormControl
                        required
                        type="text"
                        name="name"
                        placeholder="Name that only visible to admins"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                    </FormGroup>

                    <div className="flex items-center gap-10 w-full">
                      <FormGroup className="w-full">
                        <ControlLabel>Code:</ControlLabel>
                        <FormControl
                          required
                          type="text"
                          name="code"
                          placeholder="Code"
                          value={code}
                          onChange={(event) => {
                            setCode(event.target.value);
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel></ControlLabel>
                        <Button
                          style={{ width: "100%", marginTop: "3px" }}
                          title="Generate Random Unique Code"
                          fill
                          bsStyle="danger"
                          onClick={generateCode}
                          disabled={loading}
                        >
                          <Svg className="w-4 h-4" name="refresh" />
                          Generate
                        </Button>
                      </FormGroup>
                    </div>
                  </div>

                  <FormGroup>
                    <ControlLabel>
                      Type: <span className="star">*</span>
                    </ControlLabel>
                    <Select
                      name="type-select"
                      isClearable={false}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Type"
                      isMulti={false}
                      value={type}
                      onChange={(opt) => {
                        setType(opt);
                        if (opt.value === "percentage") {
                          setValue(parseInt(value));
                        }
                      }}
                      options={types}
                      formatGroupLabel={(data) => (
                        <div className="flex items-center text-theme font-semibold">
                          {data.label}
                        </div>
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Value in {type.value === "percentage" ? "%" : "$"}:</ControlLabel>
                    <div className="flex items-center">
                      <NumberFormat
                        value={value}
                        defaultValue={0}
                        decimalScale={type.value === "percentage" ? 0 : 2}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        allowEmptyFormatting={true}
                        suffix={type.value === "percentage" ? "%" : ""}
                        prefix={type.value === "fixed" ? "$" : ""}
                        className="form-control"
                        inputMode="numeric"
                        isAllowed={({ floatValue }) => {
                          return (
                            floatValue == undefined ||
                            floatValue <= (type.value === "percentage" ? 100 : 9999999.99)
                          );
                        }}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setValue(
                            value
                              ? type.value === "percentage"
                                ? parseInt(value)
                                : parseFloat(value)
                              : 0
                          );
                        }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Maximum Redemptions:</ControlLabel>
                    <FormControl
                      required
                      type="number"
                      name="maxRedemptions"
                      placeholder="Maximum Redemptions"
                      min={0}
                      max={999999999}
                      value={maxRedemptions}
                      onChange={(event) => {
                        if (!isNaN(parseInt(event.target.value))) {
                          setRedemptions(parseInt(event.target.value));
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>
                      Expiry: <span className="star">*</span>
                    </ControlLabel>
                    <Datetime
                      dateFormat={dateFormat}
                      timeFormat={false}
                      inputProps={{ placeholder: "" }}
                      isValidDate={disablePastDt}
                      value={expireAt}
                      onChange={changeDate}
                      closeOnSelect={true}
                    />
                  </FormGroup>

                  <FormGroup className="flex flex-col">
                    <ControlLabel>Service Locations (Zones):</ControlLabel>
                    <Select
                      name="zone-tech-select"
                      isClearable={true}
                      isDisabled={loading}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Service Locations"
                      noOptionsMessage={() => "No Service Locations"}
                      isMulti={true}
                      value={zones}
                      onChange={(opt) => {
                        setZones(opt);
                      }}
                      options={zoneOptions}
                    />
                    <span
                      className="text-xxs sub-text"
                      style={{ marginTop: "6px", marginLeft: "4px" }}
                    >
                      If any service locations are selected, then this promo code can only be
                      applied to the appointments with the address within that serviceable zone.
                    </span>
                  </FormGroup>

                  <FormGroup className="flex flex-col">
                    <ControlLabel>Services:</ControlLabel>
                    <Select
                      name="services-tech-select"
                      isClearable={true}
                      isDisabled={loading}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Services"
                      noOptionsMessage={() => "No Services"}
                      isMulti={true}
                      value={services}
                      onChange={(opt) => {
                        setServices(opt);
                      }}
                      options={serviceOptions}
                    />
                    <span
                      className="text-xxs sub-text"
                      style={{ marginTop: "6px", marginLeft: "4px" }}
                    >
                      If services are selected, then this promo code can only be applied to the
                      appointments with these services.
                    </span>
                  </FormGroup>

                  <div className="block-group" style={{ gridColumn: "1 / span 2" }}>
                    <div className="switch">
                      <FormGroup>
                        <Switch
                          disabled={loading}
                          onChange={(checked) => setMultiRedemptionsEnabled(checked)}
                          checked={multiRedemptionsEnabled}
                          offColor="#E6E6E6"
                          onColor="#aac1e6"
                          onHandleColor="#0051d2"
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={40}
                        />
                        <ControlLabel>Multiple Redemptions Per Account</ControlLabel>
                      </FormGroup>
                    </div>
                    <span className="text-xxs sub-text">
                      If this option is enabled, then the customers will be able to use this promo
                      code multiple times. Otherwise promo code usage will be limited to one time
                      per the customer's account.
                    </span>
                  </div>

                  <div
                    className="flex items-center gap-10"
                    style={{ marginBottom: "16px", gridColumn: "1 / span 2" }}
                  >
                    <Button
                      style={{ marginLeft: "auto" }}
                      bsStyle="danger"
                      outline="true"
                      onClick={() => {
                        props.onHide();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      loading={loading}
                      disabled={
                        loading ||
                        !name ||
                        !expireAt ||
                        !value ||
                        !maxRedemptions ||
                        !type ||
                        !type.value ||
                        !code
                      }
                      bsStyle="danger"
                      fill
                      onClick={saveDiscount}
                    >
                      Add Promo Code
                    </Button>
                  </div>
                </div>
              }
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddPromocodeModal;
