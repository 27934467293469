import React, { useState, useEffect } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { parseError, languages } from "api/common.js";
import axios from "axios";
import Swal from "sweetalert2";
import LocalizableHtmlEditor from "../../Components/LocalizableInputs/LocalizableHtmlEditor.jsx";

const PlatformEditor = (props) => {
  const [loading, setLoading] = useState(false);
  const [localizations, setLocalizations] = useState({});
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    initializeEditor();
    getContent();
  }, []);

  const initializeEditor = () => {
    let paths = props.location.pathname.split("/");
    let currentPath = paths[paths.length - 1];

    switch (currentPath) {
      case "about":
        setTitle("About The App");
        break;
      case "privacy-policy":
        setTitle("Privacy Policy");
        break;
      case "terms":
        setTitle("Terms Of Service");
        break;
    }
  };

  const getContentPath = () => {
    let paths = props.location.pathname.split("/");
    let currentPath = paths[paths.length - 1];

    switch (currentPath) {
      case "about":
        return "abouttheapp";
      case "privacy-policy":
        return "privacypolicy";
      case "terms":
        return "termsofservice";
    }
  };

  const getContent = () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/platform/admin/${getContentPath()}`)
      .then((res) => {
        setLoading(false);
        setContent(res.data.content);

        let localizationsList = Object.assign({}, res.data.localizations || {});
        if (localizationsList.Content) {
          Object.keys(localizationsList.Content).forEach((lang) => {
            localizationsList.Content[lang] = localizationsList.Content[lang] || "";
          });
        } else {
          localizationsList.Content = {};
          Object.keys(languages).forEach((lang) => {
            localizationsList.Content[lang] = "";
          });
        }

        setLocalizations(localizationsList);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Content Error", error, "error");
      });
  };

  const updateContent = () => {
    setLoading(true);

    let localizationsList = Object.assign({}, localizations);
    const data = {
      content: content,
      localizations: localizationsList,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/platform/${getContentPath()}`, data)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: "Content Update",
          text: "Content has been successfully updated",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Update Error", error, "error");
      });
  };

  return (
    <div className="main-content system-settings">
      <Card
        content={
          <div className="flex flex-col gap-16">
            <div className="flex items-start gap-16">
              <strong className="text-xxl">{title}</strong>
              <Button
                loading={loading}
                disabled={loading}
                className="ml-auto"
                bsStyle="danger"
                onClick={() => updateContent()}
              >
                Save Changes
              </Button>
            </div>

            <LocalizableHtmlEditor
              value={content}
              onChange={(newContent) => setContent(newContent)}
              onLocalizationChange={(translation) => {
                let newObj = Object.assign({}, localizations);
                Object.assign(newObj, translation);
                setLocalizations(newObj);
              }}
              fieldName="Content"
              localizations={localizations}
            />
          </div>
        }
      />
    </div>
  );
};

export default PlatformEditor;
