import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillResize from "quill-resize-module";
import Quill from "quill";

Quill.register("modules/imageResize", QuillResize);

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["16px", "10px", "12px", "14px", "18px", "20px"];
Quill.register(Size, true);

const HtmlEditor = (props) => {
  const [content, setContent] = useState(props.value);

  useEffect(() => {
    setContent(props.value);
  }, [props.value]);

  return (
    <div
      className="quill-html-container"
      style={Object.assign({ maxHeight: props.height || "unset" }, props.style)}
    >
      <ReactQuill
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "size",
        ]}
        modules={{
          imageResize: {
            modules: ["Resize", "DisplaySize"],
          },
          clipboard: {
            matchVisual: false,
          },
          history: {
            delay: 2500,
            userOnly: true,
          },
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, false] }],
            [{ size: ["16px", "10px", "12px", "14px", "18px", "20px"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link", "image"],
            ["clean"],
          ],
        }}
        theme="snow"
        value={content}
        onChange={(val) => {
          setContent(val);
          if (props.onChange) {
            props.onChange(val);
          }
        }}
      />
    </div>
  );
};

export default HtmlEditor;
