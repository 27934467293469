import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import { FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import ServiceReportPreviewModal from "../Appointments/ServiceReportPreviewModal.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Svg from "components/Svg/Svg.jsx";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { parseError, renderDatePickerInput, dateTimeFormat, dateFormat } from "api/common.js";
import Select from "react-select";

const ServiceReportsView = () => {
  let history = useHistory();
  let location = useLocation();
  let locationSearchParams = new URLSearchParams(location.search);
  const savedPageSize = localStorage.getItem("reports-page-size")
    ? parseInt(localStorage.getItem("reports-page-size"))
    : 15;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(locationSearchParams.get("pageToken"));
  const [actualPageSize, setActualPageSize] = useState(savedPageSize);
  const [selectedPageSize, setSelectedPageSize] = useState({
    value: savedPageSize,
    label: `${savedPageSize.toString()} per page`,
  });
  const [reportKey, setReportKey] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(locationSearchParams.get("query") || "");
  const [fromDate, setFromDate] = useState(
    locationSearchParams.get("from") ? moment(locationSearchParams.get("from")).toDate() : null
  );
  const [endDate, setEndDate] = useState(
    locationSearchParams.get("to") ? moment(locationSearchParams.get("to")).toDate() : null
  );

  useEffect(() => {
    localStorage.setItem("reports-page-size", selectedPageSize.value);
    history.replace(history.location.pathname, null);
    getReports(null, true);
  }, [selectedPageSize]);

  const getReports = (pageToken = null, isBackward = true) => {
    setLoading(true);
    setError(null);
    setData([]);
    setActualPageSize(selectedPageSize.value);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/service-reports?pageSize=${
          selectedPageSize.value
        }&paginationToken=${pageToken || ""}&isBackward=${
          isBackward ? "true" : "false"
        }&query=${searchQuery}&from=${
          fromDate
            ? `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
            : ""
        }&to=${
          endDate ? `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}` : ""
        }`
      )
      .then((res) => {
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);
        setData(mapItems(res.data.items));
        setLoading(false);

        if (res.data.items.length > selectedPageSize.value) {
          setActualPageSize(res.data.items.length);
        }

        if (searchQuery || fromDate || endDate || pageToken || !isBackward) {
          var filterParams = new URLSearchParams();

          if (searchQuery) {
            filterParams.set("query", searchQuery);
          }

          if (fromDate) {
            filterParams.set(
              "from",
              `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
            );
          }

          if (endDate) {
            filterParams.set(
              "to",
              `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
            );
          }

          if (pageToken) {
            filterParams.set("pageToken", pageToken);
          }

          filterParams.set("backward", isBackward.toString());

          history.replace(`${history.location.pathname}?${filterParams.toString()}`, null);
        }
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapCode(prop);
    });
  };

  const mapCode = (prop) => {
    return {
      id: prop.id,
      appointment: formatAppointment(prop),
      patient: formatPatient(prop),
      service: formatService(prop),
      reportDate: formatDate(prop),
      place: prop.place,
      actions: (
        <div className="flex items-center gap-6">
          <Button
            title="View Report"
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px" }}
            onClick={() => setReportKey(prop.id)}
          >
            <Svg name="eye" className="w-4 h-4" />
          </Button>
        </div>
      ),
    };
  };

  const formatDate = (item) => {
    if (!item.reportDate) {
      return "--";
    }
    return moment(
      `${item.reportDate.day}/${item.reportDate.month}/${item.reportDate.year} ${item.reportDate.hour}:${item.reportDate.minute}`,
      "D/M/YYYY H:m"
    ).format(dateTimeFormat);
  };

  const formatPatient = (item) => {
    return `${item.firstName} ${item.lastName}`;
  };

  const formatAppointment = (item) => {
    return (
      <Link to={`/admin/appointments/${item.appointmentCode}`} title="View Appointment">
        {item.appointmentCode}
      </Link>
    );
  };

  const formatService = (item) => {
    return (
      <Link to={`/admin/services/${item.serviceId.replace("SE#", "")}`} title="View Service">
        {item.serviceName}
      </Link>
    );
  };

  const isValidFromDate = (current) => {
    return endDate ? current.isSameOrBefore(endDate) : true;
  };

  const isValidEndDate = (current) => {
    return fromDate ? current.isSameOrAfter(fromDate) : true;
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title="Service Reports"
        content={
          <div className="flex flex-col gap-16">
            <div style={{ position: "relative" }} className="flex items-center gap-12">
              <Svg
                name="search"
                style={{
                  color: "var(--sub-color)",
                  position: "absolute",
                  left: "12px",
                  top: "10px",
                  width: "20px",
                  height: "20px",
                }}
              />
              <FormControl
                style={{ paddingLeft: "36px" }}
                disabled={loading}
                type="text"
                maxLength="100"
                name="reports-search-query"
                value={searchQuery}
                placeholder="Search by the patient's name or appointment code..."
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    getReports(null, true);
                  }
                }}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />

              <div style={{ width: "140px", flexShrink: 0 }}>
                <ReactDatetime
                  dateFormat={dateFormat}
                  value={fromDate}
                  closeOnSelect={true}
                  renderInput={renderDatePickerInput}
                  inputProps={{
                    disabled: loading,
                    placeholder: "From Date",
                  }}
                  timeFormat={false}
                  isValidDate={isValidFromDate}
                  onChange={(e) => setFromDate(e ? moment(e).toDate() : null)}
                />
              </div>

              <div style={{ width: "140px", flexShrink: 0 }}>
                <ReactDatetime
                  dateFormat={dateFormat}
                  value={endDate}
                  closeOnSelect={true}
                  renderInput={renderDatePickerInput}
                  inputProps={{
                    disabled: loading,
                    placeholder: "To Date",
                  }}
                  timeFormat={false}
                  isValidDate={isValidEndDate}
                  onChange={(e) => setEndDate(e ? moment(e).toDate() : null)}
                />
              </div>

              <Button
                disabled={loading}
                loading={loading}
                style={{ height: "38px" }}
                bsStyle="danger"
                fill
                onClick={() => getReports(null, true)}
              >
                <Svg name="search" className="w-4 h-4" />
                Search
              </Button>
            </div>

            <div className="separator horizontal"></div>

            <ReactTable
              loading={loading}
              loadingText="Loading service reports..."
              noDataText={error ? error : loading ? "" : "No service reports found..."}
              data={data}
              columns={[
                {
                  Header: "Patient",
                  accessor: "patient",
                  sortable: false,
                  filterable: false,
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "Appointment",
                  accessor: "appointment",
                  sortable: false,
                  filterable: false,
                  maxWidth: 120,
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "Service",
                  accessor: "service",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Report Date",
                  accessor: "reportDate",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Location",
                  accessor: "place",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  maxWidth: 50,
                  resizable: false,
                },
              ]}
              showPaginationBottom={false}
              defaultPageSize={selectedPageSize.value}
              pageSize={actualPageSize}
              className="-striped -highlight"
            />
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <Select
                name="page-size-select"
                isClearable={false}
                isDisabled={loading}
                className="react-select mr-10 w-148"
                isSearchable={false}
                placeholder="Items Per Page"
                noOptionsMessage={() => "No Options"}
                isMulti={false}
                menuPlacement="top"
                value={selectedPageSize}
                onChange={(opt) => {
                  setSelectedPageSize({ value: opt.value, label: `${opt.value} per page` });
                }}
                options={[
                  { value: 10, label: "10" },
                  { value: 15, label: "15" },
                  { value: 20, label: "20" },
                  { value: 25, label: "25" },
                  { value: 40, label: "40" },
                  { value: 50, label: "50" },
                ]}
              />
              <Button
                className="pagination-btn left btn-fill"
                disabled={!previousPageToken || loading}
                onClick={() => getReports(previousPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={!nextPageToken || loading}
                onClick={() => getReports(nextPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />

      <ServiceReportPreviewModal
        show={reportKey != null}
        reportKey={reportKey}
        onHide={() => setReportKey(null)}
      />
    </div>
  );
};

export default ServiceReportsView;
