import React, { useState, useEffect } from "react";
import Loader from "views/Components/Loader/Loader";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { parseError } from "api/common";

const TechSchedule = (props) => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [alert, setAlert] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWeekSchedule(new Date());
  }, []);

  useEffect(() => {
    getWeekSchedule(currentDate || new Date());
  }, [props.isTechEnabled]);

  const getWeekDates = (curr) => {
    const initDate = moment(curr);
    const weekStart = initDate.clone().startOf("week");
    const weekEnd = initDate.clone().endOf("week");

    var firstDay = weekStart.toDate();
    var lastDay = weekEnd.toDate();

    return { start: firstDay, end: lastDay };
  };

  const getEventTitle = (techName) => {
    return `Shift for ${techName}`;
  };

  const getWeekSchedule = (date) => {
    setSelectedEvent(null);
    setEvents([]);
    setLoading(true);

    var weekDates = getWeekDates(date);
    const epochStart = Math.round(weekDates.start / 1000);
    const epochEnd = Math.round(weekDates.end / 1000);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/technicians/${props.techId}/shifts/${epochStart}/${epochEnd}?pageSize=7`
      )
      .then((res) => {
        setLoading(false);
        setEvents(
          res.data.items.map((item) => {
            return {
              shiftId: item.id,
              title: getEventTitle(`${item.technician.firstName} ${item.technician.lastName}`),
              start: new Date(
                item.startTime.year,
                item.startTime.month - 1,
                item.startTime.day,
                item.startTime.hour,
                item.startTime.minute
              ),
              end: new Date(
                item.endTime.year,
                item.endTime.month - 1,
                item.endTime.day,
                item.endTime.hour,
                item.endTime.minute
              ),
              allDay: false,
              color: "default",
            };
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Schedule Error", error, "error");
      });
  };

  const onDatesChanged = (date) => {
    setCurrentDate(date);
    getWeekSchedule(date);
  };

  const onSelectEvent = (event) => {
    if (props.isDeletedAccount) {
      alertTechDeleted();
      return;
    }

    if (!props.isTechEnabled) {
      alertTechDisabled();
      return;
    }

    setAlert(
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Remove Shift"
        onConfirm={() => onRemoveShift(event)}
        onCancel={() => {
          setAlert(null);
          setSelectedEvent(null);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Remove Shift"
      >
        <div>
          Do you want to remove the selected shift for {props.techName} on{" "}
          <strong>
            {moment(event.start).format("dddd, MMM D, YYYY")} (
            {moment(event.start).format("h:mm a")} - {moment(event.end).format("h:mm a")})
          </strong>
          ?
        </div>
      </SweetAlert>
    );
  };

  const onNewEventCreated = (slotInfo) => {
    if (props.isDeletedAccount) {
      alertTechDeleted();
      return;
    }

    if (!props.isTechEnabled) {
      alertTechDisabled();
      return;
    }

    setAlert(
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="New Shift"
        onConfirm={() => onSaveNewShift(slotInfo)}
        onCancel={() => {
          setAlert(null);
          setSelectedEvent(null);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Create Shift"
      >
        <div>
          Do you want to create a new shift for {props.techName} on{" "}
          <strong>
            {moment(slotInfo.start).format("dddd, MMM D, YYYY")} (
            {moment(slotInfo.start).format("h:mm a")} - {moment(slotInfo.end).format("h:mm a")})
          </strong>
          ?
        </div>
      </SweetAlert>
    );
  };

  const onRemoveShift = (pEvent) => {
    setLoading(true);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/technicians/shifts/${pEvent.shiftId}`)
      .then(() => {
        setLoading(false);
        setEvents((ev) => ev.filter((s) => s.shiftId !== pEvent.shiftId));
        setSelectedEvent(null);

        Swal.fire({
          title: "Shift has been successfully removed.",
          icon: "success",
          showCancelButton: false,
          confirmBtnText: "Remove Shift",
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Shift Error", error, "error");
      });

    setAlert(null);
  };

  const onSaveNewShift = (slotInfo) => {
    var newEvent = {
      title: getEventTitle(props.techName),
      start: slotInfo.start,
      end: slotInfo.end,
      allDay: false,
      color: "default",
    };

    const year = newEvent.start.getFullYear();
    const month = newEvent.start.getMonth() + 1;
    const day = newEvent.start.getDate();
    const startHour = newEvent.start.getHours();
    const startMin = newEvent.start.getMinutes();
    const endHour = newEvent.end.getHours();
    const endMin = newEvent.end.getMinutes();

    const data = {
      technicianId: props.techId,
      date: {
        year: year,
        month: month,
        day: day,
      },
      startTime: {
        hour: startHour,
        minute: startMin,
      },
      endTime: {
        hour: endHour,
        minute: endMin,
      },
    };

    setLoading(true);
    setAlert(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/technicians/shifts`, data)
      .then((_) => {
        setLoading(false);
        Swal.fire({
          title: `New shift has been created and assigned to ${props.techName}.`,
          icon: "success",
          showCancelButton: false,
        });
        getWeekSchedule(currentDate || new Date());
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Shift Error", error, "error");
      });
  };

  const eventColors = (event) => {
    var backgroundColor = "rbc-event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  const alertTechDisabled = () => {
    Swal.fire({
      title: "Please enable the technician's account in order to be able to manage their shifts.",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "OK",
    });
  };

  const alertTechDeleted = () => {
    Swal.fire({
      title: "This account has been deleted and cannot be modified.",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "OK",
    });
  };

  return (
    <React.Fragment>
      {alert}
      <Card
        style={{ height: "880px", padding: 0 }}
        contentStyle={{ position: "relative", height: "100%", padding: 0, margin: "0 -1px" }}
        content={
          <React.Fragment>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  background: "rgba(255, 255, 255, 0.8)",
                  width: "100%",
                  height: "100%",
                  zIndex: 10,
                }}
              >
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ height: "100%" }}
                >
                  <Loader title="Please wait..." />
                </div>
              </div>
            )}

            <Calendar
              selectable
              selectedEvent={selectedEvent}
              localizer={localizer}
              events={events}
              defaultView="week"
              step={15}
              timeslots={4}
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={new Date()}
              onSelectEvent={(event) => onSelectEvent(event)}
              views={["week"]}
              onNavigate={onDatesChanged}
              onSelectSlot={
                props.isDeletedAccount
                  ? alertTechDeleted
                  : props.isTechEnabled
                  ? (slotInfo) => onNewEventCreated(slotInfo)
                  : alertTechDisabled
              }
              eventPropGetter={eventColors}
            />
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default TechSchedule;
