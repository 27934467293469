import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactDatetime from "react-datetime";
import Svg from "components/Svg/Svg";
import ReactTable from "react-table";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { dateFormat, parseError, renderDatePickerInput } from "../../../api/common";
import Loader from "../../Components/Loader/Loader";
import Card from "components/Card/Card";

const KpiPage = (props) => {
  const [loadingTechReport, setLoadingTechReport] = useState(false);
  const [techReportError, setTechReportError] = useState(null);
  const [techReportData, setTechReportData] = useState(null);
  const [techFromDate, setTechFromDate] = useState(moment().startOf("month").toDate());
  const [techToDate, setTechToDate] = useState(moment().endOf("month").toDate());

  const [loadingAppReport, setLoadingAppReport] = useState(false);
  const [appReportError, setAppReportError] = useState(null);
  const [appReportData, setAppReportData] = useState(null);
  const [appFromDate, setAppFromDate] = useState(moment().startOf("month").toDate());
  const [appToDate, setAppToDate] = useState(moment().endOf("month").toDate());

  const getTechReport = () => {
    setTechReportData(null);
    setLoadingTechReport(true);
    setTechReportError(null);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/reports/technicians/performance?from=${moment(
          techFromDate
        )
          .startOf("day")
          .toISOString()}&to=${moment(techToDate).endOf("day").toISOString()}`
      )
      .then((res) => {
        setLoadingTechReport(false);
        setTechReportData(res.data);
      })
      .catch((error) => {
        setLoadingTechReport(false);
        setTechReportError(parseError(error));
      });
  };

  const getAppReport = () => {
    setAppReportData(null);
    setLoadingAppReport(true);
    setAppReportError(null);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/reports/app-monitoring?from=${moment(appFromDate)
          .startOf("day")
          .toISOString()}&to=${moment(appToDate).endOf("day").toISOString()}`
      )
      .then((res) => {
        setLoadingAppReport(false);
        setAppReportData(res.data);
      })
      .catch((error) => {
        setLoadingAppReport(false);
        setAppReportError(parseError(error));
      });
  };

  useEffect(() => {
    getAppReport();
    getTechReport();
  }, []);

  var isValidTechFromDate = (current) => {
    return techToDate ? current.isSameOrBefore(techToDate) : current.isBefore(moment());
  };

  var isValidTechEndDate = (current) => {
    return techFromDate ? current.isSameOrAfter(techFromDate) : current.isBefore(moment());
  };

  var isValidAppFromDate = (current) => {
    return appToDate ? current.isSameOrBefore(appToDate) : current.isBefore(moment());
  };

  var isValidAppEndDate = (current) => {
    return appFromDate ? current.isSameOrAfter(appFromDate) : current.isBefore(moment());
  };

  return (
    <div className="main-content dashboard">
      <div className="flex flex-col gap-16">
        <Card
          title="General App Monitoring"
          content={
            <div className="flex flex-col gap-12">
              <div
                className="flex items-center gap-16"
                style={{ borderBottom: "1px solid var(--border-color)", paddingBottom: "16px" }}
              >
                <div className="flex items-center gap-10">
                  <ReactDatetime
                    dateFormat={dateFormat}
                    value={appFromDate}
                    renderInput={renderDatePickerInput}
                    closeOnSelect={true}
                    inputProps={{
                      disabled: loadingAppReport,
                      placeholder: "From Date",
                      clearable: false,
                    }}
                    timeFormat={false}
                    isValidDate={isValidAppFromDate}
                    onChange={(e) => setAppFromDate(e ? moment(e).toDate() : null)}
                  />

                  <ReactDatetime
                    dateFormat={dateFormat}
                    value={appToDate}
                    closeOnSelect={true}
                    renderInput={renderDatePickerInput}
                    inputProps={{
                      disabled: loadingAppReport,
                      placeholder: "To Date",
                      clearable: false,
                    }}
                    timeFormat={false}
                    isValidDate={isValidAppEndDate}
                    onChange={(e) => setAppToDate(e ? moment(e).toDate() : null)}
                  />
                </div>

                <Button
                  disabled={loadingAppReport || !appFromDate || !appToDate}
                  loading={loadingAppReport}
                  style={{ height: "38px", marginLeft: "-6px" }}
                  bsStyle="danger"
                  fill
                  onClick={() => getAppReport()}
                >
                  Generate Report
                </Button>

                <a
                  download={appReportData ? appReportData.reportName : "Test.xlsx"}
                  href={
                    appReportData
                      ? `data:${appReportData.contentType};base64,${appReportData.reportData}`
                      : undefined
                  }
                >
                  <Button
                    disabled={loadingAppReport || !appReportData}
                    style={{ height: "38px", marginLeft: "-6px" }}
                    bsStyle="danger"
                    fill
                  >
                    <Svg name="download" className="w-4 h-4" />
                    Download
                  </Button>
                </a>
              </div>

              {loadingAppReport && (
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ margin: "32px auto" }}
                >
                  <Loader title="Generating report..." />
                </div>
              )}

              {appReportError && (
                <div
                  className="error-alert"
                  style={{ margin: "32px auto", padding: "0.6rem", maxWidth: "500px" }}
                >
                  {appReportError}
                </div>
              )}

              {!appReportData && !loadingAppReport && !appReportError && (
                <div style={{ margin: "48px auto", color: "var(--sub-color)" }}>
                  Please select the period and generate the report.
                </div>
              )}

              {appReportData && !loadingAppReport && (
                <div className="flex flex-col" style={{ marginTop: "20px" }}>
                  <div className="flex flex-col gap-4">
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "var(--theme-dark-color)",
                        margin: 0,
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      AVERAGE APPOINTMENT TIME
                    </h4>
                    <div style={{ marginTop: "12px" }}>
                      {`${moment
                        .duration(appReportData.averageAppointmentTime)
                        .hours()} hours ${moment
                        .duration(appReportData.averageAppointmentTime)
                        .minutes()} min ${moment
                        .duration(appReportData.averageAppointmentTime)
                        .seconds()} sec`}
                      <span
                        style={{ marginLeft: "6px", color: "var(--sub-color)", fontSize: "12px" }}
                      >
                        {`(across ${appReportData.completedAppointments} appointments)`}
                      </span>
                    </div>
                    <div style={{ color: "var(--sub-color)", fontSize: "12px", marginTop: "6px" }}>
                      The average appointment time is calculated as a difference between the time at
                      what an appointment has been started and the time when it was finished
                      (completed). This value is calculated only for the completed appointments
                      during the selected period.
                    </div>
                  </div>
                </div>
              )}

              {appReportData && !loadingAppReport && (
                <div className="flex flex-col" style={{ marginTop: "24px" }}>
                  <div className="flex flex-col gap-4">
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "var(--theme-dark-color)",
                        margin: 0,
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      BOOKINGS PER LOCATION
                    </h4>
                    <ReactTable
                      noDataText={"No bookings have been made during the selected period..."}
                      data={Object.keys(appReportData.zoneBookings)
                        .map((c) => appReportData.zoneBookings[c])
                        .filter((s) => s.totalAppointments > 0)}
                      columns={[
                        {
                          Header: "Location",
                          accessor: "name",
                          sortable: false,
                        },
                        {
                          Header: "Cancelled",
                          accessor: "cancelledAppointments",
                          sortable: false,
                          maxWidth: 140,
                        },
                        {
                          Header: "Completed",
                          accessor: "completedAppointments",
                          sortable: false,
                          maxWidth: 140,
                        },
                        {
                          Header: "Pending",
                          accessor: "pendingAppointments",
                          sortable: false,
                          maxWidth: 140,
                        },
                        {
                          Header: "Total Bookings",
                          accessor: "totalAppointments",
                          sortable: false,
                          maxWidth: 160,
                        },
                      ]}
                      defaultPageSize={
                        Object.keys(appReportData.zoneBookings)
                          .map((c) => appReportData.zoneBookings[c])
                          .filter((s) => s.totalAppointments > 0).length
                      }
                      pageSize={
                        Object.keys(appReportData.zoneBookings)
                          .map((c) => appReportData.zoneBookings[c])
                          .filter((s) => s.totalAppointments > 0).length
                      }
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              )}

              {appReportData && !loadingAppReport && (
                <div className="flex flex-col" style={{ marginTop: "20px" }}>
                  <div className="flex flex-col gap-4">
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "var(--theme-dark-color)",
                        margin: 0,
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      CORPORATE BOOKINGS
                    </h4>
                    <ReactTable
                      noDataText={"No corporate bookings found..."}
                      data={Object.keys(appReportData.corporateBookings)
                        .map((c) => appReportData.corporateBookings[c])
                        .filter((s) => s.totalBookings > 0)}
                      columns={[
                        {
                          Header: "Corporate Profile",
                          accessor: "name",
                          sortable: false,
                        },
                        {
                          Header: "By Admin",
                          accessor: "internalBookings",
                          sortable: false,
                          maxWidth: 160,
                        },
                        {
                          Header: "By Customers",
                          accessor: "customerBookings",
                          sortable: false,
                          maxWidth: 160,
                        },
                        {
                          Header: "Total Bookings",
                          accessor: "totalBookings",
                          sortable: false,
                          maxWidth: 140,
                        },
                      ]}
                      defaultPageSize={
                        Object.keys(appReportData.corporateBookings)
                          .map((c) => appReportData.corporateBookings[c])
                          .filter((s) => s.totalBookings > 0).length || 4
                      }
                      pageSize={
                        Object.keys(appReportData.corporateBookings)
                          .map((c) => appReportData.corporateBookings[c])
                          .filter((s) => s.totalBookings > 0).length || 4
                      }
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              )}

              {appReportData && !loadingAppReport && (
                <div className="flex flex-col" style={{ marginTop: "24px" }}>
                  <div className="flex flex-col gap-4">
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "var(--theme-dark-color)",
                        margin: 0,
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      DROP-OFF LOCATION USAGE
                    </h4>
                    <ReactTable
                      noDataText={
                        "No drop-off locations have been used during the selected period..."
                      }
                      data={Object.keys(appReportData.dropOffLocations)
                        .map((c) => appReportData.dropOffLocations[c])
                        .filter((s) => s.timesUsed > 0)}
                      columns={[
                        {
                          Header: "Location",
                          accessor: "name",
                          sortable: false,
                        },
                        {
                          Header: "Times Used",
                          accessor: "timesUsed",
                          sortable: false,
                          maxWidth: 160,
                        },
                      ]}
                      defaultPageSize={
                        Object.keys(appReportData.dropOffLocations)
                          .map((c) => appReportData.dropOffLocations[c])
                          .filter((s) => s.timesUsed > 0).length || 4
                      }
                      pageSize={
                        Object.keys(appReportData.dropOffLocations)
                          .map((c) => appReportData.dropOffLocations[c])
                          .filter((s) => s.timesUsed > 0).length || 4
                      }
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              )}
            </div>
          }
        />

        <Card
          title="Technician Performance"
          content={
            <div className="flex flex-col gap-12">
              <div className="flex items-center gap-16">
                <div className="flex items-center gap-10">
                  <ReactDatetime
                    dateFormat={dateFormat}
                    value={techFromDate}
                    renderInput={renderDatePickerInput}
                    closeOnSelect={true}
                    inputProps={{
                      disabled: loadingTechReport,
                      placeholder: "From Date",
                      clearable: false,
                    }}
                    timeFormat={false}
                    isValidDate={isValidTechFromDate}
                    onChange={(e) => setTechFromDate(e ? moment(e).toDate() : null)}
                  />

                  <ReactDatetime
                    dateFormat={dateFormat}
                    value={techToDate}
                    closeOnSelect={true}
                    renderInput={renderDatePickerInput}
                    inputProps={{
                      disabled: loadingTechReport,
                      placeholder: "To Date",
                      clearable: false,
                    }}
                    timeFormat={false}
                    isValidDate={isValidTechEndDate}
                    onChange={(e) => setTechToDate(e ? moment(e).toDate() : null)}
                  />
                </div>

                <Button
                  disabled={loadingTechReport || !techFromDate || !techToDate}
                  loading={loadingTechReport}
                  style={{ height: "38px", marginLeft: "-6px" }}
                  bsStyle="danger"
                  fill
                  onClick={() => getTechReport()}
                >
                  Generate Report
                </Button>

                <a
                  download={techReportData ? techReportData.reportName : "Test.xlsx"}
                  href={
                    techReportData
                      ? `data:${techReportData.contentType};base64,${techReportData.reportData}`
                      : undefined
                  }
                >
                  <Button
                    disabled={loadingTechReport || !techReportData}
                    style={{ height: "38px", marginLeft: "-6px" }}
                    bsStyle="danger"
                    fill
                  >
                    <Svg name="download" className="w-4 h-4" />
                    Download
                  </Button>
                </a>
              </div>

              {loadingTechReport && (
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ margin: "32px auto" }}
                >
                  <Loader title="Generating report..." />
                </div>
              )}

              {techReportError && (
                <div
                  className="error-alert"
                  style={{ margin: "32px auto", padding: "0.6rem", maxWidth: "500px" }}
                >
                  {techReportError}
                </div>
              )}

              {!techReportData && !loadingTechReport && !techReportError && (
                <div style={{ margin: "48px auto", color: "var(--sub-color)" }}>
                  Please select the period and generate the report.
                </div>
              )}

              {techReportData && !loadingTechReport && (
                <div className="flex flex-col" style={{ marginTop: "20px" }}>
                  <div className="flex flex-col gap-4">
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "var(--theme-dark-color)",
                        margin: 0,
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      APPOINTMENT LATES
                    </h4>
                    <ReactTable
                      noDataText={"No appointment lates recorded during the selected period..."}
                      data={Object.keys(techReportData.technicians)
                        .map((c) => techReportData.technicians[c])
                        .filter((s) => s.totalLateAppointments > 0)}
                      columns={[
                        {
                          Header: "Technician's Name",
                          id: "tech-name-column",
                          accessor: (item) => `${item.profile.firstName} ${item.profile.lastName}`,
                          sortable: false,
                        },
                        {
                          Header: "Started Appointments",
                          accessor: "totalStartedAppointments",
                          sortable: false,
                          maxWidth: 180,
                        },
                        {
                          Header: "Late Appointments",
                          accessor: "totalLateAppointments",
                          sortable: false,
                          maxWidth: 180,
                        },
                        {
                          Header: "Average Late Time",
                          id: "late-time-column",
                          accessor: (item) => {
                            return `${moment
                              .duration(item.averageAppointmentLateTime)
                              .hours()} hours ${moment
                              .duration(item.averageAppointmentLateTime)
                              .minutes()} min ${moment
                              .duration(item.averageAppointmentLateTime)
                              .seconds()} sec`;
                          },
                          sortable: false,
                        },
                      ]}
                      defaultPageSize={
                        Object.keys(techReportData.technicians)
                          .map((c) => techReportData.technicians[c])
                          .filter((s) => s.totalLateAppointments > 0).length || 4
                      }
                      pageSize={
                        Object.keys(techReportData.technicians)
                          .map((c) => techReportData.technicians[c])
                          .filter((s) => s.totalLateAppointments > 0).length || 4
                      }
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              )}

              {techReportData && !loadingTechReport && (
                <div className="flex flex-col" style={{ marginTop: "20px" }}>
                  <div className="flex flex-col gap-4">
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "var(--theme-dark-color)",
                        margin: 0,
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      SHIFT LATES
                    </h4>
                    <ReactTable
                      noDataText={"No shift lates recorded during the selected period..."}
                      data={Object.keys(techReportData.technicians)
                        .map((c) => techReportData.technicians[c])
                        .filter((s) => s.totalShiftsLate > 0)}
                      columns={[
                        {
                          Header: "Technician's Name",
                          id: "tech-name-column",
                          accessor: (item) => `${item.profile.firstName} ${item.profile.lastName}`,
                          sortable: false,
                        },
                        {
                          Header: "Total Shifts",
                          accessor: "totalShifts",
                          sortable: false,
                          maxWidth: 180,
                        },
                        {
                          Header: "Late Shifts",
                          accessor: "totalShiftsLate",
                          sortable: false,
                          maxWidth: 180,
                        },
                        {
                          Header: "Average Late Time",
                          id: "late-time-column",
                          accessor: (item) => {
                            return `${moment
                              .duration(item.averageShiftLateTime)
                              .hours()} hours ${moment
                              .duration(item.averageShiftLateTime)
                              .minutes()} min ${moment
                              .duration(item.averageShiftLateTime)
                              .seconds()} sec`;
                          },
                          sortable: false,
                        },
                      ]}
                      defaultPageSize={
                        Object.keys(techReportData.technicians)
                          .map((c) => techReportData.technicians[c])
                          .filter((s) => s.totalShiftsLate > 0).length || 4
                      }
                      pageSize={
                        Object.keys(techReportData.technicians)
                          .map((c) => techReportData.technicians[c])
                          .filter((s) => s.totalShiftsLate > 0).length || 4
                      }
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              )}

              {techReportData && !loadingTechReport && (
                <div className="flex flex-col" style={{ marginTop: "20px" }}>
                  <div className="flex flex-col gap-4">
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "var(--theme-dark-color)",
                        margin: 0,
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      ASAP APPOINTMENTS
                    </h4>
                    <ReactTable
                      noDataText={"No ASAP requests recorded during the selected period..."}
                      data={Object.keys(techReportData.technicians)
                        .map((c) => techReportData.technicians[c])
                        .filter((s) => s.totalAsapAppointmentsOffered > 0)}
                      columns={[
                        {
                          Header: "Technician's Name",
                          id: "tech-name-column",
                          accessor: (item) => `${item.profile.firstName} ${item.profile.lastName}`,
                          sortable: false,
                        },
                        {
                          Header: "Offered (Total)",
                          accessor: "totalAsapAppointmentsOffered",
                          sortable: false,
                          maxWidth: 180,
                        },
                        {
                          Header: "Missed (Not Accepted)",
                          accessor: "totalAsapMissedAppointments",
                          sortable: false,
                          maxWidth: 200,
                        },
                        {
                          Header: "Average Time To Accept",
                          id: "accept-time-column",
                          accessor: (item) => {
                            return item.totalAsapAppointmentsOffered !==
                              item.totalAsapMissedAppointments
                              ? `${moment
                                  .duration(item.averageTimeToAcceptAsap)
                                  .minutes()} min ${moment
                                  .duration(item.averageTimeToAcceptAsap)
                                  .seconds()} sec`
                              : "--";
                          },
                          sortable: false,
                        },
                      ]}
                      defaultPageSize={
                        Object.keys(techReportData.technicians)
                          .map((c) => techReportData.technicians[c])
                          .filter((s) => s.totalAsapAppointmentsOffered > 0).length || 4
                      }
                      pageSize={
                        Object.keys(techReportData.technicians)
                          .map((c) => techReportData.technicians[c])
                          .filter((s) => s.totalAsapAppointmentsOffered > 0).length || 4
                      }
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              )}

              {techReportData && !loadingTechReport && (
                <div className="flex flex-col" style={{ marginTop: "20px" }}>
                  <div className="flex flex-col gap-4">
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "var(--theme-dark-color)",
                        margin: 0,
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      CANCELLED APPOINTMENTS{" "}
                      {Object.keys(techReportData.technicians).flatMap((c) =>
                        Object.keys(techReportData.technicians[c].cancelledAppointments)
                      ).length > 0
                        ? `(${
                            Object.keys(techReportData.technicians).flatMap((c) =>
                              Object.keys(techReportData.technicians[c].cancelledAppointments)
                            ).length
                          })`
                        : ""}
                    </h4>
                    <ReactTable
                      noDataText={
                        "No cancelled appointments recorded during the selected period..."
                      }
                      data={Object.keys(techReportData.technicians)
                        .map((c) => {
                          return {
                            technician: techReportData.technicians[c].profile,
                            appointments: techReportData.technicians[c].cancelledAppointments,
                          };
                        })
                        .flatMap((s) =>
                          Object.keys(s.appointments).map((code) => {
                            return {
                              appointmentCode: code,
                              reason: s.appointments[code],
                              tech: s.technician,
                            };
                          })
                        )}
                      columns={[
                        {
                          Header: "Technician's Name",
                          id: "tech-name-column",
                          accessor: (item) => `${item.tech.firstName} ${item.tech.lastName}`,
                          sortable: false,
                        },
                        {
                          Header: "Appointment Code",
                          id: "appointmemnt-code-column",
                          accessor: (item) => {
                            return (
                              <Link
                                to={`/admin/appointments/${encodeURIComponent(
                                  item.appointmentCode
                                )}`}
                                title="View Appointment"
                              >
                                {item.appointmentCode}
                              </Link>
                            );
                          },
                          sortable: false,
                          maxWidth: 200,
                        },
                        {
                          Header: "Reason",
                          accessor: "reason",
                          sortable: false,
                        },
                      ]}
                      defaultPageSize={
                        Object.keys(techReportData.technicians).flatMap((c) =>
                          Object.keys(techReportData.technicians[c].cancelledAppointments)
                        ).length || 4
                      }
                      pageSize={
                        Object.keys(techReportData.technicians).flatMap((c) =>
                          Object.keys(techReportData.technicians[c].cancelledAppointments)
                        ).length || 4
                      }
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default KpiPage;
