import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "components/Footer/Footer.jsx";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import routes from "routes.js";
import bgImage from "assets/img/full-screen-image-5.jpg";

const AuthLayout = (props) => {
  useEffect(() => {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, []);

  const getPageClass = () => {
    var pageClass = "";
    switch (props.location.pathname) {
      case "/auth/login":
      case "/auth/forgot-password":
      case "/auth/reset-password":
      case "/auth/change-password":
      case "/auth/admin-invitation":
        pageClass = " login-page";
        break;
      case "/auth/lock-screen-page":
        pageClass = " lock-page";
        break;
      default:
        pageClass = "";
        break;
    }
    return pageClass;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else if (prop.layout === "") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <AuthNavbar />
      <div className="wrapper wrapper-full-page">
        <div className={"full-page" + getPageClass()} data-color="black" data-image={bgImage}>
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <Footer transparent />
          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
