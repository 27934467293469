import React, { useEffect, useState } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Swal from "sweetalert2";
import ZonesMap from "./ZonesMap.jsx";
import Svg from "components/Svg/Svg.jsx";
import { FormControl } from "react-bootstrap";
import axios from "axios";
import Loader from "../../../views/Components/Loader/Loader.jsx";
import { parseError } from "api/common.js";

const ServiceLocations = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [highlightedZone, setHighlightedZone] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getServiceLocations();
  }, []);

  const getServiceLocations = () => {
    setLoading(true);
    setError(null);
    setLocations([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/zones/detailed`)
      .then((res) => {
        if (res.data.length > pageSize) {
          setPageSize(res.data.length);
        }
        setLoading(false);
        setLocations(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const deleteServiceLocation = async (zone) => {
    Swal.fire({
      title: "",
      text: `Are you sure you want to delete the '${zone.name}' service location?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Location",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/zones/${zone.id}`)
          .then((res) => {
            getServiceLocations();

            Swal.fire({
              title: "Service location has been successfully deleted.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            setLoading(false);
            Swal.fire({
              title: "Delete Error",
              text: error,
              icon: "error",
              showCancelButton: false,
            });
          });
      }
    });
  };

  return (
    <div className="main-content flex flex-col gap-16" style={{ padding: 0, position: "relative" }}>
      <div
        className="flex items-center flex-col gap-16 service-zones-list"
        style={{
          position: "absolute",
          zIndex: 10,
          border: "1px solid var(--border-color)",
          borderRadius: "7px",
          left: 16,
          top: 16,
          height: "calc(100% - 32px)",
          maxHeight: "780px",
          width: "300px",
          overflow: "hidden",
          padding: "16px",
          boxShadow:
            "0.4px 0.8px 1px hsl(var(--shadow-color) / 0.21), 1.2px 2.2px 2.9px -0.8px hsl(var(--shadow-color) / 0.22), 2.9px 5.3px 7px -1.5px hsl(var(--shadow-color) / 0.23), 6.8px 12.5px 16.4px -2.3px hsl(var(--shadow-color) / 0.24)",
        }}
      >
        <div
          className="flex flex-col gap-16"
          style={{ zIndex: 1, width: "100%", height: "100%", position: "relative" }}
        >
          <div style={{ position: "relative" }} className="flex items-center gap-12">
            <Svg
              name="search"
              style={{
                color: "var(--sub-color)",
                position: "absolute",
                left: "12px",
                top: "10px",
                width: "20px",
                height: "20px",
              }}
            />
            <FormControl
              style={{ paddingLeft: "36px" }}
              disabled={loading}
              type="text"
              maxLength="100"
              name="reports-search-query"
              value={searchQuery}
              placeholder="Search locations..."
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
            />
          </div>

          <div className="separator horizontal"></div>

          <div className="zones-list">
            {loading && (
              <div className="flex flex-col items-center justify-center">
                <Loader title="Loading..." />
              </div>
            )}
            {locations
              .filter(
                (p) =>
                  searchQuery.length === 0 ||
                  p.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
              )
              .map((prop) => (
                <div
                  onPointerEnter={(e) => {
                    setHighlightedZone({
                      id: prop.id,
                      coordinates: prop.coordinates,
                    });
                  }}
                  onPointerLeave={(e) => {
                    setHighlightedZone(null);
                  }}
                  key={prop.id}
                  className={`zone-view-item ${
                    selectedZone !== null && selectedZone.id === prop.id ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedZone((prev) => (prev !== null && prev.id === prop.id ? null : prop));
                  }}
                >
                  <div className="flex flex-col">
                    <span className="zone-title">{prop.name}</span>
                    <span className="zone-subtitle">{prop.serviceCentreName || "--"}</span>
                  </div>
                  <div className="flex items-center gap-6 ml-auto">
                    <Button
                      title="View/Edit Details"
                      bsStyle="danger"
                      outline="true"
                      style={{ padding: "4px 6px" }}
                      onClick={() => props.history.push(`/admin/service-locations/${prop.id}`)}
                    >
                      <Svg name="edit" className="w-4 h-4" />
                    </Button>
                    <Button
                      onClick={() => deleteServiceLocation(prop)}
                      title="Delete Service Location"
                      bsStyle="danger"
                      outline="true"
                      style={{ padding: "4px 6px" }}
                    >
                      <Svg name="trash" className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              ))}
          </div>

          <div className="separator horizontal"></div>

          <Button
            bsStyle="danger"
            fill
            onClick={() => {
              props.history.push("/admin/service-locations/new");
            }}
          >
            <Svg name="plus" className="w-4 h-4" />
            New Service Location
          </Button>
        </div>
      </div>

      <ZonesMap
        locations={locations}
        selectedZone={selectedZone}
        selected={
          (selectedZone !== null
            ? {
                id: selectedZone.id,
                coordinates: selectedZone.coordinates,
              }
            : undefined) || highlightedZone
        }
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
    </div>
  );
};

export default ServiceLocations;
