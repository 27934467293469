import React, { useState, useEffect, useRef } from "react";
import { FormGroup, ControlLabel } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Button from "components/CustomButton/CustomButton.jsx";
import Swal from "sweetalert2";
import Switch from "react-switch";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import axios from "axios";
import Svg from "components/Svg/Svg.jsx";
import TextListControl from "./TextListControl/TextListControl.jsx";
import PlaceholderImage from "../../../assets/img/placeholder.svg";
import { parseError, languages } from "api/common.js";
import LocalizableTextInput from "../../Components/LocalizableInputs/LocalizableTextInput.jsx";
import LocalizableTextArea from "../../Components/LocalizableInputs/LocalizableTextArea.jsx";
import LocalizableHtmlEditor from "../../Components/LocalizableInputs/LocalizableHtmlEditor.jsx";
import PriceSchedule from "./PriceSchedule.jsx";

const ServiceView = (props) => {
  const imageFileInput = useRef();
  const [serviceId, setServiceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [ageRestriction, setAgeRestriction] = useState("");
  const [price, setPrice] = useState(0);
  const [duration, setDuration] = useState(0);
  const [commission, setCommission] = useState(0);
  const [pictureFile, setPictureFile] = useState(null);
  const [pictureData, setPictureData] = useState(null);
  const [picturePreview, setPicturePreview] = useState(null);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [dropOffRequired, setDropOffRequired] = useState(false);
  const [serviceNotesRequired, setServiceNotesRequired] = useState(false);
  const [requisitionRequired, setRequisitionRequired] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [localizations, setLocalizations] = useState({});
  const [description, setDescription] = useState("");
  const [priceSchedule, setPriceSchedule] = useState({
    useCommonSchedule: true,
    commonSchedule: [],
    zoneSchedules: {},
  });
  const [reportEnabled, setReportEnabled] = useState(false);
  const [reportOptions, setReportOptions] = useState([]);
  const [reportTemplate, setReportTemplate] = useState();
  const [zones, setZones] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [daysOptions] = useState([
    { value: "Sunday", label: "Sun" },
    { value: "Monday", label: "Mon" },
    { value: "Tuesday", label: "Tue" },
    { value: "Wednesday", label: "Wed" },
    { value: "Thursday", label: "Thu" },
    { value: "Friday", label: "Fri" },
    { value: "Saturday", label: "Sat" },
  ]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    let svcId = props.match.params.id;
    if (svcId !== "new") {
      setServiceId(svcId);
    } else {
      let localizationsList = {};
      localizationsList.Description = {};
      Object.keys(languages).forEach((lang) => {
        localizationsList.Description[lang] = "";
      });

      setLocalizations(localizationsList);
    }
  }, []);

  useEffect(() => {
    if (serviceId && serviceId != "new") {
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL.replace("v1", "v2")}/services/${serviceId}`)
        .then((res) => {
          setLoading(false);
          initializeData(res.data);
          loadRelatedData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          Swal.fire("Service Error", error, "error");
        });
    }
  }, [serviceId]);

  useEffect(() => {
    return function cleanup() {
      if (picturePreview) {
        URL.revokeObjectURL(picturePreview);
      }
    };
  }, [picturePreview]);

  const getQuestions = () => {
    if (questionOptions.length > 0) {
      return;
    }

    setQuestionOptions([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/questionnaire`)
      .then((res) => {
        setQuestionOptions(res.data);
      })
      .catch((err) => {
        parseError(err);
      });
  };

  const loadRelatedData = (svc) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/services/report-templates`)
      .then((res) => {
        let templatesList = res.data.map((s) => {
          return { value: s.id, label: s.title };
        });
        setReportOptions(templatesList);
        if (svc.reportTemplateId) {
          setReportTemplate(templatesList.find((r) => r.value === svc.reportTemplateId));
        }
      })
      .catch((err) => {
        setReportOptions([]);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/zones`)
      .then((res) => {
        const zonesList = res.data.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        setZoneOptions(zonesList);

        if (svc.zoneIds && svc.zoneIds.length > 0) {
          let currentZones = zonesList.filter((s) =>
            svc.zoneIds.some((z) => z.replace("ZN#", "") === s.value)
          );

          setZones(currentZones);
        } else {
          setZones([]);
        }
      })
      .catch((err) => {
        parseError(err);
      });

    getQuestions();
  };

  const initializeData = (service) => {
    setTitle(service.title);
    setDescription(service.description || "");
    setPriceSchedule(service.priceSchedule);
    setPrice(service.basePrice / 100.0);
    setCommission(service.commissionPercentage);
    setDisclaimer(service.disclaimer || "");
    setAgeRestriction(service.ageRestriction || "");
    setIsPrivate(service.private);
    setDuration(service.durationInMinute);
    setRequisitionRequired(service.isRequisitionFormRequired);
    setDropOffRequired(service.isDropOffLocationRequired);
    setServiceNotesRequired(service.isServiceNoteRequired);
    if (service.reportTemplateId) {
      setReportEnabled(true);

      if (reportOptions.length > 0) {
        setReportTemplate(reportOptions.find((s) => s.value === service.reportTemplateId));
      }
    } else {
      setReportEnabled(false);
      setReportTemplate(undefined);
    }
    setQuestionnaire(service.questionnaire || []);
    setChecklist(
      (service.checklist || []).map((q) => {
        return { text: q.description, id: q.id, localizations: q.localizations || {} };
      })
    );

    if (service.picture) {
      setPictureData(service.picture);
    }

    setSelectedDays(
      daysOptions.filter((s) => service.daysAvailability.some((dd) => dd === s.value))
    );

    let localizationsList = Object.assign({}, service.localizations || {});
    if (localizationsList.Description) {
      Object.keys(localizationsList.Description).forEach((lang) => {
        localizationsList.Description[lang] = localizationsList.Description[lang] || "";
      });
    } else {
      localizationsList.Description = {};
      Object.keys(languages).forEach((lang) => {
        localizationsList.Description[lang] = "";
      });
    }

    setLocalizations(localizationsList);
  };

  const onImageFileUpload = () => {
    imageFileInput.current.click();
  };

  const onImageFileChange = (e) => {
    setPictureFile(e.target.files[0]);
    try {
      setPicturePreview(URL.createObjectURL(e.target.files[0]));
    } catch (err) {
      console.error(err);
      setPicturePreview(null);
    }
  };

  const saveService = async (e) => {
    const isUpdate = serviceId && serviceId !== "new";
    setLoading(true);

    let uploadCompleted = false;
    let imageId = pictureData ? pictureData.id : null;

    try {
      if (pictureFile) {
        const file = pictureFile;
        let formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("type", "serviceImage");

        let fileRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/file/upload/image`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        imageId = fileRes.data.id;
      }

      uploadCompleted = true;
    } catch (err) {
      setLoading(false);
      let error = parseError(err);
      Swal.fire("Image Upload Error", error, "error");
    }

    if (!uploadCompleted) {
      setLoading(false);
      return;
    }

    let localizationsList = Object.assign({}, localizations);
    const serviceData = {
      id: serviceId ? serviceId : null,
      title: title,
      ageRestriction: ageRestriction,
      description: description,
      localizations: localizationsList,
      basePrice: parseInt(price * 100),
      durationInMinute: duration,
      commissionPercentage: commission,
      disclaimer: disclaimer || null,
      isDropOffLocationRequired: dropOffRequired,
      isRequisitionFormRequired: requisitionRequired,
      isServiceNoteRequired: serviceNotesRequired,
      private: isPrivate,
      priceSchedule: {
        useCommonSchedule: priceSchedule.useCommonSchedule,
        commonSchedule: priceSchedule.useCommonSchedule ? priceSchedule.commonSchedule : [],
        zoneSchedules: priceSchedule.useCommonSchedule ? {} : priceSchedule.zoneSchedules,
      },
      questionnaireItems: questionnaire.map((q) => q.id),
      checklist: checklist.map((q) => {
        return { description: q.text, id: q.id, localizations: q.localizations || {} };
      }),
      picture: {
        fileName: imageId || null,
      },
      reportTemplateId: reportEnabled ? reportTemplate.value : null,
      daysAvailability: selectedDays.map((s) => s.value),
      zoneIds: zones.map((s) => s.value),
    };

    const requestOptions = {
      method: isUpdate ? "PUT" : "POST",
      url: `${process.env.REACT_APP_API_URL}/services`,
      data: serviceData,
    };

    axios(requestOptions)
      .then((res) => {
        if (isUpdate) {
          initializeData(res.data);
        }
        setLoading(false);
        Swal.fire({
          title: "Service Created",
          text: `The service '${serviceData.title}' has been ${
            isUpdate ? "updated" : "created"
          } successfully.`,
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          if (!isUpdate) {
            props.history.push("/admin/services");
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Service Error", error, "error");
      });
  };

  const limitPrice = ({ floatValue }) => {
    return floatValue == undefined || floatValue < 999999.99;
  };
  const limitCommission = ({ floatValue }) => {
    return floatValue == undefined || floatValue <= 100.0;
  };
  const limitDuration = ({ floatValue }) => {
    return floatValue == undefined || floatValue < 3600;
  };

  return (
    <div className="main-content">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "12px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Go Back</span>
      </Button>

      <Card
        content={
          <div className="flex flex-col gap-24">
            <div className="flex items-start col-2span gap-24">
              <FormGroup style={{ flexShrink: "0", marginBottom: "0" }}>
                <ControlLabel>Service Image:</ControlLabel>
                <div className="flex flex-col gap-12 items-start">
                  <img
                    src={
                      picturePreview
                        ? picturePreview
                        : pictureData
                        ? pictureData.url
                        : PlaceholderImage
                    }
                    alt="Service Picture"
                    style={{
                      width: "164px",
                      height: "164px",
                      overflow: "hidden",
                      borderRadius: "6px",
                      objectFit: "cover",
                      border: "1px solid var(--border-color)",
                    }}
                  />
                  <Button
                    disabled={loading}
                    bsStyle="danger"
                    style={{ width: "100%" }}
                    fill
                    onClick={onImageFileUpload}
                  >
                    <Svg name="upload" className="w-4 h-4" />
                    Select Image
                  </Button>

                  <span
                    className="sub-text text-xxs text-center"
                    style={{
                      width: "164px",
                    }}
                  >
                    Only JPEG/PNG images are allowed.
                  </span>

                  <input
                    accept="image/png,image/jpeg"
                    ref={imageFileInput}
                    id="file-upload"
                    style={{ display: "none" }}
                    type="file"
                    onChange={onImageFileChange}
                  />
                </div>
              </FormGroup>

              <div className="flex flex-col gap-16" style={{ width: "100%", marginBottom: "0" }}>
                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>
                    Service Name: <span className="star">*</span>
                  </ControlLabel>
                  <LocalizableTextInput
                    disabled={loading}
                    placeholder="Enter service name"
                    value={title}
                    maxLength={100}
                    onChange={(text) => {
                      setTitle(text);
                    }}
                    onLocalizationChange={(translation) => {
                      setLocalizations((prev) => {
                        let newObj = Object.assign({}, prev);
                        Object.assign(newObj, translation);
                        return newObj;
                      });
                    }}
                    fieldName="Title"
                    localizations={localizations}
                  />
                </FormGroup>

                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>Disclaimer:</ControlLabel>
                  <LocalizableTextArea
                    disabled={loading}
                    placeholder="Enter the disclaimer (optional)"
                    value={disclaimer}
                    maxLength={500}
                    rows={5}
                    onChange={(text) => {
                      setDisclaimer(text);
                    }}
                    onLocalizationChange={(translation) => {
                      setLocalizations((prev) => {
                        let newObj = Object.assign({}, prev);
                        Object.assign(newObj, translation);
                        return newObj;
                      });
                    }}
                    fieldName="Disclaimer"
                    localizations={localizations}
                  />
                </FormGroup>

                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>Age Restrictions:</ControlLabel>
                  <LocalizableTextInput
                    disabled={loading}
                    placeholder="Enter age restrictions (optional)..."
                    value={ageRestriction}
                    maxLength={50}
                    onChange={(text) => {
                      setAgeRestriction(text);
                    }}
                    onLocalizationChange={(translation) => {
                      setLocalizations((prev) => {
                        let newObj = Object.assign({}, prev);
                        Object.assign(newObj, translation);
                        return newObj;
                      });
                    }}
                    fieldName="AgeRestriction"
                    localizations={localizations}
                  />
                </FormGroup>

                <div className="block-group">
                  <div className="gap-12 flex item-center" style={{ marginBottom: "6px" }}>
                    <Switch
                      disabled={loading}
                      onChange={() => setIsPrivate((prev) => !prev)}
                      checked={isPrivate}
                      offColor="#E6E6E6"
                      onColor="#e0eafa"
                      onHandleColor="#0051d2"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={40}
                    />
                    <div style={{ marginTop: "2px" }} className="text-xxs text-semibold">
                      IS PRIVATE SERVICE
                    </div>
                  </div>

                  <span className="text-xxs sub-text">
                    If this option is enabled, then this service won't be visible for general public
                    (i.e. regular customers).
                    <br />
                    After you mark the service as private, you can specify this service to be
                    available for specific corporate profiles and all customers with this profile
                    will be able to see that service.
                  </span>
                </div>
              </div>
            </div>

            <FormGroup style={{ marginBottom: "0" }}>
              <ControlLabel>
                Description: <span className="star">*</span>
              </ControlLabel>
              <div className="service-editor">
                <LocalizableHtmlEditor
                  disabled={loading}
                  value={description}
                  onChange={(newContent) => setDescription(newContent)}
                  onLocalizationChange={(translation) => {
                    setLocalizations((prev) => {
                      let newObj = Object.assign({}, prev);
                      Object.assign(newObj, translation);
                      return newObj;
                    });
                  }}
                  fieldName="Description"
                  localizations={localizations}
                />
              </div>
            </FormGroup>

            <div className="separator horizontal col-2span"></div>

            <div className="flex flex-col gap-6">
              <div className="grid grid-2col" style={{ columnGap: "24px", rowGap: "16px" }}>
                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>Availability (by Service Location):</ControlLabel>
                  <Select
                    name="zone-tech-select"
                    isClearable={true}
                    isDisabled={loading}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Service Locations"
                    noOptionsMessage={() => "No Service Locations"}
                    isMulti={true}
                    value={zones}
                    onChange={(opt) => {
                      setZones(opt);
                    }}
                    options={zoneOptions}
                  />
                </FormGroup>

                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>Availability (by Days):</ControlLabel>
                  <div className="flex items-center gap-6" style={{ flexWrap: "wrap" }}>
                    {daysOptions.map((d) => (
                      <div
                        key={d.value}
                        onClick={() => {
                          setSelectedDays((prev) =>
                            prev.some((s) => s.value === d.value)
                              ? prev.filter((s) => s.value !== d.value)
                              : prev.concat(d)
                          );
                        }}
                        className={`week-day-option ${
                          selectedDays.some((s) => s.value === d.value) ? "selected" : ""
                        }`}
                      >
                        {d.label}
                      </div>
                    ))}
                  </div>
                </FormGroup>
              </div>

              <p
                className="text-xxs sub-text col-2span"
                style={{
                  margin: 0,
                  paddingLeft: "4px",
                }}
              >
                Any changes made for availability do not affect already booked appointments and will
                only apply to the newly booked appointments or services added to an appointment when
                updating or completing.
              </p>
            </div>

            <div className="separator horizontal col-2span"></div>

            <div className="grid grid-2col gap-24">
              <div className="flex flex-col gap-16">
                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>
                    Technician Commission (Percentage): <span className="star">*</span>
                  </ControlLabel>
                  <NumberFormat
                    value={commission}
                    defaultValue={0}
                    decimalScale={2}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    suffix="%"
                    className="form-control"
                    inputMode="numeric"
                    isAllowed={limitCommission}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setCommission(value ? parseFloat(value) : 0);
                    }}
                  />
                </FormGroup>
              </div>

              <div className="flex flex-col gap-16">
                <FormGroup>
                  <ControlLabel>
                    Service Duration (Minutes): <span className="star">*</span>
                  </ControlLabel>
                  <NumberFormat
                    value={duration}
                    defaultValue={0}
                    decimalScale={0}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    suffix=" min"
                    className="form-control"
                    inputMode="numeric"
                    isAllowed={limitDuration}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setDuration(value ? parseInt(value) : 0);
                    }}
                  />
                </FormGroup>
              </div>

              <div className="separator horizontal col-2span"></div>

              <div className="flex flex-col gap-16 col-2span">
                <FormGroup>
                  <ControlLabel>
                    Base Price: <span className="star">*</span>
                  </ControlLabel>

                  <NumberFormat
                    value={price}
                    defaultValue={0}
                    decimalScale={2}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    prefix="$"
                    className="form-control"
                    inputMode="numeric"
                    isAllowed={limitPrice}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setPrice(value ? parseFloat(value) : 0);
                    }}
                  />
                </FormGroup>

                <PriceSchedule
                  schedule={priceSchedule}
                  disabled={loading}
                  onChange={(schedule) => setPriceSchedule(schedule)}
                />
              </div>

              <div className="separator horizontal col-2span"></div>

              <div className="flex col-2span gap-16">
                <div className="block-group" style={{ width: "50%" }}>
                  <div className="switch">
                    <FormGroup>
                      <Switch
                        disabled={loading}
                        onChange={(checked) => setReportEnabled(checked)}
                        checked={reportEnabled}
                        offColor="#E6E6E6"
                        onColor="#aac1e6"
                        onHandleColor="#0051d2"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={40}
                      />
                      <ControlLabel>Enable Service Reports</ControlLabel>
                    </FormGroup>
                  </div>
                  <span className="text-xxs sub-text">
                    If this option is enabled, then a technnician will be able to provide service
                    results and send a report PDF to a customer when completing an appointment. One
                    of the report templates should be selected to enable service reports.
                  </span>

                  <FormGroup className="flex flex-col" style={{ marginTop: "16px" }}>
                    <ControlLabel>Service Report Template:</ControlLabel>
                    <Select
                      name="services-report-select"
                      isClearable={false}
                      isDisabled={loading || !reportEnabled}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Template"
                      noOptionsMessage={() => "No Templates"}
                      isMulti={false}
                      value={reportTemplate}
                      onChange={(opt) => {
                        setReportTemplate(opt);
                      }}
                      options={reportOptions}
                    />
                  </FormGroup>
                </div>

                <div className="block-group flex flex-col gap-16" style={{ width: "50%" }}>
                  <div className="gap-12 flex item-center">
                    <Switch
                      disabled={loading}
                      onChange={() => setRequisitionRequired((prev) => !prev)}
                      checked={requisitionRequired}
                      offColor="#E6E6E6"
                      onColor="#e0eafa"
                      onHandleColor="#0051d2"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={40}
                    />
                    <div style={{ marginTop: "2px" }} className="text-xxs text-semibold">
                      REQUISITION FORM REQUIRED
                    </div>
                  </div>

                  <span className="text-xxs sub-text">
                    If this option is enabled, then a customer will have an option to upload a
                    requisition form while booking an appointment an a technnician will be required
                    to upload requisition form when completing an appointment.
                  </span>

                  <div className="gap-12 flex item-center" style={{ marginTop: "16px" }}>
                    <Switch
                      disabled={loading}
                      onChange={() => setDropOffRequired((prev) => !prev)}
                      checked={dropOffRequired}
                      offColor="#E6E6E6"
                      onColor="#e0eafa"
                      onHandleColor="#0051d2"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={40}
                    />
                    <div style={{ marginTop: "2px" }} className="text-xxs text-semibold">
                      DROP OFF LOCATION REQUIRED
                    </div>
                  </div>

                  <span className="text-xxs sub-text">
                    If this option is enabled, then a technician will need to deliver a sample for
                    this test to one of the drop off locations.
                  </span>

                  <div className="gap-12 flex item-center" style={{ marginTop: "16px" }}>
                    <Switch
                      disabled={loading}
                      onChange={() => setServiceNotesRequired((prev) => !prev)}
                      checked={serviceNotesRequired}
                      offColor="#E6E6E6"
                      onColor="#e0eafa"
                      onHandleColor="#0051d2"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={40}
                    />
                    <div style={{ marginTop: "2px" }} className="text-xxs text-semibold">
                      SERVICE NOTES REQUIRED
                    </div>
                  </div>

                  <span className="text-xxs sub-text">
                    If this option is enabled, then a technician will need to enter service notes
                    when completing an appointment. The medical notes report will be generated with
                    these notes after the appointment is completed.
                  </span>
                </div>
              </div>

              <div className="separator horizontal col-2span"></div>

              <Card
                title="Questionnaire"
                category="Add questionnaire for the customer"
                content={
                  <div className="flex flex-col gap-10">
                    {questionnaire.map((q) => (
                      <div className="question-card" key={q.id}>
                        <div className="flex flex-col gap-12 w-full">
                          <div className="text-semibold text-normal">{q.description}</div>

                          <div className="flex flex-wrap gap-6">
                            {q.options.map((opt) => (
                              <div className="question-option" key={opt.id}>
                                {opt.description}
                              </div>
                            ))}
                          </div>
                        </div>

                        <div
                          className="flex flex-col items-center gap-12 ml-auto"
                          style={{ alignSelf: "center" }}
                        >
                          <Button
                            bsStyle="default"
                            outline="true"
                            className="btn-error"
                            title="Delete Question"
                            style={{ padding: "3px 4px" }}
                            onClick={() => {
                              setQuestionnaire((prev) => prev.filter((qq) => qq.id !== q.id));
                            }}
                          >
                            <Svg name="trash" className="w-4 h-4" />
                          </Button>
                        </div>
                      </div>
                    ))}

                    {(!questionnaire || questionnaire.length === 0) && (
                      <div className="sub-text" style={{ marginBottom: "12px" }}>
                        No items...
                      </div>
                    )}

                    <div
                      className="flex flex-col"
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "7px",
                        padding: "12px 16px",
                        background: "#fafafa",
                      }}
                    >
                      <FormGroup>
                        <ControlLabel>Questionnaire:</ControlLabel>
                        <Select
                          name="questions-select"
                          isClearable={false}
                          isDisabled={loading}
                          className="react-select react-select-icon"
                          isSearchable={true}
                          placeholder="Select a question"
                          noOptionsMessage={() => "No questions..."}
                          isMulti={false}
                          value={selectedQuestion}
                          placement="top"
                          menuPlacement="top"
                          onChange={(opt) => {
                            setSelectedQuestion(opt);
                          }}
                          options={questionOptions
                            .filter((q) => questionnaire.every((qq) => qq.id !== q.id))
                            .map((q) => {
                              return { label: q.description, value: q.id, item: q };
                            })}
                          components={{
                            Option: ({ innerProps, data, isDisabled, isSelected, isFocused }) => {
                              return (
                                <div
                                  style={{ padding: "8px 12px" }}
                                  className={`custom-select-option flex flex-col items-start ${
                                    isSelected ? "selected" : ""
                                  } ${isFocused ? "focused" : ""}`}
                                  {...innerProps}
                                >
                                  <span style={{ fontSize: "13px" }}>{data.label}</span>
                                  <span style={{ fontSize: "12px", color: "#aaa" }}>
                                    {data.item.options.map((o) => o.description).join(", ")}
                                  </span>
                                </div>
                              );
                            },
                          }}
                        />
                      </FormGroup>

                      <Button
                        disabled={props.disabled || !selectedQuestion}
                        bsStyle="danger"
                        fill
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          let question = questionOptions.find(
                            (q) => q.id === selectedQuestion.value
                          );
                          if (question) {
                            setQuestionnaire((prev) => prev.concat([question]));
                            setSelectedQuestion(null);
                          }
                        }}
                        type="button"
                      >
                        <Svg name="plus" className="w-4 h-4" />
                        Add Question
                      </Button>
                    </div>
                  </div>
                }
              />
              <Card
                title="Checklist"
                category="Add checklist for the technician"
                content={
                  <div>
                    <TextListControl
                      disabled={loading}
                      onChange={(items) => {
                        setChecklist(items);
                      }}
                      textLabel="DESCRIPTION"
                      items={checklist}
                    />
                  </div>
                }
              />
            </div>

            <Button
              loading={loading}
              disabled={loading || !title || price <= 0 || duration <= 0}
              onClick={() => saveService()}
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
            >
              Save Service
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default ServiceView;
