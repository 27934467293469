import React, { Component } from "react";
import { FormGroup, ControlLabel, FormControl, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Rating from "react-rating";
import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import PhoneInput from "react-phone-number-input/input";
import Select from "react-select";
import TechSchedule from "./TechSchedule.jsx";
import TechStatisticsView from "./TechStatisticsView.jsx";
import TechAppointments from "../Appointments/AppointmentsTable.jsx";
import UserReviews from "../Users/UserReviews.jsx";
import BusinessInfoModal from "./BusinessInfoModal.jsx";
import axios from "axios";
import Loader from "../../../views/Components/Loader/Loader.jsx";
import "react-phone-number-input/style.css";
import { parseError } from "api/common.js";
import Svg from "components/Svg/Svg.jsx";

class LabTechDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      emailError: null,
      initialEmail: "",
      editProfileOn: false,
      initialFirstName: "",
      initialLastName: "",
      initialPhone: "",
      initialTechnicianType: "",
      initialGender: null,
      initialServiceCentreId: "",
      phone: "",
      value: "",
      hoursWorked: 0,
      isTechEnabled: true,
      assignLocationOn: false,
      techId: "",
      zoneOptions: [],
      zones: [],
      initialZoneIds: [],
      zoneName: "",
      gender: { value: "" },
      allServices: [],
      serviceId: "",
      serviceName: "",
      serviceValue: [],
      serviceIds: [],
      recruitmentType: { value: "" },
      options: [],
      selectedServices: [],
      services: [],
      ratingValue: 0,
      showModal: false,
      editEmailOn: false,
      serviceCentre: { value: "" },
      serviceCentresList: [],
      loading: false,
      genders: [],
      techPictureUrl: null,
      passwordSet: true,
      recruitmentTypes: [
        { label: "Commission", value: "Commission" },
        { label: "Salary", value: "Salary" },
      ],
      availability: "",
      businessInfo: null,
      businessInfoModal: false,
      deleteReason: null,
      deletedAt: null,
    };
  }

  componentDidMount() {
    const self = this;
    const techId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    this.setState({ techId: techId, loading: true });

    axios
      .get(`${process.env.REACT_APP_API_URL}/technicians/${techId}`)
      .then((res) => {
        this.setState({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          phone: res.data.phone,
          email: res.data.email,
          zones: res.data.zoneIds
            ? res.data.zoneIds.map((zId) => {
                return {
                  value: zId,
                };
              })
            : [],
          serviceIds: res.data.serviceIds,
          recruitmentType: self.state.recruitmentTypes.find(
            (s) => s.value === res.data.technicianType
          ),
          services: res.data.services || [],
          isTechEnabled: res.data.isEnabled,
          gender: { value: res.data.gender.key },
          ratingValue: res.data.rating,
          initialFirstName: res.data.firstName,
          initialLastName: res.data.lastName,
          initialPhone: res.data.phone,
          initialTechnicianType: res.data.technicianType,
          initialZoneIds: res.data.zoneIds || [],
          initialServiceCentreId: res.data.serviceCentreId,
          initialGender: res.data.gender,
          initialEmail: res.data.email,
          serviceCentre: { value: res.data.serviceCentreId },
          techPictureUrl: res.data.picture ? res.data.picture.url : null,
          passwordSet: res.data.isPasswordSet,
          availability: res.data.availability,
          deleteReason: res.data.deleteReason,
          deletedAt: res.data.deletedAt,
          businessInfo: res.data.businessInformation
            ? {
                name: res.data.businessInformation.name,
                phone: res.data.businessInformation.phone,
                gstNumber: res.data.businessInformation.gstNumber
                  ? res.data.businessInformation.gstNumber
                  : "",
                pstNumber: res.data.businessInformation.pstNumber
                  ? res.data.businessInformation.pstNumber
                  : "",
                hstNumber: res.data.businessInformation.hstNumber
                  ? res.data.businessInformation.hstNumber
                  : "",
                qstNumber: res.data.businessInformation.qstNumber
                  ? res.data.businessInformation.qstNumber
                  : "",
              }
            : null,
        });
        self.getHelperData(res.data.serviceCentreId);
      })
      .catch((err) => {
        parseError(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getHelperData(serviceCentreId) {
    let self = this;

    axios.get(`${process.env.REACT_APP_API_URL}/services`).then((res) => {
      const allServices = res.data.map((service) => ({
        value: service.id,
        label: service.title,
      }));

      self.setState({ allServices: allServices });
    });

    axios
      .get(`${process.env.REACT_APP_API_URL}/zones`)
      .then((res) => {
        const zones = res.data.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));

        let currentZones = zones.filter(
          (s) => self.state.zones.findIndex((z) => z.value === s.value) > -1
        );
        this.setState({ zoneOptions: zones, zones: currentZones });
      })
      .catch((err) => {
        parseError(err);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/service-centres`)
      .then((scRes) => {
        let scList = scRes.data.items.map((s) => {
          return { label: s.name, value: `SC#${s.id}` };
        });
        let currentCentre = scList.find((s) => s.value === serviceCentreId);

        this.setState({
          serviceCentresList: scList,
          serviceCentre: currentCentre,
        });
      })
      .catch((err) => {
        parseError(err);
      });

    // axios
    //   .get(`${process.env.REACT_APP_API_URL}/account/genders`)
    //   .then((res) => {
    //     var genders = res.data.map((s) => {
    //       return { label: s.value, value: s.key };
    //     });

    //     this.setState({
    //       genders: genders,
    //       gender: self.state.gender
    //         ? genders.find((g) => g.value === self.state.gender.value)
    //         : self.state.gender,
    //     });
    //   })
    //   .catch((err) => {
    //     parseError(err);
    //   });
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
    });
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(event.target.value) === false
      ? this.setState({
          emailError: (
            <small className="text-danger">
              Email is required and format should be <i>john@doe.com</i>.
            </small>
          ),
        })
      : this.setState({ emailError: null });
  }

  handleServiceMultiChange = (e) => {
    this.setState({ serviceValue: e });
    this.setState({ serviceId: e.value, serviceName: e.label });
  };

  handleServiceChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    this.setState({ options: value });
  };

  assignTechToZones = () => {
    this.setState({ loading: true });
    const self = this;

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/technicians/${self.state.techId}/zones`,
        self.state.zones.map((z) => z.value)
      )
      .then((res) => {
        self.setState({
          assignLocationOn: false,
          loading: false,
          initialZoneIds: self.state.zones.map((z) => z.value),
        });
        Swal.fire({
          title: "The technician has been successfully assigned to service location.",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        let error = parseError(err);
        self.setState({
          loading: false,
          assignLocationOn: false,
          zones: self.state.zoneOptions.filter((z) => self.state.initialZoneIds.includes(z.value)),
        });
        Swal.fire(error, "", "error");
      });
  };

  assignService = () => {
    this.setState({ loading: true });

    const self = this;
    const serviceIds = this.state.selectedServices.map((service) => service.value);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/technicians/${self.state.techId}/services`,
        serviceIds
      )
      .then((res) => {
        self.setState({
          services: res.data.services || [],
          showModal: false,
          loading: false,
        });

        Swal.fire({
          title: "The selected service has been assigned to the technician.",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        let error = parseError(err);
        self.setState({ loading: false });
        Swal.fire(error, "", "error");
      });
  };

  unassignService = (service) => {
    const self = this;

    Swal.fire({
      title: "Are you sure you want to remove the service assignment from the technician?",
      text: "Technician will not be be assigned to any appointments with the selected service.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Remove Assignment",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/technicians/${this.state.techId}/services/${service}`
          )
          .then((res) => {
            self.setState({
              services: res.data.services || [],
              showModal: false,
              loading: false,
            });

            Swal.fire({
              title: "The selected service assignment has been removed from the technician.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            self.setState({ loading: true });
            Swal.fire(error, "", "error");
          });
      }
    });
  };

  onSaveProfileDetails = () => {
    const self = this;
    this.setState({ loading: true });

    const data = {
      id: self.state.techId,
      technicianType: self.state.recruitmentType.value,
      picture: null,
      firstName: self.state.firstName,
      lastName: self.state.lastName,
      phone: self.state.phone,
      // gender: {
      //   key: self.state.gender.value,
      //   value: self.state.gender.label,
      // },
      serviceCentreId: self.state.serviceCentre.value,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/technicians`, data)
      .then((res) => {
        self.setState({
          loading: false,
          editProfileOn: !self.state.editProfileOn,
          initialFirstName: data.firstName,
          initialLastName: data.lastName,
          initialPhone: data.phone,
          initialGender: data.gender,
          initialServiceCentreId: data.serviceCentreId,
          initialTechnicianType: data.technicianType,
          passwordSet: res.data.isPasswordSet,
        });

        Swal.fire("Profile information has been updated successfully.", "", "success");
      })
      .catch((err) => {
        let error = parseError(err);
        self.cancelChanges();
        Swal.fire(error, "", "error");
      });
  };

  saveEmailChanges = () => {
    const self = this;
    this.setState({ loading: true });

    const data = {
      technicianId: self.state.techId,
      newEmail: self.state.email,
    };

    axios
      .patch(`${process.env.REACT_APP_API_URL}/technicians`, data)
      .then((res) => {
        Swal.fire("Email address has been updated.", "", "success");
        this.setState({
          loading: false,
          editEmailOn: !this.state.editEmailOn,
          email: data.newEmail,
          initialEmail: data.newEmail,
        });
      })
      .catch((err) => {
        let error = parseError(err);
        self.setState({
          loading: false,
          editEmailOn: !this.state.editEmailOn,
          email: self.state.initialEmail,
        });
        Swal.fire(error, "", "error");
      });
  };

  cancelChanges = () => {
    let self = this;
    this.setState({
      loading: false,
      firstName: self.state.initialFirstName,
      lastName: self.state.initialLastName,
      phone: self.state.initialPhone,
      recruitmentType: self.state.recruitmentTypes.find(
        (s) => s.value === self.state.initialTechnicianType
      ),
      editProfileOn: !self.state.editProfileOn,
      // gender: self.state.genders.find((s) => s.value === self.state.initialGender.key),
      serviceCentre: self.state.serviceCentresList.find(
        (s) => s.value === self.state.initialServiceCentreId
      ),
    });
  };

  cancelAssignLocation = () => {
    const zones = this.state.initialZoneIds
      ? this.state.zoneOptions.filter((s) => this.state.initialZoneIds.includes(s.value))
      : [];
    this.setState({
      zones: zones,
      assignLocationOn: !this.state.assignLocationOn,
    });
  };

  disableTech = async () => {
    const self = this;

    Swal.fire({
      title: "Are you sure you want to disable this account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Disable Account",
    }).then((result) => {
      if (result.isConfirmed) {
        self.setState({ loading: true });

        axios
          .delete(`${process.env.REACT_APP_API_URL}/technicians/${self.state.techId}/disable`)
          .then((res) => {
            self.setState({ isTechEnabled: false, loading: false });
            Swal.fire({
              title: "Account has been disabled.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            self.setState({ loading: false });
            Swal.fire(error, "", "error");
          });
      }
    });
  };

  deleteTech = async () => {
    const self = this;

    Swal.fire({
      title: "Are you sure you want to delete this technician's account?",
      text: "You can only delete the techncian's account if they do not have any not completed appointments assigned to them or any unpaid commissions. Otherwise, you can only disable this account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Account",
    }).then((result) => {
      if (result.isConfirmed) {
        self.setState({ loading: true });

        axios
          .delete(`${process.env.REACT_APP_API_URL}/technicians/${self.state.techId}`)
          .then((res) => {
            self.setState({ isTechEnabled: false, loading: false });
            Swal.fire({
              title: "Account has been deleted.",
              icon: "success",
              showCancelButton: false,
            }).then(() => {
              this.props.history.push("/admin/lab-techs");
            });
          })
          .catch((err) => {
            let error = parseError(err);
            self.setState({ loading: false });
            Swal.fire("Account Delete Error", error, "error");
          });
      }
    });
  };

  enableTech = async () => {
    const self = this;

    Swal.fire({
      title: "Are you sure you want to enable this account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0051d2",
      cancelButtonColor: "#565656",
      confirmButtonText: "Enable Account",
    }).then((result) => {
      if (result.isConfirmed) {
        self.setState({ loading: true });

        axios
          .put(`${process.env.REACT_APP_API_URL}/technicians/${self.state.techId}/enable`)
          .then((res) => {
            Swal.fire({
              title: "Account has been enabled.",
              icon: "success",
              showCancelButton: false,
            }).then(() => {
              self.setState({ isTechEnabled: true, loading: false });
            });
          })
          .catch((err) => {
            let error = parseError(err);
            self.setState({ loading: false });
            Swal.fire(error, "", "error");
          });
      }
    });
  };

  mapAvailabilityStatus = () => {
    switch (this.state.availability) {
      case "Available":
        return "Online";
      case "Busy":
        return "Busy";
      default:
        return "Offline";
    }
  };

  mapRegistrationStatus = () => {
    if (this.state.deletedAt !== null) {
      return "Deleted";
    }

    if (this.state.isTechEnabled) {
      return this.state.passwordSet ? "Enabled" : "Not Signed Up";
    } else {
      return "Disabled";
    }
  };

  render() {
    return (
      <div className="main-content flex flex-col gap-24" style={{ padding: "24px" }}>
        <div className="flex items-center" style={{ marginRight: "auto" }}>
          <Button
            simple
            icon
            style={{ padding: "0", marginBottom: "-12px", marginRight: "auto" }}
            onClick={() => {
              this.props.history.go(-1);
            }}
          >
            <Svg name="chevron-left" className="w-6 h-6" />
            <span className="text-bold">Go Back</span>
          </Button>
        </div>

        {this.state.loading && (
          <div className="flex flex-col items-center justify-center">
            <Loader title="Please wait..." />
          </div>
        )}

        <Card
          content={
            <div className="flex flex-col">
              <div className="flex flex-col items-center gap-12" style={{ marginTop: "32px" }}>
                {this.state.techPictureUrl && (
                  <img
                    alt="Profile Picture"
                    src={this.state.techPictureUrl}
                    className="round"
                    style={{
                      width: "80px",
                      height: "80px",
                      overflow: "hidden",
                      border: "1px solid var(--border-color)",
                      objectFit: "cover",
                    }}
                  />
                )}
                {!this.state.techPictureUrl && (
                  <Svg name="profile" style={{ width: "80px", height: "80px" }} />
                )}
                <div className="text-xxl text-bold">{`${this.state.firstName} ${this.state.lastName}`}</div>
                <div className="sub-text text-xs text-regular" style={{ marginTop: "-6px" }}>
                  {this.state.email}
                </div>

                <div className="flex items-center gap-12">
                  <div
                    className={`badge ${
                      this.state.availability === "Available"
                        ? "success"
                        : this.state.availability === "Busy"
                        ? "warning"
                        : "error"
                    }`}
                  >
                    {this.mapAvailabilityStatus()}
                  </div>
                  {" • "}
                  {this.state.businessInfo && (
                    <>
                      <div
                        title="Independent Practitioner"
                        className="flex items-center gap-12 badge success cursor-pointer"
                        onClick={() => this.setState({ businessInfoModal: true })}
                      >
                        <span>Independent Practitioner</span>
                        <Svg name="eye" style={{ marginRight: "-2px" }} className="w-3 h-3" />
                      </div>
                      {" • "}
                    </>
                  )}
                  <div
                    title={
                      this.state.passwordSet
                        ? ""
                        : "The user has not changed their temporary password and hasn't tried to login into the system yet."
                    }
                    className="sub-text text-xs"
                  >
                    {this.mapRegistrationStatus()}
                  </div>
                  <div className="sub-text text-xs text-regular">
                    {" • "}
                    <Rating
                      style={{ marginLeft: "4px" }}
                      initialRating={this.state.ratingValue}
                      emptySymbol="fa fa-star-o theme-text"
                      fullSymbol="fa fa-star theme-text"
                      readonly
                    />
                    {this.state.ratingValue > 0 && (
                      <span className="sub-text">{this.state.ratingValue.toFixed(1)}</span>
                    )}
                  </div>
                </div>

                {this.state.deletedAt === null && (
                  <div className="flex gap-10 item-center" style={{ marginTop: "16px" }}>
                    <Button
                      disabled={this.state.loading}
                      bsStyle="danger"
                      outline="true"
                      onClick={
                        this.state.isTechEnabled
                          ? () => this.disableTech()
                          : () => this.enableTech()
                      }
                    >
                      {this.state.isTechEnabled ? "Disable Account" : "Enable Account"}
                    </Button>
                    <Button
                      disabled={this.state.loading}
                      bsStyle="danger"
                      outline="true"
                      title="Delete Account"
                      onClick={() => this.deleteTech()}
                    >
                      <Svg name="trash" className="w-4 h-4" />
                      Delete Account
                    </Button>
                  </div>
                )}
              </div>

              <div className="separator horizontal" style={{ margin: "24px 0" }}></div>

              <div className="grid grid-2col gap-12">
                <div className="tech-profile">
                  <Card
                    title={
                      <div className="flex items-center">
                        <div className="title">Profile Details</div>
                        {this.state.deletedAt === null && (
                          <Button
                            bsStyle="danger"
                            disabled={
                              this.state.editProfileOn ||
                              this.state.loading ||
                              this.state.deletedAt !== null
                            }
                            fill
                            style={{ marginLeft: "auto" }}
                            onClick={(e) => this.setState({ editProfileOn: true })}
                          >
                            <Svg name="edit" className="w-4 h-4" />
                            Edit
                          </Button>
                        )}
                      </div>
                    }
                    content={
                      <div className="flex flex-col">
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              label: "First name",
                              type: "text",
                              maxLength: "32",
                              bsClass: "form-control",
                              placeholder: "First Name",
                              value: this.state.firstName,
                              onChange: (e) =>
                                this.setState({
                                  firstName: e.target.value,
                                }),
                              disabled: !this.state.editProfileOn,
                            },
                          ]}
                        />
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              label: "Last name",
                              type: "text",
                              maxLength: "32",
                              bsClass: "form-control",
                              placeholder: "Last Name",
                              value: this.state.lastName,
                              onChange: (e) =>
                                this.setState({
                                  lastName: e.target.value,
                                }),
                              disabled: !this.state.editProfileOn,
                            },
                          ]}
                        />
                        <div className="form-group phone-input">
                          <ControlLabel>
                            Phone Number: <span className="star">*</span>
                          </ControlLabel>
                          <PhoneInput
                            disabled={!this.state.editProfileOn}
                            maxLength="16"
                            max="10"
                            defaultCountry="CA"
                            extension="true"
                            value={this.state.phone}
                            onChange={(value) => {
                              this.setState({ phone: value });
                            }}
                            onBlur={(event) => {
                              event.target.value === ""
                                ? this.setState({
                                    phoneError: (
                                      <small className="text-danger">
                                        Phone number is required.
                                      </small>
                                    ),
                                  })
                                : this.setState({ phoneError: null });
                            }}
                            required
                          />
                          {this.state.phoneError}
                        </div>
                        {/*
                        <div className="form-group">
                          <ControlLabel>Gender:</ControlLabel>
                          <Select
                            name="gender-select"
                            isDisabled={!this.state.editProfileOn}
                            isClearable={false}
                            className="react-select react-select-icon"
                            isSearchable={true}
                            placeholder="Select Gender"
                            noOptionsMessage={(inp) => "No Genders Loaded"}
                            isMulti={false}
                            value={this.state.gender}
                            onChange={(opt) => this.setState({ gender: opt })}
                            options={this.state.genders}
                            formatGroupLabel={(data) => (
                              <div className="flex items-center text-theme font-semibold">
                                {data.label}
                              </div>
                            )}
                          />
                        </div>
                        */}
                        <div className="form-group">
                          <ControlLabel>Recruitment Type:</ControlLabel>
                          <Select
                            name="recruitment-select"
                            isDisabled={!this.state.editProfileOn}
                            isClearable={false}
                            className="react-select react-select-icon"
                            isSearchable={true}
                            placeholder="Select Recruitment Type"
                            isMulti={false}
                            value={this.state.recruitmentType}
                            onChange={(opt) => {
                              this.setState({ recruitmentType: opt });
                            }}
                            options={this.state.recruitmentTypes}
                            formatGroupLabel={(data) => (
                              <div className="flex items-center text-theme font-semibold">
                                {data.label}
                              </div>
                            )}
                          />
                        </div>
                        <div className="form-group">
                          <ControlLabel>Service Centre:</ControlLabel>
                          <Select
                            name="service-centre-select"
                            isClearable={false}
                            isDisabled={!this.state.editProfileOn}
                            className="react-select react-select-icon"
                            isSearchable={false}
                            placeholder="Select Technician's Service Centre"
                            noOptionsMessage={(inp) => "No Service Centres"}
                            isMulti={false}
                            value={this.state.serviceCentre}
                            onChange={(opt) => {
                              this.setState({ serviceCentre: opt });
                            }}
                            options={this.state.serviceCentresList}
                            formatGroupLabel={(data) => (
                              <div className="flex items-center text-theme font-semibold">
                                {data.label}
                              </div>
                            )}
                          />
                        </div>

                        {this.state.editProfileOn ? (
                          <div className="flex items-center gap-10">
                            <Button
                              disabled={this.state.loading}
                              bsStyle="danger"
                              outline="true"
                              style={{ marginLeft: "auto" }}
                              onClick={() => this.cancelChanges()}
                            >
                              Cancel
                            </Button>
                            <Button
                              loading={this.state.loading}
                              bsStyle="danger"
                              fill
                              disabled={
                                !this.state.firstName ||
                                !this.state.lastName ||
                                !this.state.phone ||
                                !this.state.recruitmentType ||
                                !this.state.serviceCentre ||
                                this.state.loading
                              }
                              onClick={() => this.onSaveProfileDetails()}
                            >
                              Save Changes
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    }
                  />
                </div>

                <div className="tech-additional-details flex flex-col gap-12">
                  <Card
                    title={
                      <div className="flex items-center">
                        <div>Email Address</div>
                        {this.state.deletedAt === null && (
                          <Button
                            bsStyle="danger"
                            disabled={this.state.editEmailOn || this.state.loading}
                            fill
                            style={{ marginLeft: "auto" }}
                            onClick={(e) => this.setState({ editEmailOn: !this.state.editEmailOn })}
                          >
                            <Svg name="edit" className="w-4 h-4" />
                            Edit
                          </Button>
                        )}
                      </div>
                    }
                    content={
                      <div style={{ position: "relative" }}>
                        <FormGroup>
                          <FormControl
                            style={{ marginBottom: 20 }}
                            type="text"
                            name="email"
                            maxLength="100"
                            placeholder="Email Address"
                            value={this.state.email}
                            onChange={(event) => this.handleEmailChange(event)}
                            disabled={!this.state.editEmailOn}
                          />
                          {this.state.editEmailOn && (
                            <div className="flex items-center gap-10">
                              <Button
                                disabled={!this.state.editEmailOn || this.state.loading}
                                bsStyle="danger"
                                outline="true"
                                style={{ marginLeft: "auto" }}
                                onClick={() =>
                                  this.setState({
                                    email: this.state.initialEmail,
                                    editEmailOn: !this.state.editEmailOn,
                                  })
                                }
                              >
                                Cancel
                              </Button>
                              <Button
                                loading={this.state.loading}
                                disabled={!this.state.editEmailOn || this.state.loading}
                                bsStyle="danger"
                                fill
                                onClick={() => this.saveEmailChanges()}
                              >
                                Save Changes
                              </Button>
                            </div>
                          )}
                        </FormGroup>
                      </div>
                    }
                  />

                  <Card
                    title={
                      <div className="flex items-center">
                        <div>Assigned Service Location</div>
                        {this.state.deletedAt === null && (
                          <Button
                            bsStyle="danger"
                            disabled={this.state.assignLocationOn || this.state.loading}
                            fill
                            style={{ marginLeft: "auto" }}
                            onClick={(e) =>
                              this.setState({ assignLocationOn: !this.state.assignLocationOn })
                            }
                          >
                            <Svg name="edit" className="w-4 h-4" />
                            Edit
                          </Button>
                        )}
                      </div>
                    }
                    content={
                      <div className="form-group" style={{ position: "relative" }}>
                        <Select
                          name="zone-tech-select"
                          isClearable={false}
                          isDisabled={!this.state.assignLocationOn}
                          className="react-select react-select-icon"
                          isSearchable={false}
                          placeholder="Select Service Locations"
                          noOptionsMessage={() => "No Service Locations"}
                          isMulti={true}
                          value={this.state.zones}
                          onChange={(opt) => {
                            this.setState({ zones: opt });
                          }}
                          options={this.state.zoneOptions}
                        />

                        {this.state.assignLocationOn && (
                          <div className="flex items-center gap-10" style={{ marginTop: "12px" }}>
                            <Button
                              disabled={!this.state.assignLocationOn || this.state.loading}
                              bsStyle="danger"
                              outline="true"
                              style={{ marginLeft: "auto" }}
                              onClick={() => this.cancelAssignLocation()}
                            >
                              Cancel
                            </Button>
                            <Button
                              loading={this.state.loading}
                              disabled={
                                !this.state.assignLocationOn ||
                                this.state.loading ||
                                this.state.zones.length === 0
                              }
                              bsStyle="danger"
                              fill
                              onClick={() => this.assignTechToZones()}
                            >
                              Assign
                            </Button>
                          </div>
                        )}
                      </div>
                    }
                  />

                  <Card
                    title={
                      <div className="flex items-center">
                        <div>Assigned Services</div>
                        {this.state.deletedAt === null && (
                          <Button
                            bsStyle="danger"
                            disabled={this.state.loading}
                            fill
                            style={{ marginLeft: "auto" }}
                            onClick={() => this.setState({ showModal: true, selectedServices: [] })}
                          >
                            <Svg name="plus" className="w-4 h-4" />
                            Assign
                          </Button>
                        )}
                      </div>
                    }
                    content={
                      <div className="flex flex-col gap-4" style={{ marginTop: "-2px" }}>
                        {this.state.services &&
                          this.state.services.map((service) => {
                            return (
                              <div
                                key={service.serviceId}
                                className="flex items-center gap-16"
                                style={{
                                  borderBottom: "1px solid var(--border-color)",
                                  paddingBottom: "4px",
                                }}
                              >
                                <span className="text-semibold text-xs">{service.title}</span>
                                {this.state.deletedAt === null && (
                                  <Button
                                    disabled={this.state.loading}
                                    style={{ marginLeft: "auto" }}
                                    onClick={() => this.unassignService(service.serviceId)}
                                    bsStyle="danger"
                                    simple
                                    icon
                                  >
                                    <Svg name="trash" className="w-4 h-4" />
                                  </Button>
                                )}
                              </div>
                            );
                          })}
                        {(!this.state.services || this.state.services.length === 0) && (
                          <div className="sub-text">No services assigned.</div>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          }
        />

        {this.state.techId && this.state.firstName && (
          <Card
            title="Working Schedule"
            content={
              <TechSchedule
                passwordSet={this.state.passwordSet}
                techName={`${this.state.firstName} ${this.state.lastName}`}
                techId={this.state.techId}
                isTechEnabled={this.state.isTechEnabled}
                isDeletedAccount={this.state.deletedAt !== null}
              />
            }
          />
        )}

        {this.state.techId && this.state.firstName && (
          <Card
            title="Statistics"
            content={
              <TechStatisticsView
                techId={this.state.techId}
                techName={`${this.state.firstName} ${this.state.lastName}`}
                techType={this.state.recruitmentType ? this.state.recruitmentType.value : ""}
              />
            }
          />
        )}

        {this.state.techId && (
          <Card
            title="Assigned Appointments"
            content={<TechAppointments pageSize={8} techId={this.state.techId} />}
          />
        )}

        {this.state.techId && (
          <Card
            title="Reviews"
            content={
              <UserReviews
                onUpdate={(userData) => {
                  this.setState({ ratingValue: userData.rating });
                }}
                pageSize={8}
                techId={this.state.techId}
              />
            }
          />
        )}

        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          centered="true"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Assign Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card
              content={
                <Select
                  isClearable={false}
                  isMulti
                  options={this.state.allServices.filter(
                    (s) => this.state.services.filter((ss) => ss.serviceId === s.value).length === 0
                  )}
                  onChange={(value) => this.setState({ selectedServices: value })}
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select Services"
                  name="multipleSelect"
                  closeMenuOnSelect={false}
                  isOptionDisabled={(option) => option.isdisabled}
                />
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center gap-10">
              <Button
                outline="true"
                bsStyle="danger"
                disabled={this.state.loading}
                style={{ marginLeft: "auto" }}
                onClick={() => this.setState({ showModal: false, selectedServices: [] })}
              >
                Cancel
              </Button>
              <Button
                loading={this.state.loading}
                disabled={this.state.loading || this.state.selectedServices.length == 0}
                bsStyle="danger"
                fill
                onClick={() => this.assignService()}
              >
                Assign Services
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <BusinessInfoModal
          show={this.state.businessInfoModal}
          businessInfo={this.state.businessInfo}
          onHide={() => this.setState({ businessInfoModal: false })}
        />
      </div>
    );
  }
}

export default LabTechDetails;
