import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const BusinessInfoModal = ({show, businessInfo, onHide}) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [gst, setGst] = useState("");
    const [pst, setPst] = useState("");
    const [hst, setHst] = useState("");
    const [qst, setQst] = useState("");

    useEffect(() => {
        if (show) {
            setName(businessInfo.name ? businessInfo.name : "");
            setPhone(businessInfo.phone ? businessInfo.phone : "");
            setGst(businessInfo.gstNumber ? businessInfo.gstNumber : "");
            setPst(businessInfo.pstNumber ? businessInfo.pstNumber : "");
            setHst(businessInfo.hstNumber ? businessInfo.hstNumber : "");
            setQst(businessInfo.qstNumber ? businessInfo.qstNumber : "");
        }
        else {
            setName("");
            setPhone("");
            setGst("");
            setPst("");
            setQst("");
            setHst("");
        }
    }, [show])
    return (
        <Modal show={show} onHide={() => onHide()} 
            style={{
                display: "flex",
                justifyContent: 'center',
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Business Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col items-center gap-1">
                        <div className="text-xxl text-bold">{name}</div>
                        <div className="sub-text text-xs text-regular">({phone})</div>
                    </div>
                    <div className="flex flex-col items-center gap-1">
                        <div className="text-xxl text-bold">Tax Information</div>
                        <div>
                            {gst && 
                                <div className="sub-text text-xs text-regular flex gap-12">
                                    <span className="text-bold">GST Number:</span><span>{gst}</span>
                                </div>
                            }
                            {pst && 
                                <div className="sub-text text-xs text-regular flex gap-12">
                                    <span className="text-bold">PST Number:</span><span>{pst}</span>
                                </div>
                            }
                            {hst && 
                                <div className="sub-text text-xs text-regular flex gap-12">
                                    <span className="text-bold">HST Number:</span><span>{hst}</span>
                                </div>
                            }
                            {qst && 
                                <div className="sub-text text-xs text-regular flex gap-12">
                                    <span className="text-bold">QST Number:</span><span>{qst}</span>    
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default BusinessInfoModal;