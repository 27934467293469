import React, { useState, useEffect } from "react";
import Card from "components/Card/Card.jsx";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { FormGroup, ControlLabel } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import Switch from "react-switch";
import Select from "react-select";
import PriceModal from "./PriceModal";
import Swal from "sweetalert2";

const PriceSchedule = (props) => {
  const localizer = momentLocalizer(moment);
  const [useCommonSchedule, setUseCommonSchedule] = useState(
    props.schedule ? props.schedule.useCommonSchedule || true : true
  );
  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState({ value: "" });
  const [priceList, setPriceList] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [schedule, setSchedule] = useState(
    props.schedule || {
      useCommonSchedule: true,
      zoneSchedules: {},
      commonSchedule: [],
    }
  );

  useEffect(() => {
    if (zones.length === 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/zones`)
        .then((res) => {
          const items = res.data.map((serv) => {
            return { label: serv.name, value: serv.id };
          });
          setZones(items);
          if (items.length > 0) {
            setZone(items[0]);
          }
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (props.schedule) {
      setSchedule(props.schedule);
      setUseCommonSchedule(props.schedule.useCommonSchedule);
      switchModeOrZone(props.schedule);
    }
  }, [props.schedule]);

  useEffect(() => {
    switchModeOrZone();
  }, [useCommonSchedule, zone]);

  const onScheduleUpdated = (updatedSchedule) => {
    if (props.onChange) {
      props.onChange(updatedSchedule);
    }
  };

  const onScheduleModeChange = () => {
    const newSchedule = Object.assign({}, schedule);
    newSchedule.useCommonSchedule = !newSchedule.useCommonSchedule;
    setSchedule(newSchedule);
    setUseCommonSchedule(newSchedule.useCommonSchedule);
    onScheduleUpdated(newSchedule);
  };

  const switchModeOrZone = (updatedSchedule = null) => {
    let sourceSchedule = updatedSchedule || schedule;

    var displayPriceList = sourceSchedule.useCommonSchedule
      ? sourceSchedule.commonSchedule || []
      : sourceSchedule.zoneSchedules
      ? zone.value
        ? sourceSchedule.zoneSchedules[zone.value] || []
        : []
      : [];

    let resultList = (displayPriceList || []).map((scItem, ind) => {
      let start = moment(scItem.startTime.hour + ":" + scItem.startTime.minute, ["H:mm"]).day(
        scItem.dayOfWeek
      );
      let end = moment(scItem.endTime.hour + ":" + scItem.endTime.minute, ["H:mm"]).day(
        scItem.dayOfWeek
      );

      return {
        id: ind + 1,
        title: `${new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          signDisplay: "never",
        }).format(scItem.percentage)}%`,
        dayOfWeek: scItem.dayOfWeek,
        start: start.toDate(),
        end: end.toDate(),
        percentage: scItem.percentage,
        allDay: false,
        color: "default",
      };
    });

    setPriceList(resultList);
  };

  const mapPriceModel = (item) => {
    return {
      startTime: {
        hour: parseInt(moment(item.start).format("H")),
        minute: parseInt(moment(item.start).format("m")),
      },
      endTime: {
        hour: parseInt(moment(item.end).format("H")),
        minute: parseInt(moment(item.end).format("m")),
      },
      percentage: parseFloat(item.percentage),
      dayOfWeek: item.dayOfWeek,
    };
  };

  const onEntryClick = (item) => {
    setSelectedEntry(item);
  };

  const onTimeIntervalSelected = (slotInfo) => {
    let start = moment(slotInfo.start);
    let end = moment(slotInfo.end);

    if (!start.isSame(end, "date")) {
      end = start.endOf("day");
      slotInfo.end = end.toDate();
    }

    let collisionItems = priceList.filter((ep) => {
      return ep.start < slotInfo.end && slotInfo.start < ep.end;
    });

    if (collisionItems.length > 0) {
      Swal.fire("Price Conflict", "You cannot have two prices with overlapping times.", "error");
      return;
    }

    setSelectedEntry({
      dayOfWeek: moment(slotInfo.start).format("dddd"),
      start: slotInfo.start,
      end: slotInfo.end,
      percentage: 100.0,
    });
  };

  const onItemRemoved = (item) => {
    let updatedList = priceList.filter((s) => s.id !== item.id);
    setPriceList(updatedList);
    onPriceListChanged(updatedList);
    setSelectedEntry(null);
  };

  const onPriceConfirmed = (data) => {
    let updatedList = [];

    if (data.id) {
      updatedList = priceList.map((p) => {
        if (p.id === data.id) {
          let pp = Object.assign({}, p);
          pp.percentage = data.percentage;
          pp.title = `${new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            signDisplay: "never",
          }).format(data.percentage)}%`;
          pp.start = data.start;
          pp.end = data.end;
          return pp;
        } else {
          return p;
        }
      });
    } else {
      updatedList = [
        ...priceList,
        {
          id: priceList.length + 1,
          title: `${new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            signDisplay: "never",
          }).format(data.percentage)}%`,
          dayOfWeek: data.dayOfWeek,
          start: data.start,
          end: data.end,
          percentage: data.percentage,
          allDay: false,
          color: "default",
        },
      ];
    }

    setPriceList(updatedList);
    onPriceListChanged(updatedList);
    setSelectedEntry(null);
  };

  const onPriceListChanged = (newList) => {
    const newSchedule = {
      useCommonSchedule: schedule.useCommonSchedule,
      commonSchedule: schedule.commonSchedule || [],
      zoneSchedules: schedule.zoneSchedules || {},
    };

    if (useCommonSchedule) {
      newSchedule.commonSchedule = newList.map((s) => mapPriceModel(s));
    } else {
      schedule.zoneSchedules[zone.value] = newList.map((s) => mapPriceModel(s));
    }

    setSchedule(newSchedule);
    onScheduleUpdated(newSchedule);
  };

  return (
    <Card
      title={
        <div className="flex items-center gap-16">
          <div className="title flex flex-col">
            <span>Price Schedule</span>
            <span className="sub-text text-xxs font-regular" style={{ lineHeight: "16px" }}>
              You can select to use a single price schedule for all service locations or configure
              separate price scheduling for each of the service locations.
              <br /> Please note that the Price Schedule does not apply for corporate services that
              have specific price set for them.
            </span>
          </div>
        </div>
      }
      content={
        <div className="flex flex-col gap-16">
          <div className="flex gap-24 items-end" style={{ position: "relative", zIndex: "5" }}>
            <FormGroup
              className="form-group"
              style={{ maxWidth: "340px", width: "100%", marginBottom: "0" }}
            >
              <ControlLabel>SERVICE LOCATION:</ControlLabel>
              <Select
                isDisabled={props.disabled || false || useCommonSchedule}
                classNamePrefix="react-select"
                name="zone-select"
                value={zone}
                isMulti={false}
                isClearable={false}
                onValueClick={(e) => e.stopPropogation()}
                placeholder={"Select service location..."}
                onChange={(value) => setZone(value)}
                options={zones}
              />
            </FormGroup>

            <div className="gap-12 flex items-center" style={{ marginBottom: "10px" }}>
              <div style={{ marginTop: "2px" }} className="text-xxs text-semibold">
                SINGLE SCHEDULE FOR ALL LOCATIONS
              </div>
              <Switch
                disabled={props.disabled || false}
                onChange={() => onScheduleModeChange()}
                checked={useCommonSchedule}
                offColor="#E6E6E6"
                onColor="#e0eafa"
                onHandleColor="#0051d2"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={40}
              />
            </div>
          </div>

          <div className="separator horizontal"></div>

          <div
            className="flex flex-col"
            style={{ position: "relative", height: "600px", padding: 0, margin: "0 -1px" }}
          >
            <Calendar
              selectable={schedule.useCommonSchedule || zone.value !== ""}
              selectedEvent={selectedEntry}
              localizer={localizer}
              events={priceList}
              defaultView="week"
              step={15}
              toolbar={false}
              formats={{
                dayFormat: (date) => {
                  return moment(date).format("ddd");
                },
              }}
              timeslots={4}
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={new Date()}
              onSelectEvent={(event) => onEntryClick(event)}
              views={["week"]}
              onSelectSlot={(slotInfo) => onTimeIntervalSelected(slotInfo)}
              eventPropGetter={() => {
                return {
                  className: "rbc-event-default",
                };
              }}
            />
          </div>

          <PriceModal
            onDelete={onItemRemoved}
            onConfirm={onPriceConfirmed}
            show={selectedEntry != null}
            onHide={() => setSelectedEntry(null)}
            data={selectedEntry}
          />
        </div>
      }
    />
  );
};

export default PriceSchedule;
