import React, { useState } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import showPass from "../../assets/img/show-icon.svg";
import hidePass from "../../assets/img/hide-icon.svg";
import Card from "components/Card/Card.jsx";
import Swal from "sweetalert2";
import Button from "components/CustomButton/CustomButton.jsx";
import { login, setSession, verifyCode } from "../../api/auth";
import Svg from "components/Svg/Svg";
import { parseError } from "api/common";

const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [codeRequired, setCodeRequired] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [userId, setUserId] = useState("");

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailValid = validateEmail(email);

    if (!emailValid) {
      setEmailError("Email is required and format should be john@doe.com.");
    } else {
      setEmailError("");
    }
  };

  const performLogin = () => {
    const self = this;
    setLoading(true);

    login(email, password)
      .then((res) => {
        if (res.status === 202) {
          setLoading(false);
          setCodeRequired(true);
          setUserId(res.data.userId);
          setSessionId(res.data.session);
        } else {
          setSession(res.data);
          window.location.href = "/";
        }
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Login Error", error, "error");
      });
  };

  const confirm2FA = () => {
    const self = this;
    setLoading(true);

    verifyCode(sessionId, userId, smsCode)
      .then((res) => {
        setSession(res.data);
        window.location.href = "/";
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Unable To Verify Code", error, "error");
      });
  };

  const showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPasswordType(passwordType === "input" ? "password" : "input");
  };

  const handleStep = () => {
    if (codeRequired) {
      confirm2FA();
      return;
    }

    performLogin();
  };

  const onEnter = (event) => {	
    if (event.key === "Enter"){	
      event.preventDefault();	
      event.stopPropagation();	
      handleStep();	
    }	
  }

  return (
    <div className="flex flex-col" style={{ width: "90%", maxWidth: "400px", margin: "0 auto" }}>
      <Card
        style={{ padding: "16px 0px" }}
        className="w-full"
        content={
          <div className="flex flex-col">
            <Svg name="avvy-vertical" style={{ height: "120px", marginBottom: "32px" }} />
            <FormGroup>
              <ControlLabel>EMAIL ADDRESS</ControlLabel>
              <FormControl
                placeholder="Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailChange}
                name="email"
                onKeyDown={onEnter}
                required
              />
              <span className="text-danger text-xxs" style={{ marginTop: "6px" }}>
                {emailError}
              </span>
            </FormGroup>
            <FormGroup style={{ position: "relative" }}>
              <ControlLabel>PASSWORD</ControlLabel>
              <FormControl
                autoComplete="off"
                placeholder="Password"
                type={passwordType}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={onEnter}
                required
              />
              {passwordType === "input" ? (
                <img className="show-icon" src={hidePass} onClick={showHide} />
              ) : (
                <img className="show-icon" src={showPass} onClick={showHide} />
              )}
            </FormGroup>

            {codeRequired && (
              <React.Fragment>
                <div className="separator horizontal" style={{ margin: "16px 0" }}></div>
                <FormGroup>
                  <ControlLabel>
                    SMS CODE: <span className="star">*</span>
                  </ControlLabel>
                  <FormControl
                    disabled={loading}
                    placeholder="Enter SMS Verification Code"
                    type="text"
                    name="smsCode"
                    maxLength="6"
                    value={smsCode}
                    onChange={(event) => {
                      setSmsCode(event.target.value);
                    }}
                    onKeyDown={onEnter}
                  />
                </FormGroup>
                <div className="sub-text text-xxs text-center">
                  We have sent a verification code to your phone number.
                  <br />
                  Please enter this code below to confirm your identity.
                </div>
              </React.Fragment>
            )}
          </div>
        }
        legend={
          <div className="flex flex-col items-center">
            <Button
              onClick={() => handleStep()}
              bsStyle="danger"
              fill
              loading={loading}
              style={{ width: "70%" }}
              disabled={!email || !password || emailError || loading || (codeRequired && !smsCode)}
            >
              <div>{codeRequired ? "Verify SMS Code" : "Login"}</div>
            </Button>
            <div className="forgot">
              <NavLink title="Reset your password" to="/auth/forgot-password" className="nav-link text-semibold" activeClassName="active">
                Forgot password?
              </NavLink>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default LoginPage;
