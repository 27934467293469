import React, { useState, useEffect } from "react";
import { Modal, FormGroup, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import LocalizableTextArea from "../../Components/LocalizableInputs/LocalizableTextArea.jsx";
import LocalizableTextInput from "../../Components/LocalizableInputs/LocalizableTextInput.jsx";
import Switch from "react-switch";
import Svg from "components/Svg/Svg";
import axios from "axios";
import { parseError } from "api/common.js";

const QuestionModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [description, setDescription] = useState(props.question ? props.question.description : "");
  const [compatibleDescription, setCompatibleDescription] = useState(
    props.question ? props.question.compatibleDescription : ""
  );
  const [localizations, setLocalizations] = useState(
    props.question ? props.question.localizations : {}
  );
  const [options, setOptions] = useState(props.question ? props.question.options : []);
  const [localizationFlag, setLocalizationFlag] = useState(0);
  const [allowFreeText, setAllowFreeText] = useState(
    props.question ? props.question.allowFreeText : false
  );
  const [allowMultipleAnswers, setAllowMultipleAnswers] = useState(
    props.question ? props.question.allowMultipleOptions : false
  );

  useEffect(() => {
    if (!props.show) {
      setLoading(false);
      setError(undefined);
      setDescription("");
      setCompatibleDescription("");
      setOptions([]);
      setLocalizations({});
      setLocalizationFlag((prev) => prev + 1);
      setAllowFreeText(false);
      setAllowMultipleAnswers(false);
    } else {
      setDescription(props.question ? props.question.description : "");
      setCompatibleDescription(props.question ? props.question.compatibleDescription : "");
      setOptions(props.question ? props.question.options : []);
      setLocalizations(props.question ? props.question.localizations : {});
      setLocalizationFlag(0);
      setAllowFreeText(props.question ? props.question.allowFreeText : false);
      setAllowMultipleAnswers(props.question ? props.question.allowMultipleOptions : false);
    }
  }, [props.show, props.question]);

  const saveQuestion = () => {
    setLoading(true);
    setError(null);

    let questionData = {
      id: props.question ? props.question.id : null,
      localizations: localizations,
      description: description,
      compatibleDescription: compatibleDescription,
      allowFreeText: allowFreeText,
      allowMultipleOptions: options.length > 0 ? allowMultipleAnswers : false,
      options:
        options.length > 0
          ? options.map((opt) => {
              return {
                description: opt.description,
                id: opt.id || null,
                localizations: opt.localizations,
              };
            })
          : [],
    };

    let updateRequest = props.question
      ? axios.put(`${process.env.REACT_APP_API_URL}/questionnaire`, questionData)
      : axios.post(`${process.env.REACT_APP_API_URL}/questionnaire`, questionData);

    updateRequest
      .then((res) => {
        if (props.onConfirm) {
          props.onConfirm(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{props.question ? "Question Details" : "New Question"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="flex flex-col gap-16">
            <FormGroup style={{ marginBottom: "0" }}>
              <ControlLabel>
                Question Text:<span className="star">*</span>
              </ControlLabel>
              <LocalizableTextArea
                disabled={loading}
                placeholder="The question text..."
                value={description}
                maxLength={250}
                forceResetFlag={localizationFlag}
                rows={2}
                onChange={(text) => {
                  setDescription(text);
                }}
                onLocalizationChange={(translation) => {
                  setLocalizations((prev) => {
                    let newObj = Object.assign({}, prev);
                    Object.assign(newObj, translation);
                    return newObj;
                  });
                }}
                fieldName="Description"
                localizations={localizations}
              />
            </FormGroup>

            <FormGroup style={{ marginBottom: "0" }}>
              <ControlLabel>
                Compatibility Question:<span className="star">*</span>
              </ControlLabel>
              <LocalizableTextArea
                disabled={loading}
                forceResetFlag={localizationFlag}
                placeholder="The question text for the old versions of the apps..."
                value={compatibleDescription}
                maxLength={250}
                rows={2}
                onChange={(text) => {
                  setCompatibleDescription(text);
                }}
                onLocalizationChange={(translation) => {
                  setLocalizations((prev) => {
                    let newObj = Object.assign({}, prev);
                    Object.assign(newObj, translation);
                    return newObj;
                  });
                }}
                fieldName="CompatibleDescription"
                localizations={localizations}
              />
            </FormGroup>

            <p
              className="text-xxs sub-text col-2span"
              style={{
                margin: 0,
                marginTop: "-10px",
                paddingLeft: "4px",
              }}
            >
              This is the question to support old versions of the mobile apps that only allow to
              check the questions for a service without selecting any options. Please compose your
              question in a way that allows a customer to agree or disagree with the statement.
            </p>

            <div className="block-group">
              <div className="switch">
                <FormGroup style={{ marginBottom: "6px" }}>
                  <Switch
                    disabled={loading}
                    onChange={(checked) => setAllowFreeText(checked)}
                    checked={allowFreeText}
                    offColor="#E6E6E6"
                    onColor="#aac1e6"
                    onHandleColor="#0051d2"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={40}
                  />
                  <ControlLabel style={{ fontSize: "12px" }}>ALLOW FREE TEXT ANSWER</ControlLabel>
                </FormGroup>
              </div>
              <span className="text-xxs sub-text">
                If this option is enabled, then a customer should enter their answer manually. This
                is a mandatory field for a customer to fill out. Can be combined with answer
                options.
              </span>
            </div>

            <FormGroup style={{ marginBottom: "0" }}>
              <ControlLabel>Answer Options:</ControlLabel>
              <div className="flex flex-col gap-12">
                {options.length >= 2 && (
                  <div className="block-group">
                    <div className="switch">
                      <FormGroup style={{ marginBottom: "10px" }}>
                        <Switch
                          disabled={loading || options.length < 2}
                          onChange={(checked) => setAllowMultipleAnswers(checked)}
                          checked={allowMultipleAnswers}
                          offColor="#E6E6E6"
                          onColor="#aac1e6"
                          onHandleColor="#0051d2"
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={40}
                        />
                        <ControlLabel style={{ fontSize: "12px" }}>
                          ALLOW MULTIPLE ANSWERS
                        </ControlLabel>
                      </FormGroup>
                    </div>
                    <span className="text-xxs sub-text">
                      If this option is enabled, then a customer can select multiple answers for
                      this question.
                    </span>
                  </div>
                )}
                {options.length === 0 && (
                  <div
                    className="sub-text text-normal flex flex-col items-center"
                    style={{ background: "#f4f4f4", borderRadius: "7px", padding: "16px 0" }}
                  >
                    No answer options are provided...
                  </div>
                )}
                {options.map((opt) => (
                  <div
                    key={opt.id}
                    className="flex gap-12 items-center"
                    style={{ marginTop: "6px" }}
                  >
                    <LocalizableTextInput
                      disabled={loading}
                      style={{ width: "100%" }}
                      placeholder="Answer text..."
                      value={opt.description}
                      maxLength={100}
                      onChange={(text) => {
                        setOptions((prev) =>
                          prev.map((p) => {
                            if (p.id === opt.id) {
                              return Object.assign({}, p, { description: text });
                            }
                            return p;
                          })
                        );
                      }}
                      onLocalizationChange={(translation) => {
                        setOptions((prev) =>
                          prev.map((p) => {
                            if (p.id === opt.id) {
                              return Object.assign({}, p, {
                                localizations: Object.assign({}, p.localizations, translation),
                              });
                            }
                            return p;
                          })
                        );
                      }}
                      fieldName="Description"
                      localizations={opt.localizations}
                    />

                    <Button
                      bsStyle="danger"
                      style={{ marginLeft: "auto", padding: "8px 10px" }}
                      title="Delete Item"
                      onClick={() => {
                        setOptions((prev) => prev.filter((s) => s.id !== opt.id));
                      }}
                      type="button"
                    >
                      <Svg name="trash" className="w-4 h-4" />
                    </Button>
                  </div>
                ))}
              </div>

              <Button
                bsStyle="danger"
                className="ml-auto"
                style={{ marginTop: "12px" }}
                outline="true"
                disabled={loading || options.length >= 5}
                onClick={() => {
                  setOptions((prev) =>
                    prev.concat({
                      id: (prev.length + 1).toString(),
                      description: "",
                      localizations: {},
                    })
                  );
                }}
              >
                Add Answer
              </Button>
            </FormGroup>
          </div>

          {error && (
            <div
              className="error-alert"
              style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
            >
              {error}
            </div>
          )}

          <div className="flex items-center gap-10" style={{ marginTop: "24px" }}>
            <Button
              bsStyle="danger"
              className="ml-auto"
              outline="true"
              disabled={loading}
              onClick={() => props.onHide()}
            >
              Cancel
            </Button>

            <Button
              loading={loading}
              disabled={
                !description ||
                !compatibleDescription ||
                loading ||
                (!allowFreeText && options.length === 0) ||
                (options.length > 0 && options.some((opt) => !opt.description))
              }
              bsStyle="danger"
              fill
              onClick={() => saveQuestion()}
            >
              {props.question && props.question.id ? "Save Question" : "Add Question"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionModal;
