import Svg from "components/Svg/Svg";
import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { WebMercatorViewport } from "react-map-gl";

export const timeFormat = "h:mm A";
export const dateFormat = "MMM D, YYYY";
export const dateTimeFormat = "MMM D, YYYY h:mm A";

export function parseError(err) {
  console.log(err);

  var hasResponse = err && err.response && err.response.data;
  if (hasResponse) {
    var msg = err.response.data.message;
    var errorList = err.response.data.errorList;

    if (
      !msg &&
      err.response.config &&
      err.response.config.responseType === "arraybuffer" &&
      err.response.data
    ) {
      try {
        var data = Buffer.from(err.response.data).toJSON();
        var jsonData = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(data.data)));
        msg = jsonData.message;
        errorList = jsonData.errorList;
      } catch (err) {
        console.warn("Cannot parse failed response.");
      }
    }

    if (msg) {
      if (errorList) {
        return `${msg}\n${errorList.join("\n")}`;
      }
      return msg;
    } else if (err.response.status === 429) {
      return "Sorry, you are trying to perform the same action too often. Please wait some time and then try again.";
    } else if (err.response.data.errors) {
      let errors = err.response.data.errors;
      let errorMsg = "";
      Object.keys(errors).forEach((e) => (errorMsg += `${errors[e]}`));

      return (
        errorMsg ||
        "Sorry, an unexpected error occurred. Please try again and contact us if the issue still persist."
      );
    } else {
      return "Sorry, an unexpected error occurred. Please try again and contact us if the issue still persist.";
    }
  } else if (err.request) {
    return "Sorry, we are unable to connect to the Avvy due to network issues. Please try again or contact our support team if the issue still persist.";
  }

  return "Sorry, an unexpected error has occurred. Please try again or contact our support team if the issue still persist.";
}

export function getAppointmentStatusName(status) {
  switch (status) {
    case "pending":
      return "Pending";
    case "technician_assigned":
      return "Tech Assigned";
    case "technician_in_transit":
      return "Tech In Transit";
    case "technician_at_location":
      return "Tech At Location";
    case "started":
      return "In Progress";
    case "ended":
      return "Completed";
    case "completed":
      return "Dropped Off";
    case "cancelled":
      return "Cancelled";
  }

  return status;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function getSize(el) {
  if (!el) {
    return {
      width: 0,
      height: 0,
    };
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}

export function useComponentSize(ref) {
  var _useState = useState(getSize(ref ? ref.current : {}));
  var ComponentSize = _useState[0];
  var setComponentSize = _useState[1];

  var handleResize = useCallback(
    function handleResize() {
      if (ref.current) {
        setComponentSize(getSize(ref.current));
      }
    },
    [ref]
  );

  useLayoutEffect(
    function () {
      if (!ref.current) {
        return;
      }

      handleResize();

      if (typeof ResizeObserver === "function") {
        var resizeObserver = new ResizeObserver(function () {
          handleResize();
        });
        resizeObserver.observe(ref.current);

        return function () {
          resizeObserver.disconnect(ref.current);
          resizeObserver = null;
        };
      } else {
        window.addEventListener("resize", handleResize);

        return function () {
          window.removeEventListener("resize", handleResize);
        };
      }
    },
    [ref.current]
  );

  return ComponentSize;
}

export function renderDatePickerInput(props, openCalendar, closeCalendar) {
  function clear() {
    props.onChange({ target: { value: "" } });
    closeCalendar();
  }

  const { clearable, ...restProps } = props;

  return (
    <div style={{ position: "relative" }}>
      <input {...restProps} onChange={() => {}} />
      {props.value && ((clearable && clearable === true) || clearable === undefined) && (
        <Svg
          title="Clear Selection"
          name="close-circle"
          onClick={clear}
          className="w-5 h-5"
          style={{
            color: "var(--sub-color)",
            position: "absolute",
            right: "8px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
        />
      )}
    </div>
  );
}

export const languages = {
  en: "EN",
  "fr-CA": "FR",
};

const applyToArray = (func, array) => func.apply(Math, array);

export function getBoundsForPoints(points, height = 600) {
  const pointsLong = points.map((point) => point.longitude);
  const pointsLat = points.map((point) => point.latitude);
  const cornersLongLat = [
    [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
  ];
  const viewport = new WebMercatorViewport({
    width: window.innerWidth - 240,
    height: height || 600,
  }).fitBounds(cornersLongLat, {
    padding: 110,
  });
  const { longitude, latitude, zoom } = viewport;
  return { longitude, latitude, zoom };
}

export function formatFullAddress(address) {
  if (!address) {
    return "";
  }

  return `${address.unitNumber ? `${address.unitNumber}, ` : ""}${
    address.street ? `${address.street}, ` : ""
  }${address.city || address.place || ""}, ${address.province || address.region || ""} ${
    address.postCode || address.postalCode
  }`;
}

export function formatShortAddress(address) {
  if (!address) {
    return "";
  }

  return `${address.unitNumber ? `${address.unitNumber}, ` : ""}${
    address.street ? `${address.street}, ` : ""
  }${address.city || address.place}`;
}

export function formatCityAddress(address) {
  if (!address) {
    return "";
  }

  return `${address.unitNumber ? `${address.unitNumber}, ` : ""}${
    address.street ? `${address.street}, ` : ""
  }${address.city || address.place}`;
}

export function getPlatform() {
  if (navigator.platform) {
    return navigator.platform;
  }

  if (navigator.userAgentData && navigator.userAgentData.platform) {
    return navigator.userAgentData.platform;
  }

  return "";
}
