import React, { useState, useEffect } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import axios from "axios";
import { parseError } from "api/common";
import { dateTimeFormat } from "api/common";
import { dateFormat } from "api/common";

const RescheduleModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [availabilitiesLoading, setAvailabilitiesLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [scheduledTime, setScheduledTime] = useState(null);
  const [availabilities, setAvailabilities] = useState([]);
  const [costData, setCostData] = useState(null);
  const [costLoading, setCostLoading] = useState(false);

  useEffect(() => {
    setError(null);
    setLoading(false);
    setScheduledTime(null);
    setScheduledDate(new Date());
    setAvailabilities([]);
    setAvailabilitiesLoading(false);

    if (props.show && props.appointmentId && props.appointment) {
      getAvailabilities();
    }
  }, [props.show, props.appointmentId, props.appointment]);

  useEffect(() => {
    if (props.show) {
      getAvailabilities();

      if (scheduledTime && scheduledDate) {
        calculateCost();
      } else {
        setCostData(null);
      }
    }
  }, [scheduledDate]);

  useEffect(() => {
    if (props.show && scheduledTime && scheduledDate) {
      calculateCost();
    } else {
      setCostData(null);
    }
  }, [scheduledTime]);

  const getAvailabilities = () => {
    if (availabilitiesLoading) {
      setTimeout(() => {
        getAvailabilities();
      }, 1000);
      return;
    }

    if (!scheduledDate) {
      return;
    }

    let appServices = props.appointment.appointmentPatients
      .flatMap((p) => p.appointmentItems)
      .map((s) => s.service.id);
    let serviceCounts = appServices.filter(distinctValue).map((svc) => {
      return {
        serviceId: svc,
        count: appServices.filter((sId) => sId == svc).length,
      };
    });

    setAvailabilities([]);
    setAvailabilitiesLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/appointments/availabilities`, {
        latitude: props.appointment.location.latitude,
        longitude: props.appointment.location.longitude,
        appointmentCode: props.appointment.id,
        date: {
          year: scheduledDate.getFullYear(),
          month: scheduledDate.getMonth() + 1,
          day: scheduledDate.getDate(),
        },
        requestedServices: serviceCounts,
      })
      .then((res) => {
        setAvailabilitiesLoading(false);

        let today = new Date();
        let froms =
          res.data.date.day === today.getDate()
            ? res.data.froms.filter(
                (f) =>
                  f.hour > today.getHours() ||
                  (f.hour === today.getHours() && f.minute > today.getMinutes() + 15)
              )
            : res.data.froms;
        setAvailabilities(froms);
      })
      .catch((err) => {
        let error = parseError(err);
        setAvailabilitiesLoading(false);
        setAvailabilities([]);
        setError(error);
      });
  };

  const onRescheduleAppointment = () => {
    setError(null);
    setLoading(true);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/appointments/${props.appointmentId}`, {
        year: scheduledDate.getFullYear(),
        month: scheduledDate.getMonth() + 1,
        day: scheduledDate.getDate(),
        hour: parseInt(scheduledTime.value.split(":")[0]),
        minute: parseInt(scheduledTime.value.split(":")[1]),
      })
      .then((res) => {
        if (props.onConfirm) {
          props.onConfirm(res.data);
        }

        setLoading(false);
        props.onHide();
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const distinctValue = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--";
    }
    return moment(
      `${inDate.day}/${inDate.month}/${inDate.year} ${inDate.hour}:${inDate.minute}`,
      "D/M/YYYY H:mm"
    ).format(dateTimeFormat);
  };

  const isValidDate = function (current) {
    return current.isAfter(moment().add(-1, "days"));
  };

  const calculateCost = () => {
    if (costLoading) {
      setTimeout(() => {
        calculateCost();
      }, 1500);
      return;
    }

    if (!props.appointment) {
      setError(null);
      setCostData(null);
      return;
    }

    let appServices = props.appointment.appointmentPatients
      .flatMap((s) => s.appointmentItems || [])
      .map((s) => s.service);
    let serviceIds = appServices.map((s) => s.id).filter(distinctValue);
    let serviceCounts = serviceIds.map((svcId) => {
      return {
        serviceId: svcId,
        count: appServices.filter((s) => s.id == svcId).length,
      };
    });

    setCostLoading(true);
    setError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/appointments/calculate-cost`, {
        province: props.appointment.address.province,
        patientId: props.appointment.patient.id,
        discountCode: props.appointment.discountCodeUsed,
        appointmentCode: props.appointment.id,
        services: serviceCounts,
        type: props.appointment.type,
        location: props.appointment.location,
        startTime: {
          year: scheduledDate.getFullYear(),
          month: scheduledDate.getMonth() + 1,
          day: scheduledDate.getDate(),
          hour: parseInt(scheduledTime.value.split(":")[0]),
          minute: parseInt(scheduledTime.value.split(":")[1]),
        },
      })
      .then((res) => {
        setCostLoading(false);
        setCostData(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
        setCostData(null);
        setCostLoading(false);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Reschedule Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="grid grid-2col gap-24">
            <Card
              content={
                <div className="flex flex-col gap-16">
                  <FormGroup>
                    <ControlLabel>ORIGINAL DATE</ControlLabel>
                    <FormControl
                      type="text"
                      name="appDate"
                      disabled={true}
                      readOnly={true}
                      value={formatDate(props.appointment.startTime)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>New Date</ControlLabel>
                    <ReactDatetime
                      className="w-full"
                      dateFormat={dateFormat}
                      value={scheduledDate}
                      inputProps={{
                        required: true,
                        placeholder: "Select Date",
                        disabled: loading || availabilitiesLoading,
                      }}
                      timeFormat={false}
                      isValidDate={isValidDate}
                      closeOnSelect={true}
                      onChange={(e) => {
                        setScheduledTime(null);
                        setScheduledDate(moment(e).toDate());
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>New Time</ControlLabel>
                    <Select
                      isDisabled={loading || !scheduledDate}
                      name="time-select"
                      isClearable={false}
                      className="react-select react-select-icon w-full"
                      isSearchable={false}
                      isLoading={loading || availabilitiesLoading}
                      placeholder="Select Time"
                      noOptionsMessage={(inp) => "No time slots"}
                      isMulti={false}
                      value={scheduledTime}
                      onChange={(opt) => {
                        setScheduledTime(opt);
                      }}
                      options={availabilities.map((s) => {
                        return {
                          label: `${s.hour}:${s.minute.toString().padStart(2, "0")}`,
                          value: `${s.hour}:${s.minute.toString().padStart(2, "0")}`,
                        };
                      })}
                      formatGroupLabel={(data) => (
                        <div className="flex items-center text-theme font-semibold">
                          {data.label}
                        </div>
                      )}
                    />
                  </FormGroup>

                  {error && (
                    <div className="error-alert" style={{ padding: "0.6rem" }}>
                      {error}
                    </div>
                  )}
                </div>
              }
            />

            <Card
              content={
                <div className="flex flex-col gap-12">
                  <p className="text-bold">Payment Summary</p>

                  <div className="separator horizontal"></div>
                  {props.billingDisabled && (
                    <div className="flex flex-col gap-4">
                      <span className="main-text text-semibold text-lg">No Payment Required</span>
                      <span className="sub-text text-regular text-xs">Billed Externally</span>
                    </div>
                  )}

                  {!props.billingDisabled && (
                    <React.Fragment>
                      <div className="flex items-center">
                        <span>Subtotal</span>
                        {costData && (
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(costData.subtotal > 0 ? costData.subtotal / 100 : 0)}
                          </span>
                        )}{" "}
                        {!costData && <span className="ml-auto">--</span>}
                      </div>

                      <div className="flex items-center">
                        <span>Discount</span>
                        {costData && (
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "auto",
                            }).format(costData.discount != 0 ? costData.discount / 100 : 0)}
                          </span>
                        )}{" "}
                        {!costData && <span className="ml-auto">--</span>}
                      </div>

                      <div className="separator horizontal"></div>

                      <div className="flex items-center">
                        <span className="text-semibold">
                          Total {costData ? `(${costData.currency})` : ""}
                        </span>
                        {costData && (
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(costData.total > 0 ? costData.total / 100 : 0)}
                          </span>
                        )}{" "}
                        {!costData && <span className="ml-auto font-semibold">--</span>}
                      </div>
                    </React.Fragment>
                  )}

                  {costData && (
                    <React.Fragment>
                      <div className="separator horizontal"></div>

                      <div className="flex items-center">
                        <span>Pre-Authorized/Paid</span>
                        <span className="text-semibold ml-auto">
                          -${" "}
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            signDisplay: "auto",
                          }).format(costData.amountPaid != 0 ? costData.amountPaid / 100 : 0)}
                        </span>
                      </div>

                      <div className="flex items-center">
                        <span className="text-semibold">
                          To Pay {costData ? `(${costData.currency})` : ""}
                        </span>
                        <span className="text-semibold ml-auto">
                          ${" "}
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            signDisplay: "never",
                          }).format(costData.toPay > 0 ? costData.toPay / 100 : 0)}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              }
            />
          </div>
          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button
              disabled={loading}
              bsStyle="danger"
              outline="true"
              onClick={() => props.onHide()}
            >
              Close
            </Button>

            <Button
              loading={loading}
              bsStyle="danger"
              fill
              onClick={() => onRescheduleAppointment()}
              disabled={loading || !scheduledDate || !scheduledTime || availabilitiesLoading}
            >
              Reschedule Appointment
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RescheduleModal;
