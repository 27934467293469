export class ChatFunctions {
  public static AuthenticationSuccess = "AuthenticationSuccess";
  public static GetChatRoom = "GetChatRoom";
  public static CreateChatRoomMessage = "CreateChatRoomMessage";
  public static SubscribeToChatRoom = "SubscribeToChatRoom";
  public static SetUserTypingStatus = "SetUserTypingStatus";
  public static UserTypingStatusChanged = "UserTypingStatusChanged";
  public static GetChatRoomMessagesList = "GetChatRoomMessagesList";
  public static ChatRoomMessageReceived = "ChatRoomMessageReceived";
  public static MarkChatAsRead = "MarkChatAsRead";
  public static GetUserChatRoomsList = "GetUserChatRoomsList";
}

export enum ChatEvents {
  onConnected = "onConnected",
  onDisconnected = "onDisconnected",
  onChatRoomReceived = "onChatRoomReceived",
  onMessagesHistoryReceived = "onMessagesHistoryReceived",
  onMessageReceived = "onMessageReceived",
  onTypingStatusChanged = "onTypingStatusChanged",
  onMarkAsRead = "onMarkAsRead",
  onChatRoomsList = "onChatRoomsList",
}

export type ChatRequest = {};

export type ChatBaseMessage = {
  FunctionName: string;
  MessageType: number;
  Payload: string;
  SequenceNumber: number;
};

export type SubscribeToChatRoomRequest = ChatRequest & {
  ChatRoomId: string;
};

export type GetUserChatRoomsList = ChatRequest & {
  Limit: number;
  NextToken?: string;
  SortDirection: number;
};

export type GetChatRoomRequest = ChatRequest & {
  ChatRoomId: string;
};

export type MarkChatAsReadRequest = ChatRequest & {
  ChatRoomId: string;
};

export type CreateChatRoomMessageRequest = ChatRequest & {
  ChatRoomId: string;
  MessageBody: string;
};

export type SetUserTypingStatusRequest = ChatRequest & {
  ChatRoomId: string;
  IsTyping: boolean;
};

export type GetChatRoomMessagesListRequest = ChatRequest & {
  ChatRoomId: string;
  Limit: number;
  NextToken?: string;
  SortDirection: number;
};
