import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import AppointmentsChart from "./AppointmentsChart";
import MapGL, { Marker, Popup, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import LocationMarker from "../../../assets/img/appointment-marker.png";
import ProfileImage from "../../../assets/img/faces/face-0.jpg";
import DriverMarker from "../../../assets/img/driver-marker.png";
import {
  getAppointmentStatusName,
  useComponentSize,
  useWindowSize,
  getBoundsForPoints,
} from "api/common";
import { dateTimeFormat } from "api/common";
import { formatFullAddress } from "api/common";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const AppointmentsMap = (props) => {
  let history = useHistory();
  const contentRef = React.useRef(null);
  const { width: contentWidth } = useComponentSize(contentRef);
  const { width: windowWidth } = useWindowSize();
  const [appointments, setAppointments] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedTech, setSelectedTech] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 45.4994169,
    longitude: -73.6651472,
    zoom: props.zoom || 10,
    width: "100%",
    height: `${props.height || 600}px`,
  });

  useEffect(() => {
    window.addEventListener("keydown", mapKeyListener);

    let points = props.appointments.map((app) => {
      return {
        longitude: app.location.longitude,
        latitude: app.location.latitude,
      };
    });

    try {
      if (points.length !== 0) {
        let centre = getBoundsForPoints(points, props.height || 600);
        setTimeout(() => {
          setViewport({
            latitude: centre.latitude,
            longitude: centre.longitude,
            zoom: (centre.zoom > 13 ? 13 : centre.zoom) || props.zoom,
            width: "100%",
            height: `${props.height || 600}px`,
          });
        }, 500);
      }
    } catch (mErr) {
      console.log(mErr);
    }

    return () => {
      window.removeEventListener("keydown", mapKeyListener);
    };
  }, []);

  useEffect(() => {
    let vw = viewport;
    vw.width = `${contentWidth}px`;
    setViewport(vw);
  }, [contentWidth, windowWidth]);

  useEffect(() => {
    const appts = props.appointments.map((app) => {
      return {
        id: app.id,
        type: "Feature",
        appointment: app,
        geometry: {
          type: "Point",
          coordinates: [app.location.longitude, app.location.latitude],
        },
      };
    });

    setAppointments(appts);
  }, [props.appointments]);

  useEffect(() => {
    const techs = props.technicians.map((tech) => {
      return {
        id: tech.id,
        type: "Feature",
        tech: tech,
        geometry: tech.location
          ? {
              type: "Point",
              coordinates: [tech.location.longitude, tech.location.latitude],
            }
          : null,
      };
    });
    setTechnicians(techs);
  }, [props.updateCounter]);

  useEffect(() => {
    setSummaryData(props.summaryData || {});
  }, [props.summaryData]);

  const mapKeyListener = (e) => {
    if (e.key === "Escape") {
      setSelectedAppointment(null);
      setSelectedTech(null);
    }
  };

  const formatTime = (time) => {
    let date = moment(
      `${time.year}-${time.month}-${time.day} ${time.hour}:${time.minute}`,
      "YYYY-MM-DD H:mm"
    );
    return date.format(dateTimeFormat);
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "pending":
        return "badge-secondary";
      case "technician_assigned":
      case "technician_in_transit":
      case "technician_at_location":
        return "badge-primary";
      case "ended":
      case "completed":
        return "badge-success";
      case "cancelled":
        return "badge-danger";
    }

    return status;
  };

  const mapType = (type) => {
    switch (type) {
      case "scheduled":
        return "Scheduled";
      case "asap":
        return "ASAP";
    }

    return type;
  };

  return (
    <Card
      title={
        <div className="flex items-start">
          <div className="title">Today's Appointments</div>
        </div>
      }
      content={
        <div ref={contentRef} className="flex flex-col gap-12">
          <div
            style={{
              borderRadius: "6px",
              overflow: "hidden",
              position: "relative",
              border: "1px solid var(--border-color)",
            }}
          >
            <MapGL
              width="100%"
              height="100%"
              scrollZoom={true}
              maxPitch={85}
              {...viewport}
              onViewportChange={(viewport) => setViewport(viewport)}
              mapStyle="mapbox://styles/mapbox/light-v10"
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
            >
              <NavigationControl
                style={{
                  right: 12,
                  top: 12,
                }}
              />

              {appointments.map((appointment) => (
                <Marker
                  key={appointment.id}
                  longitude={appointment.geometry.coordinates[0]}
                  latitude={appointment.geometry.coordinates[1]}
                >
                  <button
                    className="marker-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedAppointment(appointment);
                      setSelectedTech(null);
                    }}
                  >
                    <img src={LocationMarker} alt="Appointment Marker" />
                  </button>
                </Marker>
              ))}

              {technicians
                .filter((s) => s.geometry != null)
                .map((tech) => (
                  <Marker
                    key={tech.id}
                    longitude={tech.geometry.coordinates[0]}
                    latitude={tech.geometry.coordinates[1]}
                  >
                    <button
                      className="marker-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTech(tech);
                        setSelectedAppointment(null);
                      }}
                    >
                      <img src={DriverMarker} alt="Technician Marker" />
                    </button>
                  </Marker>
                ))}

              {selectedTech && (
                <Popup
                  offsetLeft={36}
                  offsetTop={16}
                  dynamicPosition={true}
                  anchor="bottom"
                  closeOnClick={false}
                  latitude={selectedTech.geometry.coordinates[1]}
                  longitude={selectedTech.geometry.coordinates[0]}
                  onClose={() => {
                    setSelectedTech(null);
                  }}
                >
                  <div className="flex flex-col items-center justify-center gap-12">
                    <img
                      alt="Profile Picture"
                      src={selectedTech.tech.picture ? selectedTech.tech.picture.url : ProfileImage}
                      className="round"
                      style={{
                        width: "80px",
                        height: "80px",
                        overflow: "hidden",
                        border: "1px solid var(--border-color)",
                        objectFit: "cover",
                      }}
                    />

                    <div className="flex flex-col gap-6 items-center">
                      <div className="main-text text-xl text-semibold">
                        {selectedTech.tech.firstName} {selectedTech.tech.lastName}
                      </div>
                      <div className="sub-text text-xs">{selectedTech.tech.email}</div>
                      <a href={`tel:${selectedTech.tech.phone}`} className="sub-text text-xs">
                        {selectedTech.tech.phone}
                      </a>
                      <div className="badge badge-primary text-xs">
                        {selectedTech.tech.technicianType}
                      </div>
                    </div>

                    <Button
                      bsStyle="danger"
                      style={{ marginTop: "16px" }}
                      outline="true"
                      onClick={() => {
                        history.push(`/admin/lab-techs-details/${selectedTech.tech.id}`);
                      }}
                    >
                      View Account
                    </Button>
                  </div>
                </Popup>
              )}

              {selectedAppointment && (
                <Popup
                  offsetLeft={36}
                  offsetTop={16}
                  dynamicPosition={true}
                  anchor="bottom"
                  closeOnClick={false}
                  latitude={selectedAppointment.geometry.coordinates[1]}
                  longitude={selectedAppointment.geometry.coordinates[0]}
                  onClose={() => {
                    setSelectedAppointment(null);
                  }}
                >
                  <div className="flex flex-col gap-12">
                    <div className="main-text text-bold">Appointment Details</div>
                    <div className="separator horizontal"></div>
                    <div
                      className="grid gap-16"
                      style={{ gridTemplateColumns: "repeat(2, minmax(0, 180px))" }}
                    >
                      <div className="flex flex-col">
                        <span
                          className="text-semibold text-xxs sub-text"
                          style={{ marginBottom: "6px" }}
                        >
                          STATUS
                        </span>
                        <div
                          className={`badge ${getBadgeColor(
                            selectedAppointment.appointment.status
                          )} text-xs`}
                          style={{ marginRight: "auto" }}
                        >
                          {getAppointmentStatusName(selectedAppointment.appointment.status)}
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <span
                          className="text-semibold sub-text text-xxs"
                          style={{ marginBottom: "6px" }}
                        >
                          CODE
                        </span>
                        <div className="main-text text-xs">{selectedAppointment.id}</div>
                      </div>

                      <div className="flex flex-col gap-4">
                        <span
                          className="text-semibold sub-text text-xxs"
                          style={{ marginBottom: "2px" }}
                        >
                          BOOKED FOR
                        </span>
                        {selectedAppointment.appointment.appointmentPatients.map((pat, pInd) => (
                          <div key={pInd} className="main-text  text-xs">
                            {pat.firstName} {pat.lastName}
                          </div>
                        ))}
                      </div>

                      <div className="flex flex-col">
                        <span
                          className="text-semibold sub-text text-xxs"
                          style={{ marginBottom: "6px" }}
                        >
                          DATE & TIME
                        </span>
                        <div className="main-text  text-xs">
                          {formatTime(selectedAppointment.appointment.startTime)}
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <span
                          className="text-semibold sub-text text-xxs"
                          style={{ marginBottom: "6px" }}
                        >
                          TYPE
                        </span>
                        <div className="main-text text-xs">
                          {mapType(selectedAppointment.appointment.type)}
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <span
                          className="text-semibold sub-text text-xxs"
                          style={{ marginBottom: "6px" }}
                        >
                          ADDRESS
                        </span>
                        <div className="main-text  text-xs">
                          {formatFullAddress(selectedAppointment.appointment.address)}
                        </div>
                      </div>
                    </div>
                    <div className="separator horizontal"></div>
                    <div className="flex itesm-center" style={{ marginLeft: "auto" }}>
                      <Button
                        bsStyle="danger"
                        outline="true"
                        onClick={() => {
                          history.push(`/admin/appointments/${selectedAppointment.appointment.id}`);
                        }}
                      >
                        View Appointment
                      </Button>
                    </div>
                  </div>
                </Popup>
              )}
            </MapGL>
          </div>
          <div className="separator horizontal"></div>

          <AppointmentsChart data={summaryData} />
        </div>
      }
    />
  );
};

export default AppointmentsMap;
